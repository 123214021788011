/** @jsx jsx */
import { jsx, Box, Label, Flex } from 'theme-ui';
import { Input } from '@theme-ui/components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import getLanguage from '../utils/getLanguage';
import { locNavigate } from '../state/session';
import { PageTitle } from './PageTitle';
import Button from './Button';
import { isBrowser } from '../utils';

const MIN_INPUT_LENGTH = 3;

export default ({ excel, assets }) => {
  const locale = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const term = isBrowser && new URLSearchParams(location?.search).get('term');
  const areas = (assets[excel] || {}).data || [];

  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const searchRef = useRef();

  useEffect(() => {
    // scroll to search if term was already in the query params
    let timeout;
    if (term) {
      setSearchTerm(term);
      timeout = setTimeout(() => searchRef.current.scrollIntoView({ behavior: 'smooth' }), 200);
    }
    return () => timeout && clearTimeout(timeout);
  }, [term]);

  const results = useMemo(() => {
    if (!searchTerm || searchTerm.length <= 2) {
      return [];
    }
    return areas.filter(area => {
      const cityNames = Object.values(area.city);
      return cityNames.some(city => (city || '').toLowerCase().includes(searchTerm.toLowerCase()));
    });
  }, [searchTerm]);

  const handleSelectTicket = useCallback(
    option => {
      const currentPath = window.location.pathname;
      const path = option?.url?.[locale];
      dispatch(locNavigate(path, `?returnPath=${currentPath}&term=${searchTerm}`));
    },
    [dispatch, locale, searchTerm]
  );

  return (
    <Box ref={searchRef} sx={{ my: 4 }}>
      <Box sx={{ p: [3, 24], mb: 4, bg: 'blueLighter', borderRadius: [0, 2] }}>
        <Box>
          <Label sx={{ mb: 2 }}>{translate('ticketSearch.query')}</Label>
        </Box>
        <Input
          value={searchTerm}
          aria-label={translate('ticketSearch.query')}
          onChange={e => setSearchTerm(e.target.value)}
          type="text"
          sx={{
            width: '100%',
            py: 12,
            mt: 2,
            px: 3,
          }}
        />
      </Box>

      <Box>
        {!results.length ? (
          <NoResultsLabel searchTerm={searchTerm} />
        ) : (
          <Flex sx={{ flexDirection: 'column', gap: 2 }}>
            {results.map(result => (
              <Flex
                sx={{
                  flexDirection: 'row',
                  border: '2px solid',
                  borderColor: '#ADB3B7',
                  borderRadius: 1,
                  p: 3,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <PageTitle
                  to={result.url?.[locale || 'fi']}
                  sx={{ fontSize: 4, fontWeight: 'medium' }}
                  fallback={result?.city?.[locale || 'fi']}
                />
                <Button onClick={() => handleSelectTicket(result)} sx={{ px: 4 }}>
                  {translate('ticketSearch.checkOut')}
                </Button>
              </Flex>
            ))}
          </Flex>
        )}
      </Box>
    </Box>
  );
};

const NoResultsLabel = ({ searchTerm }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Box>
      {searchTerm?.length >= MIN_INPUT_LENGTH && (
        <p
          dangerouslySetInnerHTML={{ __html: translate('ticketSearch.noTicketsFound') }}
          sx={{ a: { textDecoration: 'underline' } }}
        ></p>
      )}
    </Box>
  );
};
