/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { getTranslate } from 'react-localize-redux';
import { Form, withFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../components/Spinner';
import * as yup from 'yup';
import Button from '../components/Button';
import FormField from '../components/FormField';
import { checkRePackCode } from '../state/parcelFlow';

const RePackCodeForm = ({ setFieldValue, isSubmitting, translate }) => {
  const onInputChange = useCallback(e => {
    const { value } = e.target;
    setFieldValue('code', value.replace(/\s/g, '').toUpperCase()); // remove spaces
  }, [setFieldValue]);

  return (
    <Form
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        ml: 4,
        mt: 2,
        maxWidth: 400,
      }}
    >
      <Box sx={{ width: '100%', pr: 2 }}>
        <FormField
          name="code"
          onChange={onInputChange}
          label={translate('buyParcel.repack.enter')}
          sx={{ pr: 4 }}
        />
      </Box>
      <Button
        variant="plain"
        type="submit"
        disabled={isSubmitting}
        sx={{
          position: 'absolute',
          top: 20,
          right: 2,
          zIndex: 1,
          display: 'block',
          px: 2,
          fontSize: 24,
          transition: 'all .25s ease',
          ':hover': {
            right: 1,
          },
      }}
      >
        &rarr;
      </Button>
      {isSubmitting && 
        <Box sx={{ position: 'absolute', top: '31px', right: '-10px' }}>
          <Spinner />
        </Box>
      }
    </Form>
  );
};

const RePackCodeFormik = withFormik({
  mapPropsToValues: ({ code }) => ({
    code: code || '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      code: yup.string().min(3, translate('buyParcel.repack.tooShort')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'RePackCodeFormik',
})(RePackCodeForm);

export default () => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();
  const code = useSelector(state => state.parcelFlow.repackCode);
 
  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      const errorCode = await dispatch(checkRePackCode(values.code));
      if (errorCode) {
        if (errorCode === 'CODE_ALREADY_USED') {
          setFieldError('code', translate(`buyParcel.repack.code_used`));
        } else if (errorCode === 'ALREADY_IN_BASKET') {
          setFieldError('code', translate(`buyParcel.repack.already_in_basket`));
        } else if (errorCode === 'NOT_YET_ACTIVE') {
          setFieldError('code', translate(`buyParcel.repack.not_yet`));
        } else if (errorCode === 'EXPIRED') {
          setFieldError('code', translate(`buyParcel.repack.expired`));
        } else {
          setFieldError('code', translate(`buyParcel.repack.invalid`));
        }
      }
    },
    [dispatch, translate]
  );
  const formProps = { onSubmit, translate, code };

  return <RePackCodeFormik {...formProps} />;
};
