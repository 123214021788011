/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import Accordion from '../Accordion';

export default ({ content }) => {
  return (
    <Box variant="layout.text">
      <Styled.h2 sx={{ variant: 'text.title', mt: 0 }}>
        {content.headline}
      </Styled.h2>
      <Box>
        {content.frequentlyAskedQuestions &&
          content.frequentlyAskedQuestions.map((faq, i) => (
            <Accordion variant="faq" title={faq.question} key={i}>
              <Box
                dangerouslySetInnerHTML={{
                  __html: faq.answer.childMarkdownRemark.html,
                }}
                sx={{ lineHeight: 'normal' }}
              />
            </Accordion>
          ))}
      </Box>
    </Box>
  );
};
