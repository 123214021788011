/** @jsx jsx */
import { jsx, Flex, useThemeUI } from 'theme-ui';
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
  CarouselContext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { ChevronIcon } from '../components/Icon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { chunk } from 'lodash';
import { readableColor } from 'polished';

const chunkSlides = (slides, width) => {
  const links = slides.map(slide => slide.links).flat();
  const size = width < 600 ? 1 : 2;
  const chunked = chunk(links, size);
  return chunked.map(links => ({ links: links }));
};

const LogoCarousel = ({ slides = [], color, sx = {}, ...props }) => {
  const locale = useSelector(state => state.session.locale);

  const { width = 0 } = useWindowDimensions();
  const isSmallScreen = width < 992;

  const desktopSlides = (slides || []).filter(s => s?.languages?.includes(locale) || !!!s?.languages);
  const mobileSlides = useMemo(() => chunkSlides(desktopSlides, width), [isSmallScreen, desktopSlides, width]);

  // carousel does not work well with changing number of slides + SSR = as fix, render different component for desktop / mobile
  if (!desktopSlides.length || !width || typeof window == 'undefined') return null;
  if (isSmallScreen) {
    return <CarouselWrapper shownSlides={mobileSlides} color={color} sx={sx} {...props} />;
  } else {
    return <CarouselWrapper shownSlides={desktopSlides} color={color} sx={sx} {...props} />;
  }
};

const CarouselWrapper = ({ shownSlides, color, sx, ...props }) => {
  if (!shownSlides?.length) return null;
  return (
    <CarouselProvider
      naturalSlideWidth={400}
      naturalSlideHeight={300}
      totalSlides={shownSlides.length}
      isIntrinsicHeight
      infinite
      isPlaying
      lockOnWindowScroll
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      <Carousel shownSlides={shownSlides} color={color} />
    </CarouselProvider>
  );
};

const Carousel = ({ shownSlides = [], color }) => {
  const { theme: themeUI } = useThemeUI();
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide);
    };
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <>
      <Slider>
        {shownSlides?.map((slide, i) => (
          <Slide index={i}>
            <Flex
              sx={{
                flex: 1,
                width: '100%',
                flexDirection: 'row',
                gap: 2,
                justifyContent: 'space-between',
                color: 'white',
              }}
            >
              {(slide.links || []).map(link => (
                <>
                  <ImageLink url={link.url} imageUrl={link.icon.file.url} color={color || 'white'} />
                </>
              ))}
            </Flex>
          </Slide>
        ))}
      </Slider>
      <ButtonControls currentSlide={currentSlide} totalSlides={shownSlides?.length} />
      <DotGroup
        showAsSelectedForCurrentSlideOnly={true}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mt: 3,
          '.carousel__dot': {
            borderRadius: '50%',
            bg: 'white',
            p: '7px',
            border: `1px solid ${themeUI.colors.primary}`,
            outline: 0,
          },
          '.carousel__dot--selected': {
            bg: 'primary',
            border: `1px solid ${themeUI.colors.primary}`,
          },
        }}
      />
    </>
  );
};

const ButtonControls = ({ currentSlide, totalSlides = 0 }) => {
  // show only on mobile
  return (
    <div sx={{ display: ['block', null, null, 'none'] }}>
      {currentSlide !== 0 ? (
        <div
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            mt: '-16px',
            ml: -3,
            height: '100%',
            display: 'flex',
            flexDirection: 'col',
            alignItems: 'center',
          }}
        >
          <ButtonBack
            sx={{
              variant: 'buttons.plain',
              height: '40px',
              width: '40px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow
              borderRadius: '50%',
              backgroundColor: 'white',
              color: 'primary',
            }}
          >
            <ChevronIcon sx={{ mt: -1, ml: 2, transform: 'rotate(180deg)' }} />
          </ButtonBack>
        </div>
      ) : null}

      {currentSlide < totalSlides - 1 ? (
        <div
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            mt: '-16px',
            mr: -3,
            height: '100%',
            display: 'flex',
            flexDirection: 'col',
            alignItems: 'center',
          }}
        >
          <ButtonNext
            sx={{
              variant: 'buttons.plain',
              height: '40px',
              width: '40px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow
              borderRadius: '50%',
              backgroundColor: 'white',
              color: 'primary',
            }}
          >
            <ChevronIcon sx={{ mt: -1, ml: 2 }} />
          </ButtonNext>
        </div>
      ) : null}
    </div>
  );
};

const ImageLink = ({ url, imageUrl, color }) => {
  const height = 237;
  return (
    <Flex
      sx={{
        flex: '1 1 auto',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: color,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link
        to={url}
        target="_blank"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <img
          objectPosition="50% 50%"
          src={imageUrl}
          alt="logo"
          sx={{
            width: '100%',
            alignSelf: 'center',
            height,
            objectFit: 'cover',
          }}
        />
      </Link>
    </Flex>
  );
};

export default LogoCarousel;
