/** @jsx jsx */
import React, { useCallback, useEffect, useRef } from 'react';
import { jsx, Flex, Box } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { UserIcon, CaretIcon } from './Icon';
import { locNavigate, logout } from '../state/session';

const DropdownToggle = props => (
  <Flex
    {...props}
    sx={{
      cursor: 'pointer',
      alignItems: 'center',
      color: 'hiContrast',
      mx: 2,
      transition: 'color .25s ease',
      ':hover': {
        color: 'headerHover',
      },

      'svg:last-of-type': {
        transition: 'transform .25s ease',
        transform: props.active ? 'rotate(180deg)' : 'none',
      },
    }}
  >
    {props.children}
    <CaretIcon sx={{ ml: 2 }} />
  </Flex>
);

const DropdownMenu = props => (
  <Box
    sx={{
      position: 'absolute',
      top: '100%',
      right: 0,
      bottom: 'auto',
      bg: 'white',
      py: 2,
      mt: 2,
      borderRadius: 1,
      boxShadow: 'dropdown',
    }}
  >
    {props.children}
  </Box>
);

const DropdownMenuItem = React.forwardRef(({ children, onClick, ...props }, ref) => {
  const onKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      onClick();
    }
  }, []);
  return (
    <Box
      ref={ref}
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...props}
      sx={{
        cursor: 'pointer',
        px: 3,
        py: 1,
        whiteSpace: 'nowrap',
        color: 'secondary',
        ':hover': { bg: 'blueLighter' },
      }}
    >
      {children}
    </Box>
  );
});

const UserMenu = props => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const node = useRef();
  const [open, setOpen] = React.useState(false);
  const toggleOpen = useCallback(() => {
    setOpen(open => !open);
  }, []);
  const handleKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setOpen(open => !open);
    }
  }, []);
  const user = useSelector(state => state.session.user) || {};

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div ref={node} sx={{ position: 'relative' }}>
      <DropdownToggle active={open} tabIndex="0" onClick={toggleOpen} onKeyDown={handleKeyDown} theme={props.theme}>
        <UserIcon sx={{ mr: [0, 2], width: 24, height: 24 }} />
        <Box sx={{ display: ['none', 'block'] }}>{user.firstname}</Box>
      </DropdownToggle>
      {open && (
        <DropdownMenu open={open}>
          <DropdownMenuItem
            onClick={e => {
              dispatch(locNavigate('/my-pages/incoming-parcels'));
            }}
          >
            {translate('account.myParcels.title')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={e => {
              dispatch(locNavigate('/my-pages/my-tickets'));
            }}
          >
            {translate('account.myTickets.title')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={e => {
              dispatch(locNavigate('/my-account'));
            }}
          >
            {translate('account.header')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={e => {
              dispatch(logout());
              e.preventDefault();
            }}
          >
            {translate('page.logout')}
          </DropdownMenuItem>
        </DropdownMenu>
      )}
    </div>
  );
};

export default UserMenu;
