import {isBrowser} from '../utils';
const storage = isBrowser ? window.localStorage : {};

const KEY = {
  basketId: 'basketId',
  basketDate: 'basketDate',
}

export function getBasketId() {
  const basketId = storage.getItem(KEY.basketId);
  const basketDate = +storage.getItem(KEY.basketDate);
  const now = +new Date();
  const elapsed = (now - basketDate) / 1000;
  const basketMaxTime = 24*60*60; // one day

  if (elapsed > basketMaxTime) {
    removeBaskedId();
    return null;
  } else {
    return basketId;
  }
}

export function setBaskedId(basketId) {
  const now = +new Date();
  storage.setItem(KEY.basketId, basketId);
  storage.setItem(KEY.basketDate, now);
}

export function removeBaskedId() {
  storage.removeItem(KEY.basketId);
  storage.removeItem(KEY.basketDate);
}
