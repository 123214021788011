/** @jsx jsx  */
import { jsx, Box, Button, Flex } from 'theme-ui';
import React, { useCallback, useEffect, useReducer } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie';
import ua from 'ua-parser-js';
import { ReititJaLiputIcon } from '../components/Icon';
import paketitPng from '../images/paketit.png';

const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';

const tripsAndTicketsIosAppId = '1496304929';
const tripsAndTicketsAndroidAppId = 'com.kyyti.ride.matkahuolto';
const tatCookie = tripsAndTicketsAndroidAppId + '.smartBanner.closed';

const paketitIosAppId = '1585923810';
const paketitAndroidAppId = 'fi.matkahuolto.paketti' + (isTest ? '.staging' : '');
const paketitCookie = paketitAndroidAppId + '.smartBanner.closed';

let deferredPrompt = null;
let listenerAdded = false;
let promptDismissed = false;
let alreadyInstalled = false;
const beforeInstallListener = e => {
  // console.log('beforeInstallListener');
  e.preventDefault();
  deferredPrompt = e;
};

const getDomainForCookie = () => {
  if (window.location.hostname.endsWith('cloudfront.net')) {
    return window.location.hostname;
  }
  return window.location.hostname
    .split('.')
    .slice(-2)
    .join('.');
};

const setDontShowAgainCookie = cookieName => {
  Cookies.set(cookieName, 'true', {
    domain: getDomainForCookie(),
    expires: 30,
  });
};

export default ({ path, banner }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  // const pagePath = path || (typeof window !== 'undefined' ? window.location.pathname : '');
  const showAppBanner = true; // pagePath.startsWith('/matkustajat');
  const showOpenBanner = false; // requires update to Android app
  const agent = ua();
  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  const showPaketitBanner = banner === 'paketit';
  const cookieName = showPaketitBanner ? paketitCookie : tatCookie;
  const androidId = showPaketitBanner ? paketitAndroidAppId : tripsAndTicketsAndroidAppId;
  const iosId = showPaketitBanner ? paketitIosAppId : tripsAndTicketsIosAppId;

  const tatClosed = !!Cookies.get(cookieName);

  // console.log('agent', agent);

  // const isInWebAppiOS = (window && window.navigator.standalone === true);
  // const isInWebAppChrome = (window && window.matchMedia('(display-mode: standalone)').matches);

  if (
    typeof window !== 'undefined' &&
    !listenerAdded &&
    agent &&
    agent.os.name === 'Android' &&
    agent.browser.name === 'Chrome'
  ) {
    window.addEventListener('beforeinstallprompt', beforeInstallListener);
    listenerAdded = true;

    navigator.getInstalledRelatedApps().then(relatedApps => {
      relatedApps.forEach(app => {
        if (app.id === androidId) {
          alreadyInstalled = true;
        }
      });
    });
  }

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', forceUpdate);
    return () => {
      window.removeEventListener('beforeinstallprompt', forceUpdate);
    };
  }, [forceUpdate]);

  const onClose = useCallback(() => {
    deferredPrompt = null;
    forceUpdate();
    setDontShowAgainCookie(cookieName);
  }, [cookieName]);

  const onInstall = useCallback(() => {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then(choiceResult => {
      promptDismissed = true;
      /*
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted prompt');
      } else {
        console.log('User dismissed prompt');
      }
      */
    });
    deferredPrompt = null;
    forceUpdate();
  }, [forceUpdate]);

  return showAppBanner ? (
    <>
      <Helmet>
        <meta name="apple-itunes-app" content={'app-id=' + iosId} />
        <meta name="google-play-app" content={'app-id=' + androidId} />
        {showPaketitBanner && <link rel="manifest" href="/manifest_alt.webmanifest" />}
      </Helmet>
      {tatClosed || promptDismissed
        ? null
        : (!!deferredPrompt || (showOpenBanner && alreadyInstalled)) && (
            <Flex sx={{ py: 2, width: '100%', alignItems: 'center', bg: 'border' }}>
              <Button variant="plain" color="secondary" mx="2" onClick={onClose}>
                &times;
              </Button>
              <Box mx="0">
                {showPaketitBanner ? (
                  <img src={paketitPng} alt="Logo" width={48} height={48} sx={{ borderRadius: '4px' }} />
                ) : (
                  <ReititJaLiputIcon />
                )}
              </Box>
              <Flex sx={{ flexDirection: 'column', flexGrow: 1, mx: 3 }}>
                <Box sx={{ fontWeight: 'medium' }}>
                  {translate('smartBanner.appName.' + (showPaketitBanner ? 'paketit' : 'rl'))}
                </Box>
                <Box sx={{ fontSize: 1 }}>{translate('smartBanner.publisher')}</Box>
                <Box sx={{ fontSize: 1 }}>
                  {translate(alreadyInstalled ? 'smartBanner.alreadyInstalled' : 'smartBanner.freeInstall')}
                </Box>
              </Flex>
              <button sx={{ mx: 2 }} onClick={onInstall}>
                {translate(alreadyInstalled ? 'smartBanner.open' : 'smartBanner.install')}
              </button>
            </Flex>
          )}
    </>
  ) : null;
};

/*
<div class="smartbanner-container">' +
							'<a href="javascript:void(0);" class="smartbanner-close">&times;</a>' +
							'<span class="smartbanner-icon" style="background-image: url(' + icon + ')"></span>' +
							'<div class="smartbanner-info">' +
								'<div class="smartbanner-title">' + this.options.title + '</div>' +
								'<div>' + this.options.author + '</div>' +
								'<span>' + inStore + '</span>' +
							'</div>' +
							'<a href="' + link + '" class="smartbanner-button">' +
								'<span class="smartbanner-button-text">' + this.options.button + '</span>' +
							'</a>' +
						'</div>';
            */
