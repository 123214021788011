const initialState = {
  foo: false,
  bar: [],
};

const TOGGLE_FOO = 'TOGGLE_FOO';
const PUSH_BAR = 'PUSH_BAR';
const RESET_BAR = 'RESET_BAR';

export const setFoo = isFoo => ({
  type: TOGGLE_FOO,
  isFoo,
});

export const pushBar = item => ({
  type: PUSH_BAR,
  item,
});

export const resetBar = () => ({
  type: RESET_BAR,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FOO:
      return { ...state, foo: action.isFoo };
    case PUSH_BAR:
      return { ...state, bar: [...state.bar, action.item] };
    case RESET_BAR:
      return { ...state, bar: initialState.bar };
    default:
      return state;
  }
};
