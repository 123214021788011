import { CountryCode } from './countryCode';

export const phoneRegExp = /^\+?[0-9 \-#()]{6,18}$/;
export const swedishPhoneRegExp = /^(?:\+467[02369])\d{7}$/;

export const postalCodeRegExp = /^[0-9]{5}$/;

export const postalCodeRegExpForCountry = country => {
  // http://www.mapanet.eu/en/resources/Postal-Format.asp

  if (
    [
      CountryCode.Finland,
      CountryCode.Aland,
      CountryCode.Bosnia,
      CountryCode.Croatia,
      CountryCode.Estonia,
      CountryCode.France,
      CountryCode.Germany,
      CountryCode.Italy,
      CountryCode.Lithuania,
      CountryCode.Monaco,
      CountryCode.Serbia,
      CountryCode.Spain,
    ].includes(country)
  ) {
    return /^[0-9]{5}$/;
  }
  if (
    [
      CountryCode.Austria,
      CountryCode.Belgium,
      CountryCode.Bulgaria,
      CountryCode.Denmark,
      CountryCode.Hungary,
      CountryCode.Latvia,
      CountryCode.Luxembourg,
      CountryCode.Slovenia,
      CountryCode.Switzerland,
    ].includes(country)
  ) {
    return /^[0-9]{4}$/;
  }
  if ([CountryCode.Iceland].includes(country)) {
    return /^[0-9]{3}$/;
  }
  if ([CountryCode.Russia, CountryCode.Romania].includes(country)) {
    return /^[0-9]{6}$/;
  }
  if ([CountryCode.Czech, CountryCode.Greece, CountryCode.Sweden, CountryCode.Slovakia].includes(country)) {
    return /^[0-9]{3} ?[0-9]{2}$/;
  }
  if ([CountryCode.Netherlands].includes(country)) {
    return /^[0-9]{4} ?[A-Z]{2}$/;
  }
  if ([CountryCode.Poland].includes(country)) {
    return /^[0-9]{2}-?[0-9]{3}$/;
  }
  if ([CountryCode.Portugal].includes(country)) {
    return /^[0-9]{4}-?[0-9]{3}$/;
  }
  if ([CountryCode.GreatBritain].includes(country)) {
    return /^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9]([A-Z]{2})?$/;
  }
  if ([CountryCode.Ireland].includes(country)) {
    return /^.*$/;
  }
  return /^[0-9]{4,6}$/;
};
