/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { CartIcon } from '../components/Icon';
import NavigationActionLink from './NavigationActionLink';

export default ({ numberOfItems, color = 'currentColor' }) => (
  <NavigationActionLink to="/ostoskori" sx={{ alignItems: 'center', color: color, mr: 0 }}>
    <CartIcon
      sx={{
        color: color,
        ':hover': { color: [color, null, null, 'primary'] },
      }}
    />
    <Flex
      sx={{
        minWidth: 20,
        height: 20,
        borderRadius: 3,
        bg: 'pinkDark',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 0,
        fontWeight: 'medium',
        px: 1,
      }}
    >
      {numberOfItems}
    </Flex>
  </NavigationActionLink>
);
