// if you add new countries, also check
// 1. postal code regular expressions
// 2. noutopiste api

exports.CountryCode = {
  Finland: 'FI',
  Aland: 'AX',
  Austria: 'AT',
  Belgium: 'BE',
  Bosnia: 'BA',
  Bulgaria: 'BG',
  Croatia: 'HR',
  Czech: 'CZ',
  Denmark: 'DK',
  Estonia: 'EE',
  France: 'FR',
  GreatBritain: 'GB',
  Greece: 'GR',
  Hungary: 'HU',
  Iceland: 'IS',
  Ireland: 'IE',
  Italy: 'IT',
  Latvia: 'LV',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Monaco: 'MC',
  Netherlands: 'NL',
  Germany: 'DE',
  Poland: 'PL',
  Portugal: 'PT',
  Romania: 'RO',
  Russia: 'RU',
  Serbia: 'RS',
  Slovakia: 'SK',
  Slovenia: 'SI',
  Spain: 'ES',
  Sweden: 'SE',
  Switzerland: 'CH',
};

exports.isFinlandOrAland = countryCode =>
  [this.CountryCode.Finland, this.CountryCode.Aland, 'FIorAX'].includes(countryCode);

exports.localizedCountryNames = [
  { code: 'FI', name: { fi: 'Suomi', sv: 'Finland', en: 'Finland' } },
  { code: 'AX', name: { fi: 'Ahvenanmaa', sv: 'Åland', en: 'Åland Islands' } },
  { code: 'AT', name: { fi: 'Itävalta', sv: 'Österrike', en: 'Austria' } },
  { code: 'BA', name: { fi: 'Bosnia ja Hertsegovina', sv: 'Bosnien och Hercegovina', en: 'Bosnia and Herzegovina' } },
  { code: 'BE', name: { fi: 'Belgia', sv: 'Belgien', en: 'Belgium' } },
  { code: 'BG', name: { fi: 'Bulgaria', sv: 'Bulgarien', en: 'Bulgaria' } },
  { code: 'CH', name: { fi: 'Sveitsi', sv: 'Schweiz', en: 'Switzerland' } },
  { code: 'CZ', name: { fi: 'Tsekki', sv: 'Tjeckien', en: 'The Czech Republic' } },
  { code: 'DE', name: { fi: 'Saksa', sv: 'Tyskland', en: 'Germany' } },
  { code: 'DK', name: { fi: 'Tanska', sv: 'Danmark', en: 'Denmark' } },
  { code: 'EE', name: { fi: 'Viro', sv: 'Estland', en: 'Estonia' } },
  { code: 'ES', name: { fi: 'Espanja', sv: 'Spanien', en: 'Spain' } },
  { code: 'FR', name: { fi: 'Ranska', sv: 'Frankrike', en: 'France' } },
  { code: 'GR', name: { fi: 'Kreikka', sv: 'Grekland', en: 'Greece' } },
  { code: 'HR', name: { fi: 'Kroatia', sv: 'Kroatien', en: 'Croatia' } },
  { code: 'HU', name: { fi: 'Unkari', sv: 'Ungern', en: 'Hungary' } },
  { code: 'IE', name: { fi: 'Irlanti', sv: 'Irland', en: 'Ireland' } },
  { code: 'IS', name: { fi: 'Islanti', sv: 'Island', en: 'Iceland' } },
  { code: 'IT', name: { fi: 'Italia', sv: 'Italien', en: 'Italy' } },
  { code: 'LT', name: { fi: 'Liettua', sv: 'Litauen', en: 'Lithuania' } },
  { code: 'LU', name: { fi: 'Luxemburg', sv: 'Luxemburg', en: 'Luxembourg' } },
  { code: 'LV', name: { fi: 'Latvia', sv: 'Lettland', en: 'Latvia' } },
  { code: 'MC', name: { fi: 'Monaco', sv: 'Monaco', en: 'Monaco' } },
  { code: 'NL', name: { fi: 'Alankomaat', sv: 'Nederländerna', en: 'Netherlands' } },
  { code: 'PL', name: { fi: 'Puola', sv: 'Polen', en: 'Poland' } },
  { code: 'PT', name: { fi: 'Portugali', sv: 'Portugal', en: 'Portugal' } },
  { code: 'RO', name: { fi: 'Romania', sv: 'Rumänien', en: 'Romania' } },
  { code: 'RS', name: { fi: 'Serbia', sv: 'Serbien', en: 'Serbia' } },
  { code: 'RU', name: { fi: 'Venäjä', sv: 'Ryssland', en: 'Russia' } },
  { code: 'SE', name: { fi: 'Ruotsi', sv: 'Sverige', en: 'Sweden' } },
  { code: 'SK', name: { fi: 'Slovakia', sv: 'Slovakien', en: 'Slovakia' } },
  { code: 'SI', name: { fi: 'Slovenia', sv: 'Slovenien', en: 'Slovenia' } },
  { code: 'GB', name: { fi: 'Iso-Britannia', sv: 'Storbritannien', en: 'Great Britain' } },
];

exports.getLocalizedCountryName = (countryCode, language) => {
  const country = this.localizedCountryNames.find(c => c.code === countryCode) || { name: {} };
  return country.name[language] || country.name.fi || 'Virheellinen maakoodi';
};
