/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

export default props => (
  <Box
    dangerouslySetInnerHTML={{
      __html: props.content,
    }}
    sx={{
      width: '100%',
      overflowX: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      table: {
        width: '100%',
        border: 0,
      },
      'td, th': {
        py: 12,

        lineHeight: 'heading',
        border: 0,
        borderBottom: '1px solid',
        borderRight: '1px solid',
        borderColor: 'secondary',
        textAlign: 'center',
        ':last-of-type': {
          borderRight: 0,
        },
      },
      'tr:last-of-type td': {
        borderBottom: 0,
      },
      th: {
        fontWeight: 'medium',
        minWidth: 100,
        color: 'primary',
      },
      'th[scope="col"]': {},
    }}
  ></Box>
);
