/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Switch from '../components/Switch';
import { toggleMultipleServices } from '../state/servicePoints';
import useWindowDimensions from '../hooks/useWindowDimensions';

const ServicePointFilters = ({ showFilters, setShowFilters, services: allServices }) => {
  useEffect(() => {
    if (showFilters) {
      setShowFilters(true);
    }
  }, [showFilters]);

  const dispatch = useDispatch();

  const translate = getTranslate(useSelector(state => state.localize));

  // if office has any of these services, it is considered a 'parcel point'
  const parcelPointCodes = 'P1,P2,P3,P4,P35,VAK,LQ';

  const packetFilters = useMemo(
    () => ({
      [parcelPointCodes]: translate('servicePoints.type.parcelPoint'),
      EP: translate('servicePoints.type.automat'),
      TERMINAL: translate('servicePoints.type.terminal'),
      SL: translate('servicePoints.type.contractCustomerPoint'),
    }),
    [translate, parcelPointCodes]
  );

  // Function to determine if all services in a list are selected
  const areAllSelected = codes => {
    return codes.every(code => {
      const service = allServices.find(service => service.code === code);
      return service && service.selected;
    });
  };

  // Find active packet filters
  const activeFilters = Object.keys(packetFilters).filter(filterKey => {
    const codes = filterKey.split(',');
    return areAllSelected(codes);
  });

  const { width } = useWindowDimensions();

  const selectPacketFilter = useCallback(
    e => {
      if (!e.target.checked && activeFilters.length === 1) {
        // cannot disable the last filter
        return;
      }
      const packetServices = {
        ...activeFilters.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      };
      e.target.id.split(',').forEach(s => (packetServices[s] = !!e.target.checked));
      dispatch(toggleMultipleServices(packetServices));
    },
    [dispatch, activeFilters]
  );

  const handleExpandFilters = useCallback(() => {
    if (width > 800) {
      return;
    }
    setShowFilters(!showFilters);
  }, [width, showFilters, setShowFilters]);

  return (
    <>
      <Box
        sx={{
          py: 3,
          px: 1,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              px: 3,
              py: 3,
              bg: '#f5f6f8',
              borderRadius: 1,
            }}
          >
            <Flex role="button" onClick={handleExpandFilters} sx={{ justifyContent: 'space-between', gap: 3 }}>
              <Styled.h4 sx={{ my: 0, fontWeight: 'semibold' }}>{translate('servicePoints.filteringTitle')}</Styled.h4>
              <Box
                as="svg"
                width="11"
                height="16"
                viewBox="0 0 11 16"
                xmlns="http://www.w3.org/2000/svg"
                sx={{
                  display: ['block', null, null, 'none'],
                  justifySelf: 'flex-end',
                  transform: showFilters ? 'rotate(-90deg)' : 'rotate(90deg)',
                  mr: 2,
                }}
              >
                <path
                  d="M10.333 8c0 .441-.189.861-.52 1.153l-7.434 6.54a1.231 1.231 0 01-1.626-1.848l6.501-5.72a.167.167 0 000-.25L.753 2.155A1.231 1.231 0 012.379.307L9.81 6.845c.332.292.522.713.522 1.155z"
                  fill="currentColor"
                />
              </Box>
            </Flex>

            {showFilters && (
              <Box sx={{ mt: 4 }}>
                {Object.entries(packetFilters).map(([code, description]) => (
                  <Flex key={code} sx={{ my: 2 }}>
                    <Switch id={code} checked={activeFilters.includes(code)} onChange={selectPacketFilter}>
                      {description}
                    </Switch>
                  </Flex>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

ServicePointFilters.propTypes = {
  hideFilters: PropTypes.bool,
};

ServicePointFilters.defaultProps = {
  hideFilters: true,
};

export default ServicePointFilters;
