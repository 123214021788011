export const PAGE = {
  CONTRACT_NUMBER: 1,
  COMPANY_INFO: 2,
  PICKUP_TIME: 3,
  PICKUP_ITEMS: 4,
  CONFIRM_ORDER: 5,
  ORDER_DONE: 6,
};

const initialState = {
  page: PAGE.CONTRACT_NUMBER,
  contractNumber: null,
  contact: {},
  pickupTime: {},
  items: [],
  possibleTimes: {}, // old deprecated
  availableTimes: {},
  possibleItemNames: ['packet', 'rolltainer', 'eurPallet', 'finPallet', 'powerPallet'],
};

const PICKUP_SELECT_PAGE = 'PICKUP_SELECT_PAGE';
const PICKUP_SAVE_CONTRACT_NUBER = 'PICKUP_SAVE_CONTRACT_NUBER';
const PICKUP_SAVE_CONTACT = 'PICKUP_SAVE_CONTACT';
const PICKUP_SAVE_TIME = 'PICKUP_SAVE_TIME';
const PICKUP_SAVE_ITEMS = 'PICKUP_SAVE_ITEMS';
const PICKUP_SET_POSSIBLE_TIMES = 'PICKUP_SET_POSSIBLE_TIMES';

export const goNext = () => (dispatch, getState) => {
  const state = getState().pickupOrder;
  dispatch({ type: PICKUP_SELECT_PAGE, page: state.page + 1 });
};

export const goBack = () => (dispatch, getState) => {
  const state = getState().pickupOrder;
  dispatch({ type: PICKUP_SELECT_PAGE, page: state.page - 1 });
};

export const saveContractNumber = contractNumber => {
  return { type: PICKUP_SAVE_CONTRACT_NUBER, contractNumber };
};

export const saveContactInfo = contact => {
  return { type: PICKUP_SAVE_CONTACT, contact };
};

export const savePickupTime = pickupTime => {
  return { type: PICKUP_SAVE_TIME, pickupTime };
};

export const saveItems = items => {
  return { type: PICKUP_SAVE_ITEMS, items };
};

export const setPossiblePickupTimes = (availableTimes, possibleTimes) => {
  return { type: PICKUP_SET_POSSIBLE_TIMES, availableTimes, possibleTimes };
};

export default (state = initialState, action) => {
  const { type, ...params } = action;

  switch (type) {
    case PICKUP_SELECT_PAGE:
      return { ...state, ...params };
    case PICKUP_SAVE_CONTRACT_NUBER:
      return { ...state, ...params };
    case PICKUP_SAVE_CONTACT:
      return { ...state, ...params };
    case PICKUP_SAVE_TIME:
      return { ...state, ...params };
    case PICKUP_SAVE_ITEMS:
      return { ...state, ...params };
    case PICKUP_SET_POSSIBLE_TIMES:
      return { ...state, ...params };
    default:
      return state;
  }
};
