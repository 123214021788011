/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Link from '../components/Link';
import Logo from './Logo';
import Navigation from './Navigation';
import Container from './Container';
import Search from './Search';
import NavigationToggle from './NavigationToggle';
import BasketButton from './BasketButton';
import NavigationActionLink from './NavigationActionLink';
import UserMenu from './UserMenu';
import { UserIcon } from './Icon';
import Spinner from './Spinner';
import LanguageSelector from './LanguageSelector';
import { isBrowser } from '../utils';
import { getLinkForPage } from '../utils/links';
import { useNavigation } from '../hooks/useNavigation';

const MenuWrapper = props => (
  <Flex
    onFocus={e => {
      props.setMenu(true);
    }}
    sx={{
      position: ['fixed', null, null, 'static'],
      top: '4rem',
      left: 0,
      bottom: 0,
      zIndex: 100,
      flexDirection: ['column', null, null, 'row'],
      justifyContent: ['space-between', null, null, 'flex-start'],
      alignItems: ['flex-start', null, null, 'center'],
      flex: 1,
      p: [3, 4, 3, null, 0],
      visibility: [props.open ? 'visible' : 'hidden', null, null, 'visible'],
      opacity: [props.open ? 1 : 0, 1],
      width: ['100%', null, null, 'auto'],
      bg: ['secondary', null, null, 'transparent'],
      transition: 'opacity .3s ease, visibility .3s ease',
      willChange: 'opacity, visibility',

      '> div, > nav': {
        opacity: [props.open ? 1 : 0, null, null, 1],
        transform: [props.open ? 'translateY(0)' : 'translateY(-28px)', null, null, 'none'],
      },
    }}
  >
    {props.children}
  </Flex>
);

const LinkWithNoTitle = ({ to, children }) => (
  <Link to={to} noTitleFix>
    {children}
  </Link>
);

const removeTrailingSlash = s => {
  return typeof s === 'string' && s.endsWith('/') ? s.substring(0, s.length - 1) : s;
};

const NavLinkWithChildPaths = ({ links, ...props }) => {
  const slugs = (links || []).map(l => (l.page && getLinkForPage(l.page)) || l.url);
  const currentPath = isBrowser && window.location.pathname;
  const isCurrent = slugs.includes(removeTrailingSlash(currentPath));
  return <NavigationActionLink {...props} current={isCurrent} />;
};

const Header = ({
  menu,
  setMenu,
  paths,
  showNavigation,
  navigation,
  parentSlug,
  minimal = false,
  hideLocales,
  ...props
}) => {
  const hasUser = useSelector(state => !!(state.session && state.session.user));
  const isLoadingUser = useSelector(state => !!(state.session && state.session.isLoading));
  const numberOfBasketItems = useSelector(state => ((state.basket && state.basket.items) || []).length);
  const translate = getTranslate(useSelector(state => state.localize));
  const { colorMode } = useThemeUI();
  const locale = useSelector(state => state.session.locale);
  const { navigation: defaultNavLinks, business: businessLinks } = useNavigation(locale);

  return (
    <Box
      as="header"
      sx={{
        position: menu ? ['fixed', null, null, 'relative'] : ['sticky', null, null, 'relative'],
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        pt: [12, 3],
        backgroundColor: menu ? ['secondary', null, null, 'lowContrast'] : 'lowContrast',
        //boxShadow: 'inset 0 -1px 1px #eee',
      }}
    >
      <Container
        variant="wide"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          px: [3, null, null, 4],
          pb: [12, null, null, showNavigation ? 5 : 3],
        }}
      >
        <Box
          sx={{
            width: ['40%', null, null, 'auto'],
            justifySelf: 'flex-start',
          }}
        >
          <NavigationToggle
            close={menu}
            onClick={() => {
              setMenu(!menu);
              if (menu) return;
            }}
          />
        </Box>
        <Box
          as={LinkWithNoTitle}
          to={!minimal ? '/' : parentSlug || '/'}
          sx={{
            flex: 'none',
            width: [74, null, null, 86],
            mr: [0, null, null, 3],
          }}
        >
          <Logo
            sx={{
              width: 'auto',
              height: [48, null, null, 64],
              mt: [0, null, null, 1],
              color: menu
                ? ['white', null, null, colorMode === 'default' ? 'logoNewDarkBlue' : 'white']
                : colorMode === 'default'
                ? 'logoNewDarkBlue'
                : 'white',
            }}
          />
        </Box>

        <MenuWrapper open={menu} setMenu={setMenu}>
          {!minimal && (
            <Flex
              sx={{
                flex: 'none',
                mt: [4, null, null, 0],
                order: [1, null, null, 0],
                ml: [0, null, null, 2],
              }}
            >
              <NavLinkWithChildPaths to="/" links={defaultNavLinks}>
                {translate('navigation.private')}
              </NavLinkWithChildPaths>
              <NavLinkWithChildPaths to="/yrityksille" links={businessLinks}>
                {translate('navigation.business')}
              </NavLinkWithChildPaths>
              <NavigationActionLink to="/palvelupisteet">{translate('navigation.servicePoints')}</NavigationActionLink>
            </Flex>
          )}

          {!minimal && <Search sx={{ order: [0, null, null, 1] }} />}

          <Navigation
            paths={paths}
            navigation={navigation}
            sx={{
              display: showNavigation ? 'flex' : ['flex', null, null, 'none'],
              order: [2, null, null, 2],
              position: ['static', null, null, 'absolute'],
              bottom: 3,
              left: [3, null, null, 4],
            }}
          />

          {!!minimal && <Box sx={{ flexGrow: 1, order: 3 }} />}

          {!minimal ? (
            <LanguageSelector hideLocales={hideLocales} paths={paths} sx={{ order: [4, null, null, 3] }} />
          ) : (
            <NavigationActionLink sx={{ order: 3 }} to="/">
              Matkahuolto.fi &rarr;
            </NavigationActionLink>
          )}
        </MenuWrapper>

        {!!minimal && <Box sx={{ width: ['40%', null, null, 'auto'] }} />}

        {!minimal && (
          <Flex
            sx={{
              width: ['40%', null, null, 'auto'],
              justifyContent: 'flex-end',
            }}
          >
            {!hasUser &&
              (isLoadingUser ? (
                <Box sx={{ position: 'relative', width: ['64px', null, '96px'] }}>
                  <Spinner />
                </Box>
              ) : (
                <NavigationActionLink
                  to="/login"
                  sx={{
                    color: menu ? ['white', null, null, 'hiContrast'] : 'hiContrast',
                  }}
                >
                  <UserIcon sx={{ mr: [0, 2] }} />
                  <Box sx={{ display: ['none', null, null, 'block'] }}>{translate('page.login')}</Box>
                </NavigationActionLink>
              ))}
            {hasUser && <UserMenu />}
            {numberOfBasketItems ? (
              <BasketButton
                numberOfItems={numberOfBasketItems}
                color={menu ? ['white', null, null, 'hiContrast'] : 'hiContrast'}
              />
            ) : null}
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default Header;
