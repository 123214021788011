/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const LenghtInput = ({ innerRef, index, maxValue, onChange }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const [isValid, setIsValid] = useState(true);
  const onChange2 = useCallback(
    e => {
      let v = +e.target.value;
      setIsValid(v <= maxValue);
      onChange();
    },
    [maxValue, onChange]
  );

  return (
    <input
      ref={innerRef}
      size={4}
      type="number"
      min={1}
      max={maxValue}
      sx={{
        minWidth: 70,
        maxWidth: 80,
        borderStyle: 'dashed',
        borderColor: isValid ? 'grayLight' : 'danger',
        borderRadius: '1px',
        fontSize: 2,
        textAlign: 'center',
      }}
      onChange={onChange2}
      placeholder={translate('buyParcel.product.side') + ' ' + index}
    />
  );
};

export default props => {
  const { maxDimension, maxSideSum } = props;
  const translate = getTranslate(useSelector(state => state.localize));
  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const [sum, setSum] = useState(null);
  const ok = sum <= maxSideSum;

  const onChange = useCallback(() => {
    const v1 = +input1.current?.value;
    const v2 = +input2.current?.value;
    const v3 = +input3.current?.value;
    const sum = v1 + v2 + v3;
    setSum(sum);
  }, []);

  return (
    <div
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        py: 2,
        my: 3,
      }}
    >
      <div sx={{ flexGrow: 1, flexShrink: 1 }}>{translate('buyParcel.product.sizeCheck', props)}</div>
      <div sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <LenghtInput innerRef={input1} index={1} maxValue={maxDimension} onChange={onChange} />
        <span sx={{ mx: 2, fontSize: 5, fontWeight: 'normal' }}>+</span>
        <LenghtInput innerRef={input2} index={2} maxValue={maxDimension} onChange={onChange} />
        <span sx={{ mx: 2, fontSize: 5, fontWeight: 'normal' }}>+</span>
        <LenghtInput innerRef={input3} index={3} maxValue={maxDimension} onChange={onChange} />
        <span sx={{ mx: 2, fontSize: 5, fontWeight: 'normal' }}>=</span>
      </div>
      <div
        sx={{
          whiteSpace: 'nowrap',
          fontSize: 3,
          minWidth: 70,
          textAlign: 'right',
          color: ok === false ? 'danger' : 'text',
        }}
      >
        {sum} cm
      </div>
    </div>
  );
};
