import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as moment from 'moment';
import enLoc from 'moment/locale/en-gb';
import fILoc from 'moment/locale/fi';
import svLoc from 'moment/locale/sv';

const getLanguageFromState = state => {
  const {
    localize: { languages },
  } = state;
  const activeLanguage = languages.find(l => l.active);
  return activeLanguage.code || 'fi';
};

const getLocalizeFromState = state => state.localize;

export const useTranslate = () => {
  return getTranslate(useSelector(getLocalizeFromState));
};

export const useLanguage = () => {
  return useSelector(getLanguageFromState);
};

export const useMomentLocale = () => {
  const language = useLanguage();
  moment.locale(language);
};

export default getLanguageFromState;
