export default (to, locale, data) => {
  if (!to || !locale) return to;
  let postfix = '';
  const postfixIndex = to.indexOf('?');
  if (postfixIndex !== -1) {
    postfix = to.substring(postfixIndex);
    to = to.substring(0, postfixIndex);
  }
  if (data.allSitePage && data.allSitePage.nodes) {
      const node = data.allSitePage.nodes.find(node => node.path === to);
      if (node && node.context && node.context.paths && node.context.paths[locale]) {            
          return node.context.paths[locale] + postfix;
      } 
  }
  // fallback
  return to + postfix;
};
