/** @jsx jsx */
import { jsx, Box, Label, Styled } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { getTranslate } from 'react-localize-redux';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import Contact from './Contact';
import getLanguage from '../utils/getLanguage';

const getOptionLabel = ({ code, name, municipality }) => (
  <Box>
    {code} {municipality}
  </Box>
);

export default ({ excel, assets, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulContact {
          edges {
            node {
              ...contactFields
              node_locale
            }
          }
        }
      }
    `
  );

  const locale = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const postalCodeAreas = (assets[excel] || {}).data || [];
  const [selectedOption, setSelected] = useState(null);
  const [contactResults, setContactResults] = useState([]);

  const loadOptions = useCallback(
    async inputValue => {
      if (inputValue.length < 3) {
        return [];
      }
      const input = inputValue.toLowerCase();
      return postalCodeAreas
        .filter(
          p => p.code.startsWith(inputValue) || p.n.includes(input) // || p.municipality.toLowerCase().includes(input)
        )
        .sort((a, b) => {
          const aStarts = a.code.startsWith(input)
            ? 3
            : a.municipality.toLowerCase().startsWith(input)
            ? 2
            : a.n.startsWith(input)
            ? 1
            : 0;
          const bStarts = b.code.startsWith(input)
            ? 3
            : b.municipality.toLowerCase().startsWith(input)
            ? 2
            : b.n.startsWith(input)
            ? 1
            : 0;
          if (aStarts > bStarts) {
            return -1;
          } else if (bStarts > aStarts) {
            return 1;
          }
          // return a.municipality.localeCompare(b.municipality);
          return a.code.localeCompare(b.code);
        });
    },
    [postalCodeAreas]
  );

  const isOptionSelected = useCallback(option => selectedOption && selectedOption.code === option.code, [
    selectedOption,
  ]);

  const onChange = useCallback(
    option => {
      setSelected(option);
      const contacts = data.allContentfulContact.edges?.map(e => e.node);
      const shownContacts = contacts.filter(
        n => n?.name && option?.salesPerson && n.name === option?.salesPerson && n.node_locale.startsWith(locale)
      );
      setContactResults(shownContacts);
    },
    [data]
  );

  return (
    <Box sx={{ p: [3, 24], mx: [-3, 0], bg: 'blueLighter', borderRadius: [0, 2] }}>
      <Box>
        <Label sx={{ mb: 2 }}>{translate('salesAreaSearch.query')}</Label>
        <AsyncSelect
          getOptionLabel={getOptionLabel}
          loadOptions={loadOptions}
          isOptionSelected={isOptionSelected}
          onChange={onChange}
          noOptionsMessage={() => null}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Placeholder: () => null,
          }}
        />
      </Box>
      {contactResults.length > 0 && (
        <Box sx={{ mb: -24 }}>
          <Styled.h4 as="h3" sx={{ mt: 24 }}>
            {translate('salesAreaSearch.results')}
          </Styled.h4>
          {contactResults.map((contact, i) => (
            <Contact
              key={i}
              {...contact}
              image={get(contact, 'image.file.url')}
              info={get(contact, 'info.childMarkdownRemark.html')}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
