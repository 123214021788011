/** @jsx jsx */
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { jsx, Flex, Box, Styled } from 'theme-ui';
import { Context } from './Context';
import { Button, FullHeightColumn } from '../components';
import ServiceCard from '../parcelFlow/ServiceCard';
import { ariaLabelForService } from '../parcelFlow/ExtraServices';
import { RecipientFormik } from '../parcelFlow/Recipient';
import { ServiceCode } from '../state/catalog';
import { formatPrice, formatSize } from '../utils/formatters';
import { useTranslate } from '../utils/getLanguage';

const ServiceList = ({ services, selectedServices = [] }) => {
  const translate = useTranslate();
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.service'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : services.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < services.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const service = services[i];
            if (service) {
              setImmediate(() => {
                service.onClick();
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [services, setFocusIndex]
  );

  return (
    <Box
      ref={container}
      sx={{ maxWidth: 640, my: 3 }}
      role="group"
      aria-label={translate('buyParcel.services.title')}
      onKeyDown={onKeyDown}
    >
      {services.length === 0 ? translate('buyParcel.services.noServices') : null}
      {services.map((s, index) => {
        const isSelected = !!selectedServices.find(s2 => s2.serviceCode === s.serviceCode);
        const disabled = false;
        return (
          <Box
            sx={{ display: 'relative', width: '100%', my: 2 }}
            key={s.serviceCode}
            onClick={disabled ? null : s.onClick}
            className="service"
            role="checkbox"
            aria-label={ariaLabelForService(s, translate)}
            aria-checked={isSelected}
            tabIndex={focusIndex === index ? 0 : -1}
          >
            <ServiceCard service={s} isSelected={isSelected} disabled={disabled} onClick={s.onClick} />
          </Box>
        );
      })}
    </Box>
  );
};

export default () => {
  const translate = useTranslate();
  const { origItem, upgradeData, toggleService, setRecipient, createOrder, goBack, goNext } = useContext(Context);

  const hasCurrentServices = (origItem.services || []).length > 0;
  const newServices = upgradeData.services || [];
  const hasNothingNew = origItem.product.sizeCode === upgradeData?.product?.sizeCode && newServices.length === 0;
  const requireAddress = !!newServices.find(s =>
    [ServiceCode.Delivery8_16, ServiceCode.DeliveryAgreedTime].includes(s.serviceCode)
  );
  const askRemarks =
    newServices.find(s => s.serviceCode === ServiceCode.Delivery8_16) || upgradeData?.product?.deliveryIncluded;
  const services = useMemo(() => {
    return upgradeData.product.services.map(s => ({
      ...s,
      onClick: () => toggleService(s),
    }));
  }, [upgradeData.product, toggleService]);
  const showSize = !!upgradeData?.product?.maxSize;
  const totalPrice = newServices.reduce((sum, service) => sum + service.price, upgradeData?.product?.price);

  const onSubmit = useCallback(
    async values => {
      if (requireAddress) {
        setRecipient(values);
      } else {
        setRecipient(null);
      }
      if (totalPrice === 0) {
        createOrder({ paymentMethod: 'FREE' });
      } else {
        goNext();
      }
    },
    [requireAddress, totalPrice, setRecipient, createOrder, goNext]
  );

  const formProps = {
    recipient: { ...origItem.recipient, ...(upgradeData.recipient || {}) },
    country: 'FI',
    requireAddress: true,
    eitherPhoneOrEmail: false,
    askRemarks,
    askUnattended: askRemarks,
    toAbroad: false,
    askVATNumber: false,
    buttonText: translate('upgrade.services.continue'),
    onSubmit,
    translate,
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', my: 4 }}>{translate('upgrade.services.title')}</Styled.h1>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr', columnGap: 3 }}>
        <Box>{translate('upgrade.services.selectedProduct')}</Box>
        <Box>
          {upgradeData?.product?.sizeCode} {showSize && `(${formatSize(upgradeData?.product?.maxSize)})`}
        </Box>
        <Box>
          {translate('upgrade.services.price')} {formatPrice(upgradeData?.product?.price)}
        </Box>
        {hasCurrentServices && <Box>{translate('upgrade.services.selectedService')}</Box>}
        {(origItem?.services || []).map(s => (
          <Box key={s.serviceCode} sx={{ gridColumn: '2' }}>
            {s.displayName}
          </Box>
        ))}
      </Box>

      <ServiceList services={services} selectedServices={upgradeData.services} />

      <Box sx={{ color: 'primary', fontSize: 4, fontWeight: 'medium' }}>
        {translate('upgrade.services.price')} {formatPrice(totalPrice)}
      </Box>

      <Box sx={{ mb: 4, display: requireAddress ? 'block' : 'none' }}>
        <Styled.h2 sx={{ color: 'secondary' }}>{translate('upgrade.services.recipient')}</Styled.h2>
        <RecipientFormik {...formProps} />
      </Box>
      {!requireAddress && (
        <Flex
          sx={{
            mt: 3,
            flexDirection: ['row-reverse', null, 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{ alignSelf: ['flex-end', null, 'flex-start'], flex: 'none' }}
            onClick={onSubmit}
            disabled={hasNothingNew}
          >
            {translate(totalPrice ? 'upgrade.services.continue' : 'upgrade.services.continueFree')}
          </Button>
        </Flex>
      )}
    </FullHeightColumn>
  );
};
