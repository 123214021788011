import { useRef } from 'react';
import { useState } from 'react';
import * as api from '../utils/api';
import { useEffect } from 'react';

export const useDeliveryEstimate = shipment => {
  const [deliveryEstimate, setDeliveryEstimate] = useState(null);
  const estimateOnlyOnce = useRef(false);
  useEffect(() => {
    const status = shipment.shipmentStatus;
    if (!status || +status < 4 || +status >= 55) return;
    if (deliveryEstimate) return;
    if (estimateOnlyOnce.current) return;
    estimateOnlyOnce.current = true;
    (async () => {
      try {
        const response = await api.getDeliveryEstimate(shipment);
        if (!response.delivery) return;
        setDeliveryEstimate(response);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [shipment, deliveryEstimate]);
  return deliveryEstimate;
};
