/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ServicePointContainer from './ServicePointContainer';
import {
  Address,
  AlertRemarks,
  CutoffTimesAndTitle,
  IBoxenNote,
  NormalRemarks,
  OpenTimesAndTitle,
  ServicesList,
} from './ServiceDetails';
import { Button, Spinner } from '../components';
import { useTranslate } from '../utils/getLanguage';
import { cloneDeep } from 'lodash';
import { haversineDistance, sortByDistance } from '../utils/distance';

const NUMBER_OF_PRIORITIZED_AUTOMATS = 6;

const sortPoints = (points = [], position) => {
  let automats = points.filter(point => point.officeType === 'EASYPACK');
  let servicePoints = points.filter(point => point.officeType !== 'EASYPACK');

  if (position) {
    automats = sortByDistance(automats, position);
    servicePoints = sortByDistance(servicePoints, position);
  }

  const top4Automats = automats.slice(0, NUMBER_OF_PRIORITIZED_AUTOMATS);
  const remainingAutomats = automats.slice(NUMBER_OF_PRIORITIZED_AUTOMATS);

  const combinedPoints = position
    ? sortByDistance([...servicePoints, ...remainingAutomats], position)
    : [...servicePoints, ...remainingAutomats];

  return [...top4Automats, ...combinedPoints];
};

const orderServicePointList = (allPoints, focus, priorityPoints) => {
  // deep clone to avoid react state mutation and crash caused by doing it
  let aPoints = cloneDeep(allPoints);

  const focusPoint = focus ? { latitude: focus.lat, longitude: focus.lng } : null;
  return sortPoints(aPoints, focusPoint);
};

function ServicePointList({ servicePoints, onSelect, priorityPoints }) {
  const [sortedServicePoints, setSortedServicePoints] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { fetching, focus } = useSelector(state => state.servicePoints);
  const translate = useTranslate();

  const initialNumberOfPoints = 6;
  const maxNumberOfPointsShown = 50;

  useEffect(() => {
    const sorted = orderServicePointList(servicePoints, focus, priorityPoints);
    setSortedServicePoints(sorted.slice(0, maxNumberOfPointsShown)); // list at most 50 offices
  }, [servicePoints, focus, priorityPoints]);

  const shownServicePoints = useMemo(() => {
    if (showMore) {
      return sortedServicePoints;
    }
    return (sortedServicePoints || []).slice(0, initialNumberOfPoints);
  }, [showMore, sortedServicePoints]);

  if (!servicePoints) return null;

  if (fetching) {
    return (
      <Box sx={{ position: 'relative', py: 5 }}>
        <Spinner size="medium" />
      </Box>
    );
  } else if (servicePoints && !servicePoints.length) {
    return (
      <Box>
        <Styled.h2 sx={{ mb: 4, mt: 3 }}>{translate('servicePoints.noPointsAtAreaTitle')}</Styled.h2>
        <p>{translate('servicePoints.noPointsAtArea')}</p>
      </Box>
    );
  }

  return (
    <>
      <Styled.h2 sx={{ mb: 4, mt: 3 }}>{translate('servicePoints.pointsAtArea')}</Styled.h2>
      {shownServicePoints && shownServicePoints.length > 0 && (
        <>
          <ServicePointContainer>
            {shownServicePoints.map((point, index) => (
              <ServicePoint key={index} servicePoint={point} onSelect={() => onSelect(point)} />
            ))}
          </ServicePointContainer>
          {!showMore && sortedServicePoints.length > initialNumberOfPoints && (
            <Flex sx={{ flex: 1, my: 4, justifyContent: 'center' }}>
              <Button variant="secondary" onClick={() => setShowMore(true)}>
                {translate('servicePoints.showMore')}
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  );
}

export const ServicePointSingleView = ({ servicePoint, onGoBack }) => {
  const { fetching, focus } = useSelector(state => state.servicePoints);
  const translate = useTranslate();

  const { officeType, parcelservices: [parcelServices = {}] = [], services } = servicePoint || {};

  const distance = useMemo(() => {
    if (!focus || !servicePoint || !servicePoint.latitude) {
      return null;
    }
    const dist = haversineDistance(
      { latitude: servicePoint.latitude, longitude: servicePoint.longitude },
      { latitude: focus.lat, longitude: focus.lng }
    );
    return dist && Number(dist).toFixed(1);
  }, [servicePoint, focus]);

  if (!servicePoint) return null;

  if (fetching) {
    return (
      <Box sx={{ position: 'relative', py: 5 }}>
        <Spinner size="medium" />
      </Box>
    );
  }

  return (
    <>
      <Button onClick={onGoBack} variant="plain" sx={{ color: 'primary', fontSize: 4 }}>
        {translate('delivery.agree.back')}
      </Button>

      <Flex
        sx={{ flexDirection: ['column', null, null, 'row'], justifyContent: 'space-between', gap: [0, null, null, 4] }}
      >
        <Box sx={{ flex: 1 }}>
          <Styled.h2>
            <Flex sx={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
              {servicePoint.officeName ||
                servicePoint.officeOrganizationName ||
                translate('servicePoints.unnamedPoint')}
              {distance && <span sx={{ fontSize: 2, color: 'black' }}>{distance} km</span>}
            </Flex>
          </Styled.h2>

          <Box
            sx={{
              lineHeight: 'heading',
            }}
          >
            <Address servicePoint={servicePoint} sxx={{ textDecoration: 'none', mt: 4 }} />
            <ServicesList services={services} officeType={officeType} />
            <AlertRemarks alertRemarks={parcelServices?.alertRemarks} />
            <IBoxenNote isIBoxen={servicePoint?.officeChain === 'IBOXEN'} />
            <OpenTimesAndTitle openTimes={parcelServices?.openTimes} />
            <CutoffTimesAndTitle cutoffTimes={parcelServices?.cutoffTimes} />
          </Box>
        </Box>
        {parcelServices?.normalRemarks && (
          <Box sx={{ flex: 1, mb: 4 }}>
            <p sx={{ mb: 1, mt: [1, null, null, 4], color: 'black', fontWeight: 500 }}>
              {translate('servicePoints.additionalInfo')}
            </p>
            <NormalRemarks normalRemarks={parcelServices?.normalRemarks} showAlways={true} />
          </Box>
        )}
      </Flex>
    </>
  );
};

export const ServicePoint = ({ index, servicePoint, onSelect }) => {
  const { officeType, parcelservices: [parcelServices = {}] = [], services } = servicePoint || {};
  const translate = useTranslate();
  const { focus } = useSelector(state => state.servicePoints);

  const distance = useMemo(() => {
    if (!focus || !servicePoint || !servicePoint.latitude) {
      return null;
    }
    const dist = haversineDistance(
      { latitude: servicePoint.latitude, longitude: servicePoint.longitude },
      { latitude: focus.lat, longitude: focus.lng }
    );
    return dist && Number(dist).toFixed(1);
  }, [servicePoint, focus]);

  if (!servicePoint) {
    return null;
  }
  return (
    <Box
      key={index}
      id={index}
      sx={{
        p: 3,
        lineHeight: 'heading',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 -4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Flex sx={{ flexDirection: 'row', gap: 4, justifyContent: 'space-between' }}>
        <Button
          variant="plain"
          onClick={onSelect}
          sx={{
            fontWeight: 'medium',
            color: 'black',
            pt: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {servicePoint.officeName || servicePoint.officeOrganizationName || translate('servicePoints.unnamedPoint')}
        </Button>
        {distance && (
          <Box sx={{ float: 'right', color: 'black', fontWeight: 'semibold', whiteSpace: 'nowrap' }}>{distance} km</Box>
        )}
      </Flex>

      <Address servicePoint={servicePoint} onClick={onSelect} />
      <ServicesList services={services} officeType={officeType} />
      <AlertRemarks alertRemarks={parcelServices?.alertRemarks} />
      <NormalRemarks normalRemarks={parcelServices?.normalRemarks} showAlways={false} onSelect={onSelect} />
      <IBoxenNote isIBoxen={servicePoint?.officeChain === 'IBOXEN'} />
      <OpenTimesAndTitle openTimes={parcelServices?.openTimes} />
      <CutoffTimesAndTitle cutoffTimes={parcelServices?.cutoffTimes} />
    </Box>
  );
};

ServicePointList.propTypes = {
  servicePoints: PropTypes.arrayOf(PropTypes.object),
  priorityPoints: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
};

export default ServicePointList;
