import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';

const translateName = (to, locale, data, fallback) => {
  if (!to || !locale) return fallback;
  if (data.allSitePage && data.allSitePage.nodes) {
    const node = data.allSitePage.nodes.find(node => node.path === to);
    if (node && node.context && node.context.titles && node.context.titles[locale]) {
      return node.context.titles[locale];
    }
  }
  return fallback;
};

export const PageTitle = ({ to, fallback, ...rest }) => {
  const locale = useSelector(state => state.session.locale);
  return (
    <StaticQuery
      query={graphql`
        query PageLinksQuery {
          allSitePage {
            nodes {
              context {
                paths {
                  en
                  fi
                  sv
                }
                titles {
                  en
                  fi
                  sv
                }
                slug
              }
              path
            }
          }
        }
      `}
      render={data => {
        const translatedName = translateName(to, locale, data, fallback);
        return <span {...rest}>{translatedName}</span>;
      }}
    />
  );
};
