/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, Box } from 'theme-ui';
import { Highlight, Snippet } from 'react-instantsearch-dom';
import get from 'lodash/get';
//import { Link } from 'gatsby';
import moment from 'moment';
import Accordion from '../Accordion';
import Link from '../../components/Link';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useSelector } from 'react-redux';
import { getLinkForPage } from '../../utils/links';

const linkToHit = (hit, edges, loc) => {
  const hitSlug = (hit.slug && '/' + (hit.slug[loc] || hit.slug['fi-FI'])) || '';
  if (hit.type === 'blogPost') {
    const postType = hit.postType && hit.postType['fi-FI'];
    return (postType === 'Blog' ? '/blog' : '/news') + hitSlug;
  } else if (hit.type === 'searchKeyword') {
    return hit.link[loc] || hit.link['fi-FI'];
  } else {
    // find parent url
    if (edges) {
      let edge = edges.find(edge => edge.node.contentful_id === hit.contentId && edge.node.node_locale === loc);
      const parentSlug = edge && getLinkForPage(edge.node && edge.node.parentPage);
      if (parentSlug) {
        return `${parentSlug}${hitSlug}`;
      }
    }
  }
  return hitSlug;
};

const useLocale = () => {
  const locale = useSelector(state => state.session.locale);
  if (locale === 'sv') {
    return 'sv-SE';
  } else if (locale === 'en') {
    return 'en-US';
  }
  return 'fi-FI';
};

export const PageHitOld = (clickHandler, edges) => ({ hit }) => {
  if (hit.type === 'frequentlyAskedQuestion') {
    return null;
  }
  return <PageHitOld2 hit={hit} clickHandler={clickHandler} edges={edges} />;
};

export const PageHitOld2 = ({ hit, clickHandler, edges }) => {
  const loc = useLocale();
  let titleAttribute = 'title.' + loc;
  if (!hit.title[loc]) {
    titleAttribute = 'title.fi-FI';
  }
  let bodyField = hit.body[loc] || hit.body['fi-FI'];
  let body = typeof bodyField === 'object' ? documentToPlainTextString(bodyField) : bodyField;
  const link = linkToHit(hit, edges, loc);
  if (body.length > 150) {
    body = body.substring(0, 150) + '...';
  }
  return (
    <Box
      as={Link}
      to={{ link, external: true }}
      onClick={clickHandler}
      sx={{
        display: 'block',
        my: 12,
        pt: 12,
        lineHeight: 'normal',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: 'border',
      }}
    >
      <Box sx={{ fontWeight: 'medium', mb: 1 }}>
        <Highlight attribute={titleAttribute} hit={hit} tagName="mark" />
      </Box>
      <Box sx={{ fontSize: 1, color: 'secondary' }}>{body}</Box>
    </Box>
  );
};

export const PageHit = ({ hit, clickHandler, edges }) => {
  const loc = useLocale();
  let titleAttribute = 'title.' + loc;
  if (!hit.title[loc]) {
    titleAttribute = 'title.fi-FI';
  }
  const link = linkToHit(hit, edges, loc);

  return (
    <Box
      className={'hit'}
      as={Link}
      to={{ link, external: true }}
      onClick={clickHandler}
      tabIndex="0"
      sx={{
        ':focus:not(:active)': {
          boxShadow: 'inputFocus',
        },
        display: 'block',
        my: 10,
        lineHeight: 'normal',
      }}
    >
      <Box sx={{ fontWeight: 'medium', mb: 1 }}>
        <Highlight attribute={titleAttribute} hit={hit} tagName="mark" />
      </Box>
    </Box>
  );
};

export const AccordionHit = ({ hit, clickHandler, edges }) => {
  const loc = useLocale();
  let titleAttribute = 'title.' + loc;
  if (!hit.title[loc]) {
    titleAttribute = 'title.fi-FI';
  }
  const link = hit.searchTarget[loc] || hit.searchTarget['fi-FI'];

  return (
    <Box
      className={'hit'}
      as={Link}
      to={{ link, external: true }}
      onClick={clickHandler}
      tabIndex="0"
      sx={{
        ':focus:not(:active)': {
          boxShadow: 'inputFocus',
        },
        display: 'block',
        my: 10,
        lineHeight: 'normal',
      }}
    >
      <Box sx={{ fontWeight: 'medium', mb: 1 }}>
        <Highlight attribute={titleAttribute} hit={hit} tagName="mark" />
      </Box>
    </Box>
  );
};

export const NewsHit = ({ hit, clickHandler, edges }) => {
  const loc = useLocale();
  let titleAttribute = 'title.' + loc;
  if (!hit.title[loc]) {
    titleAttribute = 'title.fi-FI';
  }
  const link = linkToHit(hit, edges, loc);
  const { publishTime } = hit;
  const pubTime = publishTime && (publishTime[loc] || publishTime['fi-FI']);

  return (
    <Box
      className={'hit'}
      as={Link}
      to={{ link, external: true }}
      onClick={clickHandler}
      tabIndex="0"
      sx={{
        ':focus:not(:active)': {
          boxShadow: 'inputFocus',
        },
        display: 'block',
        my: 10,
        lineHeight: 'normal',
      }}
    >
      {pubTime && <Box sx={{ fontSize: 1, float: 'right' }}>{moment(pubTime).format('D.M.YYYY')}</Box>}
      <Box sx={{ fontWeight: 'medium', mb: 1 }}>
        <Highlight attribute={titleAttribute} hit={hit} tagName="mark" />
      </Box>
    </Box>
  );
};

export const FAQHit = ({ hit, clickHandler, edges }) => {
  const loc = useLocale();
  const question = hit.question[loc] || hit.question['fi-FI'];
  const answer = hit.answer[loc] || hit.answer['fi-FI'];
  return (
    <Accordion className={'hit'} variant="faq" title={question}>
      <Box>{answer}</Box>
    </Accordion>
  );
};
