export function capitalize(s) {
  if (typeof s !== 'string') {
    return s;
  }
  return s
    .toLowerCase()
    .replace(/^.|\s\S/g, function(a) {
      return a.toUpperCase();
    })
    .replace('Mh', 'MH');
}
