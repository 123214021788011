import createClusteringIcon from './clusteringIcon';
import createMarkerIcon from './markerIcon';
const markerUrl = '/map-marker.svg';
const ownMarkerUrl = '/map-marker-own.svg';

/**
 * Returns clustering theme description object.
 * Implements H.clustering.ITheme
 * https://developer.here.com/documentation/maps/dev_guide/topics_api/h-clustering-itheme.html
 */
export default (HERE) => {
  const markerIcon = createMarkerIcon(HERE, markerUrl);
  const ownMarkerIcon = createMarkerIcon(HERE, ownMarkerUrl);
  
  const clusteringTheme = {
    getClusterPresentation: cluster => {
      const clusterMarker = new HERE.map.DomMarker(
        cluster.getPosition(),
        {
          // Weight is used to indicate number of markers in cluster
          icon: createClusteringIcon(HERE, cluster.getWeight()),
          // Set min/max zoom with values from the cluster,
          // otherwise clusters will be shown at all zoom levels:
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom()
        }
      );
      return clusterMarker;
    },
    getNoisePresentation: noisePoint => {
      const data = noisePoint.getData();
      const noiseMarker = new HERE.map.DomMarker(
        noisePoint.getPosition(),
        {
          // Use min zoom from a noise point
          // to show it correctly at certain zoom levels:
          min: noisePoint.getMinZoom(),
          icon: data && data.isOwn ? ownMarkerIcon : markerIcon,
        }
      );
      noiseMarker.setData(noisePoint.getData());
      return noiseMarker;
    }
  };

  return clusteringTheme;
};
