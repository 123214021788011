/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import {
  goBack,
  goNext,
  saveRecipient,
  itemHasHomeDelivery,
  askDeliveryRemarks,
  checkIfJysDeliveryPossible,
} from '../state/parcelFlow';
import { phoneRegExp, postalCodeRegExpForCountry, swedishPhoneRegExp } from '../utils/regExp';
import RecipientForm from './RecipientForm';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { xxsDeliveryEnabled } from '../state/catalog';
import { prefetchPage } from '../state/session';
import { isFinlandOrAland, CountryCode } from '../utils/countryCode';
import * as streetAndApartment from '../utils/streetAndApartment';

const recipientSchema = (translate, eitherPhoneOrEmail = false, toAbroad, country) => {
  const phoneRegexp = country === 'SE' ? swedishPhoneRegExp : phoneRegExp;
  const validationMsg =
    country === 'SE' ? translate('form.invalidSwedishPhoneNumber') : translate('form.invalidPhoneNumber');
  let phoneValidation = yup.string().matches(phoneRegexp, validationMsg);

  if (!eitherPhoneOrEmail) {
    phoneValidation = phoneValidation.required(translate('form.requiredField'));
  }
  return yup.object().shape({
    name: yup
      .string()
      .required(translate('form.requiredField'))
      .min(5, translate('form.tooShort', { len: 5 }))
      .max(35, translate('form.tooLong', { len: 35 })),
    phone: phoneValidation,
    email:
      country !== 'FI'
        ? yup
            .string()
            .email(translate('form.invalidEmail'))
            .required(translate('form.requiredField'))
        : yup.string().email(translate('form.invalidEmail')),
  });
};

const addressSchema = (translate, country, toAbroad) =>
  yup.object().shape({
    street: toAbroad
      ? yup
          .string()
          .required(translate('form.requiredField'))
          .max(35, translate('form.tooLong', { len: 35 }))
      : yup.string().required(translate('form.requiredField')),
    apartment: yup.string(),
    postcode: yup
      .string()
      .required(translate('form.requiredField'))
      .matches(postalCodeRegExpForCountry(country), translate('form.invalidPostcode')),
    city: yup.string().required(translate('form.requiredField')),
  });

export const RecipientFormik = withFormik({
  mapPropsToValues: ({ recipient, requireAddress, askRemarks, askUnattended, toAbroad, askVATNumber, country }) =>
    Object.assign(
      {
        name: recipient.name || '',
        phone: recipient.phone || '',
        email: recipient.email || '',
      },
      requireAddress
        ? {
            street: recipient.street || '',
            apartment: recipient.apartment || '',
            postcode: recipient.postcode || '',
            city: recipient.city || '',
          }
        : {},
      askRemarks
        ? {
            remarks: recipient.remarks || '',
          }
        : {},
      askUnattended
        ? {
            unattendedHandover: recipient.unattendedHandover || 'ask',
          }
        : {},
      askVATNumber ? { vatNumber: recipient.vatNumber } : {}
    ),

  validationSchema: ({
    requireAddress,
    eitherPhoneOrEmail,
    askRemarks,
    askUnattended,
    toAbroad,
    askVATNumber,
    country,
    translate,
  }) => {
    let schema = recipientSchema(translate, eitherPhoneOrEmail, toAbroad, country);
    if (requireAddress) {
      schema = schema.concat(addressSchema(translate, country, toAbroad));
    }
    if (askRemarks) {
      schema = schema.concat(
        yup.object().shape({
          remarks: yup.string(),
        })
      );
    }
    if (askUnattended) {
      schema = schema.concat(
        yup.object().shape({
          unattendedHandover: yup
            .string()
            .oneOf(['yes', 'no'], translate('form.requiredField'))
            .required(translate('form.requiredField')),
        })
      );
    }
    if (askVATNumber) {
      schema = schema.concat(
        yup.object().shape({
          vatNumber: yup.string().matches(/^[0-9]{7}-[0-9]$/, translate('form.invalidVATNumber')),
        })
      );
    }

    return schema;
  },

  handleSubmit: (
    values,
    { props: { onSubmit, eitherPhoneOrEmail, translate, toAbroad }, setFieldError, setSubmitting }
  ) => {
    if (eitherPhoneOrEmail && !values.phone && !values.email) {
      setFieldError('phone', translate('buyParcel.recipient.eitherPhoneOrEmail'));
      setFieldError('email', ' ');
      setSubmitting(false);
      return;
    }
    if (toAbroad && streetAndApartment.combine(values).length > 35) {
      if (values.street.length > 30) {
        setFieldError('street', translate('form.tooLong', { len: 30 }));
      }
      if (values.apartment.length > 4) {
        setFieldError('apartment', translate('form.tooLong', { len: 4 }));
      }
      setSubmitting(false);
      return;
    }
    return onSubmit(values);
  },

  displayName: 'RecipientInputForm',
})(RecipientForm);

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const recipient = useSelector(state => state.parcelFlow.recipient) || {};
  const country = useSelector(state => state.parcelFlow.country) || CountryCode.Finland;
  const toAbroad = !isFinlandOrAland(country);
  const hasJPLSelected = useSelector(state => !!(state.parcelFlow.services || []).find(s => s.serviceCode === 'JPL'));
  const xxsDelivery = false; /*
    useSelector(
      state =>
        state.parcelFlow.country === CountryCode.Finland &&
        state.parcelFlow.product.sizeCode === 'XXS' &&
        !state.parcelFlow.seriesId
    ) && xxsDeliveryEnabled; */
  const requireAddress =
    useSelector(state => itemHasHomeDelivery(state.parcelFlow)) || hasJPLSelected || xxsDelivery || toAbroad;
  const askRemarks = useSelector(state => askDeliveryRemarks(state.parcelFlow)) || hasJPLSelected || xxsDelivery;
  const askUnattended = askRemarks && !hasJPLSelected && !xxsDelivery && !toAbroad;
  const eitherPhoneOrEmail = false; // !!toAbroad;
  const askVATNumber = country === CountryCode.Aland;

  const onSubmit = useCallback(
    async values => {
      await dispatch(saveRecipient(values));
      if (xxsDelivery) {
        await dispatch(checkIfJysDeliveryPossible(values));
      }
      await dispatch(goNext());
    },
    [dispatch, xxsDelivery]
  );
  const formProps = {
    recipient,
    country,
    requireAddress,
    eitherPhoneOrEmail,
    askRemarks,
    askUnattended,
    toAbroad,
    askVATNumber,
    onSubmit,
    translate,
  };
  if (requireAddress) {
    dispatch(prefetchPage('/ostoskori'));
  }

  return (
    <FullHeightColumn>
      <Box sx={{ flex: 'none' }}>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ flex: 'none', color: 'secondary', mt: 0 }}>{translate('buyParcel.recipient.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {country === 'SE' ? (
          <Box sx={{ px: 3, my: 3, bg: 'blueLighter', borderRadius: 1, mr: [0, 0, 6, 6] }}>
            <p>{translate('buyParcel.recipient.swedishPhoneNote')}</p>
          </Box>
        ) : null}
        <RecipientFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
