/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useState } from 'react';
import uniqueId from 'lodash/uniqueId';

export default props => {
  const [id] = useState(uniqueId('logo-'));

  const fill = 'currentColor';
  return (
    <Box
      as="svg"
      width="110"
      height="70"
      viewBox="0 0 110 70"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby={id}
      sx={{ display: 'block', color: 'primary' }}
    >
      <g
        id="Icon/new-logo-dark-blue"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        transform="translate(-3,-3)"
      >
        <g id="Matkahuolto_logo_round_DarkBlue_RGB" transform="translate(3,3)" fill={fill} fill-rule="nonzero">
          <g id="Group" transform="translate(0,25.196)">
            <path
              d="M 35.706253,0 H 33.642316 C 33.540082,0 33.45711,0.08293033 33.45711,0.18511234 V 12.676493 c 0,0.102182 0.08297,0.185112 0.185206,0.185112 h 2.063937 c 0.102233,0 0.185206,-0.08293 0.185206,-0.185112 V 8.9357429 c 0,-0.057755 0.07408,-0.084411 0.109642,-0.038503 l 3.084792,3.8710691 c 0.04741,0.05924 0.118532,0.0933 0.192615,0.0933 h 2.770683 c 0.106678,0 0.162981,-0.125876 0.09186,-0.205844 L 38.100597,8.1256913 c -0.03111,-0.035542 -0.03111,-0.087373 0,-0.1229146 l 4.009341,-4.5656108 c 0.06964,-0.079969 0.01333,-0.204364 -0.09334,-0.204364 H 39.24443 c -0.07556,0 -0.146684,0.034061 -0.194096,0.093297 l -3.049233,3.8518176 c -0.03704,0.045908 -0.109642,0.020733 -0.109642,-0.038503 V 0.18511234 C 35.891459,0.08293033 35.808486,0 35.706253,0 Z"
              id="Path"
            />
            <path
              d="m 59.944544,12.861605 h 2.063937 c 0.102233,0 0.185206,-0.08293 0.185206,-0.185112 V 7.2134577 c 0,-0.8692876 -0.160018,-1.6126987 -0.478573,-2.2331953 C 61.39656,4.3612467 60.952065,3.8918018 60.384594,3.5719277 59.817122,3.2535345 59.16816,3.0935974 58.437707,3.0935974 c -0.637109,0 -1.216433,0.1184719 -1.739455,0.3568966 -0.463756,0.2117685 -0.84454,0.4931393 -1.143833,0.8455932 -0.03704,0.044427 -0.108161,0.020733 -0.108161,-0.038503 V 0.18511234 C 55.446258,0.08293033 55.363286,0 55.261052,0 H 53.197116 C 53.094882,0 53.01191,0.08293033 53.01191,0.18511234 V 12.676493 c 0,0.102182 0.08297,0.185112 0.185206,0.185112 h 2.063936 c 0.102234,0 0.185206,-0.08293 0.185206,-0.185112 V 7.5436981 c 0,-0.7641438 0.194096,-1.3520606 0.582288,-1.7637504 0.388192,-0.4116898 0.918623,-0.6175348 1.591291,-0.6175348 0.660816,0 1.182356,0.205845 1.564621,0.6175348 0.382266,0.4116898 0.573398,0.9996066 0.573398,1.7637504 v 5.1327949 c 0,0.102182 0.08297,0.185112 0.185206,0.185112 z"
              id="path3"
            />
            <path
              d="m 70.381278,12.861605 h 2.081716 c 0.102234,0 0.185206,-0.08293 0.185206,-0.185112 V 3.4179143 c 0,-0.1021821 -0.08297,-0.1851124 -0.185206,-0.1851124 h -2.081716 c -0.102234,0 -0.185206,0.08293 -0.185206,0.1851124 v 5.1150241 c 0,0.7641438 -0.191133,1.3535415 -0.573398,1.7637506 -0.382266,0.41169 -0.909733,0.617535 -1.582401,0.617535 -0.672669,0 -1.182356,-0.205845 -1.564621,-0.617535 C 66.093386,9.884999 65.902253,9.2970822 65.902253,8.5329384 V 3.4179143 c 0,-0.1021821 -0.08297,-0.1851124 -0.185206,-0.1851124 h -2.063936 c -0.102234,0 -0.185206,0.08293 -0.185206,0.1851124 v 5.4630353 c 0,0.8574404 0.165944,1.5964084 0.49487,2.2154244 0.330408,0.620497 0.785274,1.088461 1.364599,1.408335 0.579325,0.318393 1.246066,0.47833 2.000226,0.47833 0.591177,0 1.139388,-0.118472 1.643148,-0.356896 0.447458,-0.210288 0.820833,-0.49314 1.117163,-0.845594 0.03704,-0.04443 0.108161,-0.02073 0.108161,0.0385 v 0.85744 c 0,0.102182 0.08297,0.185112 0.185206,0.185112 z"
              id="path5"
            />
            <path
              d="m 78.871125,3.0476896 c -2.764757,0 -5.0139,2.2480042 -5.0139,5.0113612 0,2.7633572 2.249143,5.0113612 5.0139,5.0113612 2.764756,0 5.013899,-2.248004 5.013899,-5.0113612 0,-2.763357 -2.249143,-5.0113612 -5.013899,-5.0113612 z m 0,7.7421384 c -1.506837,0 -2.732161,-1.2247032 -2.732161,-2.7307772 0,-1.506074 1.225324,-2.7307772 2.732161,-2.7307772 1.506836,0 2.73216,1.2247032 2.73216,2.7307772 0,1.506074 -1.225324,2.7307772 -2.73216,2.7307772 z"
              id="Shape"
            />
            <path
              d="m 99.986101,3.0476896 c -2.764757,0 -5.0139,2.2480042 -5.0139,5.0113612 0,2.7633572 2.249143,5.0113612 5.0139,5.0113612 2.764759,0 5.013899,-2.248004 5.013899,-5.0113612 0,-2.763357 -2.24914,-5.0113612 -5.013899,-5.0113612 z m 0,7.7421384 c -1.506837,0 -2.732161,-1.2247032 -2.732161,-2.7307772 0,-1.506074 1.225324,-2.7307772 2.732161,-2.7307772 1.506839,0 2.732159,1.2247032 2.732159,2.7307772 0,1.506074 -1.22532,2.7307772 -2.732159,2.7307772 z"
              id="path8"
            />
            <path
              d="m 13.047399,0.73008307 h -2.440276 c -0.07408,0 -0.140756,0.044427 -0.170389,0.1125483 L 6.6807541,9.6184372 c -0.020743,0.050351 -0.091862,0.050351 -0.114087,0 L 2.8106876,0.84263137 C 2.7810547,0.77451003 2.7143805,0.73008307 2.640298,0.73008307 H 0.18520609 C 0.08297233,0.73008307 0,0.8130134 0,0.91519541 V 12.676493 c 0,0.102182 0.08297233,0.185112 0.18520609,0.185112 H 2.2491428 c 0.1022337,0 0.1852061,-0.08293 0.1852061,-0.185112 V 5.2808848 c 0,-0.068121 0.093344,-0.085892 0.1185319,-0.023694 l 3.1025724,7.4889042 c 0.028151,0.0696 0.096307,0.11403 0.1703896,0.11403 h 1.5957356 c 0.075564,0 0.1422383,-0.04443 0.1718713,-0.11403 L 10.679724,5.2586714 c 0.02519,-0.062198 0.118532,-0.044427 0.118532,0.023694 v 7.3941276 c 0,0.102182 0.08297,0.185112 0.185206,0.185112 h 2.063937 c 0.102233,0 0.185206,-0.08293 0.185206,-0.185112 V 0.91519541 c 0,-0.10218201 -0.08297,-0.18511234 -0.185206,-0.18511234 z"
              id="path10"
            />
            <path
              d="m 94.569193,3.2328019 h -1.909845 c -0.03408,0 -0.06223,-0.028137 -0.06223,-0.062198 V 1.0366291 c 0,-0.10218201 -0.08297,-0.18511234 -0.185206,-0.18511234 h -2.081716 c -0.102234,0 -0.185206,0.0829303 -0.185206,0.18511234 v 2.1339751 c 0,0.034061 -0.02815,0.062198 -0.06223,0.062198 h -0.900843 c -0.102234,0 -0.185206,0.08293 -0.185206,0.1851124 v 1.6289885 c 0,0.1021821 0.08297,0.1851124 0.185206,0.1851124 h 0.900843 c 0.03408,0 0.06223,0.028137 0.06223,0.062198 v 4.5789389 c 0,1.9932896 1.025301,2.9899346 3.077384,2.9899346 h 1.345337 c 0.102234,0 0.185206,-0.08293 0.185206,-0.185112 v -1.898512 c 0,-0.102182 -0.08297,-0.185113 -0.185206,-0.185113 h -1.909845 c -0.03408,0 -0.06223,-0.02814 -0.06223,-0.0622 V 5.2942129 c 0,-0.034061 0.02815,-0.062198 0.06223,-0.062198 h 1.909845 c 0.102234,0 0.185206,-0.08293 0.185206,-0.1851124 V 3.4179143 c 0,-0.1021821 -0.08297,-0.1851124 -0.185206,-0.1851124 z"
              id="path12"
            />
            <path
              d="m 88.812988,10.579541 -1.400158,0.01333 c -0.03408,0 -0.06223,-0.02666 -0.06223,-0.0622 L 87.334302,0.18511234 C 87.334302,0.08293033 87.25133,0 87.149096,0 H 85.08516 C 84.982926,0 84.899953,0.08293033 84.899953,0.18511234 V 9.8716709 c 0,1.9932901 1.025301,2.9899341 3.077385,2.9899341 l 0.841576,-0.01185 c 0.100752,-0.0015 0.182243,-0.08441 0.182243,-0.185112 v -1.898512 c 0,-0.102182 -0.08445,-0.186593 -0.186688,-0.185113 z"
              id="path14"
            />
            <path
              d="M 24.687231,12.660203 V 3.2846334 c 0,-0.102182 -0.08297,-0.1851124 -0.185206,-0.1851124 h -1.911327 c -0.102234,0 -0.185206,0.08293 -0.185206,0.1851124 v 0.7878381 c 0,0.056274 -0.06816,0.084411 -0.105197,0.042946 C 22.005447,3.7999861 21.104604,2.9795682 19.673332,2.9795682 v 0 c 0,0 -0.01185,0 -0.01778,0 v 0 c -2.612147,0 -4.994638,2.1324942 -4.994638,4.9728579 0,2.9040429 2.382491,5.0113609 4.994638,5.0113609 v 0 c 0,0 0.01185,0 0.01778,0 0,0 0,0 0,0 1.431272,0 2.332115,-0.820417 2.626963,-1.135849 0.03852,-0.04146 0.105197,-0.01333 0.105197,0.04295 v 0.787838 c 0,0.102182 0.08297,0.185113 0.185206,0.185113 h 1.911327 c 0.102234,0 0.185206,-0.08293 0.185206,-0.185113 z m -5.013899,-1.938496 c -1.506837,0 -2.732161,-1.1995282 -2.732161,-2.7678 0,-1.506074 1.225324,-2.7307772 2.732161,-2.7307772 1.506836,0 2.73216,1.2617257 2.73216,2.7677997 0,1.4986695 -1.225324,2.7307775 -2.73216,2.7307775 z"
              id="path16"
            />
            <path
              d="m 49.42632,12.845316 h 1.911327 c 0.102233,0 0.185206,-0.08293 0.185206,-0.185113 V 3.2846334 c 0,-0.102182 -0.08297,-0.1851124 -0.185206,-0.1851124 H 49.42632 c -0.102234,0 -0.185206,0.08293 -0.185206,0.1851124 v 0.7878381 c 0,0.056274 -0.06816,0.084411 -0.105197,0.042946 C 48.841068,3.7999861 47.940226,2.9795682 46.508953,2.9795682 v 0 c 0,0 -0.01185,0 -0.01778,0 v 0 c -2.612147,0 -4.994638,2.1324942 -4.994638,4.9728579 0,2.9040429 2.382491,5.0113609 4.994638,5.0113609 v 0 c 0,0 0.01185,0 0.01778,0 0,0 0,0 0,0 1.431273,0 2.332115,-0.820417 2.626964,-1.135849 0.03852,-0.04146 0.105197,-0.01333 0.105197,0.04295 v 0.787838 c 0,0.102182 0.08297,0.185113 0.185206,0.185113 z m -2.917367,-2.123609 c -1.506836,0 -2.73216,-1.1995282 -2.73216,-2.7678 0,-1.506074 1.225324,-2.7307772 2.73216,-2.7307772 1.506837,0 2.732161,1.2617257 2.732161,2.7677997 0,1.4986695 -1.225324,2.7307775 -2.732161,2.7307775 z"
              id="path18"
            />
            <path
              d="m 29.717428,5.2320152 h 1.909846 c 0.102233,0 0.185206,-0.08293 0.185206,-0.1851124 V 3.4179143 c 0,-0.1021821 -0.08297,-0.1851124 -0.185206,-0.1851124 h -1.909846 c -0.03408,0 -0.06223,-0.028137 -0.06223,-0.062198 V 1.0366291 c 0,-0.10218201 -0.08297,-0.18511234 -0.185206,-0.18511234 h -2.081716 c -0.102234,0 -0.185207,0.0829303 -0.185207,0.18511234 v 2.1339751 c 0,0.034061 -0.02815,0.062198 -0.06223,0.062198 h -0.900842 c -0.102234,0 -0.185206,0.08293 -0.185206,0.1851124 v 1.6289885 c 0,0.1021821 0.08297,0.1851124 0.185206,0.1851124 h 0.900842 c 0.03408,0 0.06223,0.028137 0.06223,0.062198 v 4.5789389 c 0,1.9932896 1.025302,2.9899346 3.077385,2.9899346 h 1.345337 c 0.102234,0 0.185206,-0.08293 0.185206,-0.185112 v -1.898512 c 0,-0.102182 -0.08297,-0.185113 -0.185206,-0.185113 h -1.909845 c -0.03408,0 -0.06223,-0.02814 -0.06223,-0.0622 V 5.2942129 c 0,-0.034061 0.02815,-0.062198 0.06223,-0.062198 z"
              id="path20"
            />
          </g>
          <g id="g27" transform="translate(21.6256)">
            <path
              d="M 0.37396766,21.403429 H 11.102586 c 0.219284,0 0.419307,-0.116991 0.53043,-0.305065 3.730792,-6.284934 10.58638,-10.499572 18.430229,-10.499572 7.840885,0 14.699437,4.211676 18.433192,10.499572 0.111123,0.188074 0.311146,0.305065 0.53043,0.305065 h 10.731581 c 0.256326,0 0.438568,-0.256195 0.349669,-0.497582 C 55.593534,8.70028 43.847023,0 30.064726,0 16.28243,0 4.5359189,8.70028 0.02429856,20.907328 c -0.08889893,0.241387 0.09186222,0.497582 0.3496691,0.497582 z"
              id="path23"
            />
            <path
              d="M 59.756967,42.596571 H 49.025385 c -0.219284,0 -0.419306,0.116991 -0.53043,0.305065 -3.730792,6.284934 -10.58638,10.499572 -18.430229,10.499572 -7.843848,0 -14.699437,-4.214638 -18.430228,-10.499572 -0.111124,-0.188074 -0.311146,-0.305065 -0.53043,-0.305065 H 0.37248601 c -0.25632523,0 -0.43856803,0.256195 -0.3496691,0.497582 C 4.5388822,55.29972 16.285393,64 30.063245,64 43.845541,64 55.592052,55.29972 60.103673,43.092672 60.192571,42.851285 60.01181,42.59509 59.754004,42.59509 Z"
              id="path25"
            />
          </g>
        </g>
      </g>
    </Box>
  );
};

export const FooterLogo = props => {
  const [id] = useState(uniqueId('logo-'));

  const fill = 'currentColor';
  return (
    <Box
      as="svg"
      width="130"
      height="110"
      viewBox="0 0 873.65 743.51"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby={id}
      sx={{ display: 'block', color: 'primary' }}
    >
      <g>
        <g>
          <g>
            <path
              fill={fill}
              d="m323.49,237.56h-13.93c-.69,0-1.25.56-1.25,1.25v84.35c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-25.26c0-.39.5-.57.74-.26l20.82,26.14c.32.4.8.63,1.3.63h18.7c.72,0,1.1-.85.62-1.39l-27.27-30.59c-.21-.24-.21-.59,0-.83l27.06-30.83c.47-.54.09-1.38-.63-1.38h-18.71c-.51,0-.99.23-1.31.63l-20.58,26.01c-.25.31-.74.14-.74-.26v-46.96c0-.69-.56-1.25-1.25-1.25Z"
            />
            <path
              fill={fill}
              d="m487.07,324.4h13.93c.69,0,1.25-.56,1.25-1.25v-36.89c0-5.87-1.08-10.89-3.23-15.08-2.15-4.18-5.15-7.35-8.98-9.51-3.83-2.15-8.21-3.23-13.14-3.23-4.3,0-8.21.8-11.74,2.41-3.13,1.43-5.7,3.33-7.72,5.71-.25.3-.73.14-.73-.26v-27.5c0-.69-.56-1.25-1.25-1.25h-13.93c-.69,0-1.25.56-1.25,1.25v84.35c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-34.66c0-5.16,1.31-9.13,3.93-11.91,2.62-2.78,6.2-4.17,10.74-4.17,4.46,0,7.98,1.39,10.56,4.17,2.58,2.78,3.87,6.75,3.87,11.91v34.66c0,.69.56,1.25,1.25,1.25Z"
            />
            <path
              fill={fill}
              d="m557.51,324.4h14.05c.69,0,1.25-.56,1.25-1.25v-62.52c0-.69-.56-1.25-1.25-1.25h-14.05c-.69,0-1.25.56-1.25,1.25v34.54c0,5.16-1.29,9.14-3.87,11.91-2.58,2.78-6.14,4.17-10.68,4.17s-7.98-1.39-10.56-4.17c-2.58-2.78-3.87-6.75-3.87-11.91v-34.54c0-.69-.56-1.25-1.25-1.25h-13.93c-.69,0-1.25.56-1.25,1.25v36.89c0,5.79,1.12,10.78,3.34,14.96,2.23,4.19,5.3,7.35,9.21,9.51,3.91,2.15,8.41,3.23,13.5,3.23,3.99,0,7.69-.8,11.09-2.41,3.02-1.42,5.54-3.33,7.54-5.71.25-.3.73-.14.73.26v5.79c0,.69.56,1.25,1.25,1.25Z"
            />
            <path
              fill={fill}
              d="m614.81,258.14c-18.66,0-33.84,15.18-33.84,33.84,0,18.66,15.18,33.84,33.84,33.84,18.66,0,33.84-15.18,33.84-33.84,0-18.66-15.18-33.84-33.84-33.84Zm0,52.28c-10.17,0-18.44-8.27-18.44-18.44s8.27-18.44,18.44-18.44,18.44,8.27,18.44,18.44c0,10.17-8.27,18.44-18.44,18.44Z"
            />
            <path
              fill={fill}
              d="m757.32,258.14c-18.66,0-33.84,15.18-33.84,33.84,0,18.66,15.18,33.84,33.84,33.84,18.66,0,33.84-15.18,33.84-33.84,0-18.66-15.18-33.84-33.84-33.84Zm0,52.28c-10.17,0-18.44-8.27-18.44-18.44,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.27,18.44,18.44,0,10.17-8.27,18.44-18.44,18.44Z"
            />
            <path
              fill={fill}
              d="m170.55,242.49h-16.47c-.5,0-.95.3-1.15.76l-25.35,59.26c-.14.34-.62.34-.77,0l-25.35-59.26c-.2-.46-.65-.76-1.15-.76h-16.59c-.69,0-1.25.56-1.25,1.25v79.42c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-49.94c0-.46.63-.58.8-.16l20.94,50.57c.19.47.65.77,1.15.77h10.77c.51,0,.96-.3,1.16-.77l20.83-50.56c.17-.42.8-.3.8.16v49.93c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-79.42c0-.69-.56-1.25-1.25-1.25Z"
            />
            <path
              fill={fill}
              d="m720.77,259.38h-12.89c-.23,0-.42-.19-.42-.42v-14.41c0-.69-.56-1.25-1.25-1.25h-14.05c-.69,0-1.25.56-1.25,1.25v14.41c0,.23-.19.42-.42.42h-6.08c-.69,0-1.25.56-1.25,1.25v11c0,.69.56,1.25,1.25,1.25h6.08c.23,0,.42.19.42.42v30.92c0,13.46,6.92,20.19,20.77,20.19h9.08c.69,0,1.25-.56,1.25-1.25v-12.82c0-.69-.56-1.25-1.25-1.25h-12.89c-.23,0-.42-.19-.42-.42v-35.37c0-.23.19-.42.42-.42h12.89c.69,0,1.25-.56,1.25-1.25v-11c0-.69-.56-1.25-1.25-1.25Z"
            />
            <path
              fill={fill}
              d="m681.91,308.99l-9.45.09c-.23,0-.42-.18-.42-.42l-.11-69.86c0-.69-.56-1.25-1.25-1.25h-13.93c-.69,0-1.25.56-1.25,1.25v65.41c0,13.46,6.92,20.19,20.77,20.19l5.68-.08c.68-.01,1.23-.57,1.23-1.25v-12.82c0-.69-.57-1.26-1.26-1.25Z"
            />
            <path
              fill={fill}
              d="m249.11,323.05v-63.31c0-.69-.56-1.25-1.25-1.25h-12.9c-.69,0-1.25.56-1.25,1.25v5.32c0,.38-.46.57-.71.29-1.99-2.13-8.07-7.67-17.73-7.67h0s-.08,0-.12,0h0c-17.63,0-33.71,14.4-33.71,33.58,0,19.61,16.08,33.84,33.71,33.84h0s.08,0,.12,0c0,0,0,0,0,0,9.66,0,15.74-5.54,17.73-7.67.26-.28.71-.09.71.29v5.32c0,.69.56,1.25,1.25,1.25h12.9c.69,0,1.25-.56,1.25-1.25Zm-33.84-13.09c-10.17,0-18.44-8.1-18.44-18.69,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.52,18.44,18.69,0,10.12-8.27,18.44-18.44,18.44Z"
            />
            <path
              fill={fill}
              d="m416.08,324.3h12.9c.69,0,1.25-.56,1.25-1.25v-63.31c0-.69-.56-1.25-1.25-1.25h-12.9c-.69,0-1.25.56-1.25,1.25v5.32c0,.38-.46.57-.71.29-1.99-2.13-8.07-7.67-17.73-7.67h0s-.08,0-.12,0h0c-17.63,0-33.71,14.4-33.71,33.58,0,19.61,16.08,33.84,33.71,33.84h0s.08,0,.12,0c0,0,0,0,0,0,9.66,0,15.74-5.54,17.73-7.67.26-.28.71-.09.71.29v5.32c0,.69.56,1.25,1.25,1.25Zm-19.69-14.34c-10.17,0-18.44-8.1-18.44-18.69,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.52,18.44,18.69,0,10.12-8.27,18.44-18.44,18.44Z"
            />
            <path
              fill={fill}
              d="m283.06,272.88h12.89c.69,0,1.25-.56,1.25-1.25v-11c0-.69-.56-1.25-1.25-1.25h-12.89c-.23,0-.42-.19-.42-.42v-14.41c0-.69-.56-1.25-1.25-1.25h-14.05c-.69,0-1.25.56-1.25,1.25v14.41c0,.23-.19.42-.42.42h-6.08c-.69,0-1.25.56-1.25,1.25v11c0,.69.56,1.25,1.25,1.25h6.08c.23,0,.42.19.42.42v30.92c0,13.46,6.92,20.19,20.77,20.19h9.08c.69,0,1.25-.56,1.25-1.25v-12.82c0-.69-.56-1.25-1.25-1.25h-12.89c-.23,0-.42-.19-.42-.42v-35.37c0-.23.19-.42.42-.42Z"
            />
          </g>
          <g>
            <path
              fill={fill}
              d="m230.97,211.95h72.41c1.48,0,2.83-.79,3.58-2.06,25.18-42.44,71.45-70.9,124.39-70.9,52.92,0,99.21,28.44,124.41,70.9.75,1.27,2.1,2.06,3.58,2.06h72.43c1.73,0,2.96-1.73,2.36-3.36-30.46-82.42-109.74-141.18-202.75-141.18-93.02,0-172.3,58.75-202.75,141.18-.6,1.63.62,3.36,2.36,3.36Z"
            />
            <path
              fill={fill}
              d="m631.76,355.05h-72.43c-1.48,0-2.83.79-3.58,2.06-25.18,42.44-71.45,70.9-124.39,70.9-52.94,0-99.21-28.46-124.39-70.9-.75-1.27-2.1-2.06-3.58-2.06h-72.43c-1.73,0-2.96,1.73-2.36,3.36,30.48,82.42,109.76,141.17,202.75,141.17,93.02,0,172.3-58.75,202.75-141.18.6-1.63-.62-3.36-2.36-3.36Z"
            />
          </g>
        </g>
        <g>
          <path
            fill={fill}
            d="m88.46,620.65c3.75,0,6.56-.84,8.4-2.51,1.85-1.73,2.77-4.16,2.77-7.28,0-6.58-3.73-9.88-11.18-9.88h-10.92v19.67h10.92Zm21.31-9.79c0,3.06-.72,5.95-2.17,8.66-1.44,2.71-3.75,4.94-6.93,6.67-3.18,1.67-7.25,2.51-12.22,2.51h-10.92v24.43h-9.88v-60.21h20.79c4.62,0,8.52.81,11.7,2.43,3.23,1.56,5.63,3.7,7.19,6.41,1.62,2.71,2.43,5.75,2.43,9.1Z"
          />
          <path
            fill={fill}
            d="m154.98,629.23c0-3.29-.69-6.15-2.08-8.58-1.33-2.43-3.09-4.27-5.28-5.54-2.19-1.27-4.56-1.91-7.1-1.91s-4.91.64-7.1,1.91c-2.19,1.21-3.99,3.03-5.37,5.46-1.33,2.37-1.99,5.2-1.99,8.49s.66,6.18,1.99,8.66c1.39,2.48,3.18,4.39,5.37,5.72,2.25,1.27,4.62,1.91,7.1,1.91s4.91-.64,7.1-1.91,3.96-3.12,5.28-5.54c1.39-2.48,2.08-5.37,2.08-8.66Zm-39.07-.17c0-4.79.98-9.04,2.95-12.74,2.02-3.7,4.74-6.56,8.14-8.58,3.47-2.08,7.28-3.12,11.44-3.12,3.75,0,7.02.75,9.79,2.25,2.83,1.44,5.08,3.26,6.76,5.46v-6.93h9.96v47.74h-9.96v-7.1c-1.67,2.25-3.96,4.13-6.84,5.63s-6.18,2.25-9.88,2.25c-4.1,0-7.85-1.04-11.26-3.12-3.41-2.14-6.12-5.08-8.14-8.84-1.96-3.81-2.95-8.11-2.95-12.91Z"
          />
          <path
            fill={fill}
            d="m187.79,612.33c1.44-2.43,3.35-4.3,5.72-5.63,2.43-1.39,5.28-2.08,8.58-2.08v10.22h-2.51c-3.87,0-6.82.98-8.84,2.95-1.96,1.96-2.95,5.37-2.95,10.22v25.12h-9.88v-47.74h9.88v6.93Z"
          />
          <path
            fill={fill}
            d="m244.6,624.98c-.06-3.64-1.36-6.56-3.9-8.75-2.54-2.19-5.69-3.29-9.44-3.29-3.41,0-6.32,1.1-8.75,3.29-2.43,2.14-3.87,5.05-4.33,8.75h26.42Zm10.31,3.12c0,1.79-.12,3.41-.35,4.85h-36.47c.29,3.81,1.7,6.87,4.25,9.18s5.66,3.47,9.36,3.47c5.31,0,9.07-2.22,11.26-6.67h10.66c-1.44,4.39-4.07,8-7.88,10.83-3.75,2.77-8.43,4.16-14.03,4.16-4.56,0-8.66-1.01-12.3-3.03-3.58-2.08-6.41-4.97-8.49-8.66-2.02-3.75-3.03-8.09-3.03-13s.98-9.21,2.95-12.91c2.02-3.75,4.82-6.64,8.4-8.66,3.64-2.02,7.8-3.03,12.48-3.03s8.52.98,12.04,2.95c3.52,1.96,6.27,4.74,8.23,8.32,1.96,3.52,2.95,7.59,2.95,12.22Z"
          />
          <path
            fill={fill}
            d="m322.41,604.62c3.75,0,7.1.78,10.05,2.34,3,1.56,5.34,3.87,7.02,6.93,1.73,3.06,2.6,6.76,2.6,11.09v28.16h-9.79v-26.68c0-4.27-1.07-7.54-3.21-9.79-2.14-2.31-5.05-3.47-8.75-3.47s-6.64,1.16-8.84,3.47c-2.14,2.25-3.21,5.52-3.21,9.79v26.68h-9.79v-26.68c0-4.27-1.07-7.54-3.21-9.79-2.14-2.31-5.05-3.47-8.75-3.47s-6.64,1.16-8.84,3.47c-2.14,2.25-3.21,5.52-3.21,9.79v26.68h-9.88v-47.74h9.88v5.46c1.62-1.96,3.67-3.49,6.15-4.59s5.14-1.65,7.97-1.65c3.81,0,7.22.81,10.22,2.43s5.31,3.96,6.93,7.02c1.44-2.89,3.7-5.17,6.76-6.84,3.06-1.73,6.35-2.6,9.88-2.6Z"
          />
          <path
            fill={fill}
            d="m412.43,604.62c3.75,0,7.1.78,10.05,2.34,3,1.56,5.34,3.87,7.02,6.93,1.73,3.06,2.6,6.76,2.6,11.09v28.16h-9.79v-26.68c0-4.27-1.07-7.54-3.21-9.79-2.14-2.31-5.05-3.47-8.75-3.47s-6.64,1.16-8.84,3.47c-2.14,2.25-3.21,5.52-3.21,9.79v26.68h-9.79v-26.68c0-4.27-1.07-7.54-3.21-9.79-2.14-2.31-5.05-3.47-8.75-3.47s-6.64,1.16-8.84,3.47c-2.14,2.25-3.21,5.52-3.21,9.79v26.68h-9.88v-47.74h9.88v5.46c1.62-1.96,3.67-3.49,6.15-4.59s5.14-1.65,7.97-1.65c3.81,0,7.22.81,10.22,2.43s5.31,3.96,6.93,7.02c1.44-2.89,3.7-5.17,6.76-6.84,3.06-1.73,6.35-2.6,9.88-2.6Z"
          />
          <path
            fill={fill}
            d="m454.54,605.4v47.74h-9.88v-47.74h9.88Zm-4.85-6.32c-1.79,0-3.29-.61-4.51-1.82s-1.82-2.71-1.82-4.5.61-3.29,1.82-4.5,2.71-1.82,4.51-1.82,3.21.61,4.42,1.82,1.82,2.71,1.82,4.5-.61,3.29-1.82,4.5-2.69,1.82-4.42,1.82Z"
          />
          <path
            fill={fill}
            d="m491.59,604.62c3.75,0,7.1.78,10.05,2.34,3,1.56,5.34,3.87,7.02,6.93,1.67,3.06,2.51,6.76,2.51,11.09v28.16h-9.79v-26.68c0-4.27-1.07-7.54-3.21-9.79-2.14-2.31-5.05-3.47-8.75-3.47s-6.64,1.16-8.84,3.47c-2.14,2.25-3.21,5.52-3.21,9.79v26.68h-9.88v-47.74h9.88v5.46c1.62-1.96,3.67-3.49,6.15-4.59,2.54-1.1,5.23-1.65,8.06-1.65Z"
          />
          <path
            fill={fill}
            d="m585.17,629.05c0-3.29-.69-6.12-2.08-8.49-1.33-2.43-3.12-4.25-5.37-5.46-2.19-1.27-4.56-1.91-7.1-1.91s-4.85.64-7.1,1.91c-2.19,1.27-3.99,3.12-5.37,5.54-1.33,2.43-1.99,5.28-1.99,8.58s.66,6.18,1.99,8.66c1.39,2.43,3.18,4.27,5.37,5.54,2.25,1.27,4.62,1.91,7.1,1.91s4.91-.64,7.1-1.91c2.25-1.33,4.04-3.23,5.37-5.72,1.39-2.48,2.08-5.37,2.08-8.66Zm-29.02-16.63c1.67-2.19,3.96-4.04,6.84-5.54,2.89-1.5,6.15-2.25,9.79-2.25,4.16,0,7.94,1.04,11.35,3.12,3.47,2.02,6.18,4.88,8.14,8.58,1.96,3.7,2.95,7.94,2.95,12.74s-.98,9.1-2.95,12.91c-1.96,3.75-4.68,6.7-8.14,8.84-3.41,2.08-7.19,3.12-11.35,3.12-3.64,0-6.87-.72-9.7-2.17-2.83-1.5-5.14-3.35-6.93-5.54v29.63h-9.88v-70.43h9.88v7.02Z"
          />
          <path
            fill={fill}
            d="m638.43,624.98c-.06-3.64-1.36-6.56-3.9-8.75-2.54-2.19-5.69-3.29-9.44-3.29-3.41,0-6.32,1.1-8.75,3.29-2.43,2.14-3.87,5.05-4.33,8.75h26.42Zm10.31,3.12c0,1.79-.12,3.41-.35,4.85h-36.47c.29,3.81,1.7,6.87,4.25,9.18s5.66,3.47,9.36,3.47c5.31,0,9.07-2.22,11.26-6.67h10.66c-1.44,4.39-4.07,8-7.88,10.83-3.75,2.77-8.43,4.16-14.03,4.16-4.56,0-8.66-1.01-12.3-3.03-3.58-2.08-6.41-4.97-8.49-8.66-2.02-3.75-3.03-8.09-3.03-13s.98-9.21,2.95-12.91c2.02-3.75,4.82-6.64,8.4-8.66,3.64-2.02,7.8-3.03,12.48-3.03s8.52.98,12.04,2.95c3.52,1.96,6.27,4.74,8.23,8.32,1.96,3.52,2.95,7.59,2.95,12.22Z"
          />
          <path
            fill={fill}
            d="m668.33,612.33c1.44-2.43,3.35-4.3,5.72-5.63,2.43-1.39,5.28-2.08,8.58-2.08v10.22h-2.51c-3.87,0-6.82.98-8.84,2.95-1.96,1.96-2.95,5.37-2.95,10.22v25.12h-9.88v-47.74h9.88v6.93Z"
          />
          <path
            fill={fill}
            d="m701.58,605.4v47.74h-9.88v-47.74h9.88Zm-4.85-6.32c-1.79,0-3.29-.61-4.51-1.82s-1.82-2.71-1.82-4.5.61-3.29,1.82-4.5,2.71-1.82,4.51-1.82,3.21.61,4.42,1.82,1.82,2.71,1.82,4.5-.61,3.29-1.82,4.5-2.69,1.82-4.42,1.82Z"
          />
          <rect fill={fill} x="714.55" y="589.03" width="9.88" height="64.11" />
          <rect fill={fill} x="737.39" y="589.03" width="9.88" height="64.11" />
          <path
            fill={fill}
            d="m790.56,599.42c-1.44,0-2.69-.49-3.73-1.47-.98-1.04-1.47-2.28-1.47-3.73s.49-2.66,1.47-3.64c1.04-1.04,2.28-1.56,3.73-1.56s2.66.52,3.64,1.56c1.04.98,1.56,2.19,1.56,3.64s-.52,2.69-1.56,3.73c-.98.98-2.19,1.47-3.64,1.47Zm-14.9,0c-1.44,0-2.69-.49-3.73-1.47-.98-1.04-1.47-2.28-1.47-3.73s.49-2.66,1.47-3.64c1.04-1.04,2.28-1.56,3.73-1.56s2.66.52,3.64,1.56c1.04.98,1.56,2.19,1.56,3.64s-.52,2.69-1.56,3.73c-.98.98-2.19,1.47-3.64,1.47Zm20.36,29.8c0-3.29-.69-6.15-2.08-8.58-1.33-2.43-3.09-4.27-5.28-5.54-2.19-1.27-4.56-1.91-7.1-1.91s-4.91.64-7.1,1.91c-2.19,1.21-3.99,3.03-5.37,5.46-1.33,2.37-1.99,5.2-1.99,8.49s.66,6.18,1.99,8.66c1.39,2.48,3.18,4.39,5.37,5.72,2.25,1.27,4.62,1.91,7.1,1.91s4.91-.64,7.1-1.91,3.96-3.12,5.28-5.54c1.39-2.48,2.08-5.37,2.08-8.66Zm-39.07-.17c0-4.79.98-9.04,2.95-12.74,2.02-3.7,4.74-6.56,8.14-8.58,3.47-2.08,7.28-3.12,11.44-3.12,3.75,0,7.02.75,9.79,2.25,2.83,1.44,5.08,3.26,6.76,5.46v-6.93h9.96v47.74h-9.96v-7.1c-1.67,2.25-3.96,4.13-6.84,5.63-2.89,1.5-6.18,2.25-9.88,2.25-4.1,0-7.85-1.04-11.26-3.12-3.41-2.14-6.12-5.08-8.14-8.84-1.96-3.81-2.95-8.11-2.95-12.91Z"
          />
        </g>
      </g>
    </Box>
  );
};
