/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import {
  goBack,
  goNext,
  toggleService,
  reverseOrderForRecipient,
  setUnattended,
  useCurrentItemHasFreePickup,
  useRowId,
} from '../state/parcelFlow';
import ServiceCard from './ServiceCard';
import { MULTIPARCEL_QUANTITY, ServiceCode, xxsDeliveryEnabled } from '../state/catalog';
import { setPickup, useItemsWithPickup, usePickupRejected, usePickupSelected } from '../state/basket';

const allProductsForCountrySelector = state => {
  const {
    parcelFlow: { country },
    catalog: { products: productsForFinland, country: productsForCountry },
  } = state;
  return country === 'FI' ? productsForFinland : productsForCountry[country];
};

const UnattendedQuestion = () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const { unattendedHandover } = useSelector(state => state.parcelFlow.recipient) || {};
  const selectYes = useCallback(e => dispatch(setUnattended('yes')), [dispatch]);
  const selectNo = useCallback(e => dispatch(setUnattended('no')), [dispatch]);
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(unattendedHandover === 'yes' ? 0 : 1);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('input'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = 1 - i;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.stopPropagation();
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            setImmediate(() => {
              if (i === 0) {
                selectYes();
              } else {
                selectNo();
              }
            });
            return i;
          });
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [setFocusIndex, selectNo, selectYes]
  );

  return (
    <div
      ref={container}
      role="radiogroup"
      aria-labelledby="unattended-label"
      sx={{ mt: 3, mb: 2 }}
      onClick={e => e.stopPropagation()}
      onKeyDown={onKeyDown}
    >
      <div sx={{ mb: 1 }}>
        <label id="unattended-label">{translate('buyParcel.recipient.unattended')}</label>
      </div>
      <div>
        <input sx={{ mr: 2 }} type="radio" checked={unattendedHandover === 'yes'} onChange={selectYes} />
        {translate('buyParcel.recipient.unattendedYes')}
      </div>
      <div>
        <input sx={{ mr: 2 }} type="radio" checked={unattendedHandover === 'no'} onChange={selectNo} />
        {translate('buyParcel.recipient.unattendedNo')}
      </div>
    </div>
  );
};

const addWeightProps = service => ({
  ...service,
  minWeight: service.fromWeightClass,
  maxWeight: service.toWeightClass || service.override?.weightClass,
});

export const ariaLabelForService = (service, translate) => {
  const translate2 = (id, fallback, params) => translate(id, params || {}, { onMissingTranslation: () => fallback });
  const title = translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName);
  const description = translate2(
    `buyParcel.services.${service.serviceCode}.description`,
    service.description,
    addWeightProps(service)
  );
  return `${title}, ${service.price} €, ${description}`;
};

const ServiceList = ({ services }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const jysInfo = useSelector(state => state.parcelFlow.jys) || {};
  const recipientAlreadyAsked = useSelector(state =>
    reverseOrderForRecipient(state.parcelFlow.country, state.parcelFlow.product.sizeCode)
  );
  const product = useSelector(state => state.parcelFlow.product);
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.service'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : services.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < services.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const service = services[i];
            if (service) {
              setImmediate(() => {
                service.onClick();
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [services, setFocusIndex]
  );

  return (
    <Box
      ref={container}
      sx={{ maxWidth: 640 }}
      role="group"
      aria-label={translate('buyParcel.services.title')}
      onKeyDown={onKeyDown}
    >
      {services.length === 0 ? translate('buyParcel.services.noServices') : null}
      {services.map((s, index) => {
        const isNormalDelivery = s.serviceCode === ServiceCode.Delivery8_16;
        const disabled = s.disabled || false; // isMailBoxDelivery ? !jysInfo.supportedByCarrier : false;
        return (
          <Box
            sx={{ display: 'relative', width: '100%', my: 2 }}
            key={s.serviceCode}
            onClick={disabled ? null : s.onClick}
            className="service"
            role="checkbox"
            aria-label={ariaLabelForService(s, translate)}
            aria-checked={s.isSelected}
            tabIndex={focusIndex === index ? 0 : -1}
          >
            <ServiceCard
              service={s}
              isSelected={s.isSelected}
              disabled={disabled}
              extraContent={isNormalDelivery && recipientAlreadyAsked && <UnattendedQuestion />}
              onClick={s.onClick}
              product={product}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const sortServices = (a, b) => {
  const order = [
    ServiceCode.Heavy5kg,
    ServiceCode.HeavyWithDelivery,
    ServiceCode.DeliveryToMailBox,
    ServiceCode.DeliveryAgreedTime,
    ServiceCode.Delivery8_16,
    ServiceCode.PickUp,
    ServiceCode.HandleWithCare,
  ];
  const aIndex = order.indexOf(a.serviceCode);
  const bIndex = order.indexOf(b.serviceCode);
  if (aIndex === -1) {
    return bIndex === -1 ? 0 : +1;
  } else if (bIndex === -1) {
    return -1;
  } else {
    return aIndex - bIndex;
  }
};

const servicesForProduct = ({
  selectedProduct,
  allProducts,
  selectedServices,
  buySeries,
  dispatch,
  pickupSelected,
  pickupIsFree,
  pickupIsSelectedAndDisabled,
  onSelectPickup,
}) => {
  // side effect: modifies selectedServices array if extra weight

  if (!selectedProduct) {
    return [];
  }

  const enabledServices = buySeries
    ? [
        // ServiceCode.Delivery8_16,
        // ServiceCode.DeliveryAgreedTime,
        ServiceCode.Heavy5kg, // v2 api (mobile)
        // ServiceCode.HeavyWithDelivery, // v3 api
      ]
    : [
        ServiceCode.Delivery8_16,
        ServiceCode.DeliveryAgreedTime,
        ServiceCode.HandleWithCare,
        ServiceCode.Heavy5kg, // v2 api (mobile)
        ServiceCode.HeavyWithDelivery, // v3 api
        xxsDeliveryEnabled ? ServiceCode.DeliveryToMailBox : '!',
        ServiceCode.PickUp,
      ];

  const services = (selectedProduct.services || [])
    .filter(s => enabledServices.includes(s.serviceCode))
    .map(s => {
      if (s.serviceCode === ServiceCode.PickUp) {
        const service = {
          ...s,
          isSelected: !!pickupSelected,
          onClick: () => onSelectPickup(pickupSelected ? null : s),
        };
        if (pickupIsFree) {
          service.price = 0.0;
        }
        if (pickupIsSelectedAndDisabled) {
          service.isSelected = true;
          service.disabled = true;
        }
        return service;
      }
      return {
        ...s,
        isSelected: !!selectedServices.find(ss => ss.serviceCode === s.serviceCode),
        onClick: () => dispatch(toggleService(s)),
      };
    })
    .sort(sortServices);

  if (buySeries) {
    services.forEach(s => {
      const multiPricing = s.multiPrice?.[MULTIPARCEL_QUANTITY];
      if (multiPricing) {
        s.price = multiPricing.price;
        s.priceVat0 = multiPricing.priceVat0;
      } else {
        s.price = MULTIPARCEL_QUANTITY * s.price;
        s.priceVat0 = MULTIPARCEL_QUANTITY * s.priceVat0;
      }
    });
  }

  return services;
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onNextClick = useCallback(() => dispatch(goNext()), [dispatch]);
  const [canModifyPickup, setCanModifyPickup] = useState(false);
  const selectedServices = [...(useSelector(state => state.parcelFlow.services) || [])];
  const itemsWithPickup = useItemsWithPickup();
  const rowId = useRowId();
  const pickupSelected = usePickupSelected();
  const pickupRejected = usePickupRejected();
  const pickupAlreadyInOtherProduct =
    itemsWithPickup.length > 1 || (itemsWithPickup.length === 1 && itemsWithPickup[0] !== rowId);
  const currentItemHasFreePickup = useCurrentItemHasFreePickup();
  const pickupIsFree = currentItemHasFreePickup || (pickupAlreadyInOtherProduct && !canModifyPickup);
  const pickupIsSelectedAndDisabled =
    (pickupAlreadyInOtherProduct && !pickupRejected && !canModifyPickup) || currentItemHasFreePickup;

  const onSelectPickup = useCallback(
    service => {
      setCanModifyPickup(true);
      dispatch(setPickup(service));
    },
    [dispatch]
  );

  const recipientAlreadyAsked = useSelector(state =>
    reverseOrderForRecipient(state.parcelFlow.country, state.parcelFlow.product.sizeCode)
  );
  const buySeries = useSelector(state => state.parcelFlow.buySeries) || false;
  const selectedProduct = useSelector(state => state.parcelFlow.product) || {};
  const allProducts = useSelector(allProductsForCountrySelector);
  const extraServices = servicesForProduct({
    selectedProduct,
    allProducts,
    selectedServices,
    buySeries,
    dispatch,
    pickupSelected: (itemsWithPickup.length > 0 && !pickupRejected) || !!pickupSelected,
    pickupAlreadyInOtherProduct,
    pickupIsFree,
    pickupIsSelectedAndDisabled,
    onSelectPickup,
  });
  const pickupButtonIsSelected = !!extraServices.find(sv => sv.serviceCode === ServiceCode.PickUp && sv.isSelected);

  const unattendedValue = useSelector(state => state.parcelFlow.recipient.unattendedHandover);
  const unattendedNotAnswered =
    recipientAlreadyAsked &&
    selectedServices.some(s => s.serviceCode === 'JA') &&
    !['yes', 'no'].includes(unattendedValue);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('buyParcel.services.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: ['space-between'],
        }}
      >
        <ServiceList services={extraServices} />
        <Button
          sx={{
            alignSelf: ['flex-end', null, 'flex-start'],
            mt: 3,
          }}
          onClick={onNextClick}
          disabled={unattendedNotAnswered}
        >
          {selectedServices.length === 0 && !pickupButtonIsSelected
            ? translate('buyParcel.services.noButton')
            : translate('buyParcel.continue')}
        </Button>
      </Flex>
    </FullHeightColumn>
  );
};
