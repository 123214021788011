import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import * as api from '../utils/api';
import { setBaskedId, removeBaskedId } from '../utils/basket';
import { itemHasHomeDelivery, askPickupPointForCountry, ParcelType } from './parcelFlow';
import { showNotification } from './notifications';
import getLanguage from '../utils/getLanguage';

export const BasketState = {
  OPEN: 'open',
  CLOSED: 'closed',
  PAID: 'paid',
};

const initialState = {
  basketId: null,
  items: [],
  pickup: null,
  noPickup: true, // if pickup is included in atleast one item in basket, it's on by default
  departurePoint: null,
  sender: {},
};

const pickupSelector = state => state.basket.pickup;
export const usePickupSelected = () => useSelector(pickupSelector);

const pickupRejectedSelector = state => state.basket.noPickup;
export const usePickupRejected = () => useSelector(pickupRejectedSelector);

const itemsWithPickupSelector = state => {
  const selectedPickup = state.basket.pickup;
  const itemsWithPickup = [];
  state.basket.items.forEach(item => {
    if (item.buySeries) return;
    const pickupIncluded = item.product.pickupIncluded || (item.services || []).some(s => s.override?.pickupIncluded);
    if (selectedPickup || pickupIncluded) {
      itemsWithPickup.push(item.rowId);
      return;
    }
  });
  return itemsWithPickup;
};
export const useItemsWithPickup = () => useSelector(itemsWithPickupSelector);

const onlyExpressSelector = state =>
  (state.basket.items || []).length > 0 &&
  (state.basket.items || []).every(item => item.parcelType === ParcelType.Express);
export const useCartHasOnlyExpressParcels = () => {
  return useSelector(onlyExpressSelector);
};

const CREATE_BASKET = 'CREATE_BASKET';
const SET_BASKET = 'SET_BASKET';
const RESET_BASKET = 'RESET_BASKET';
const PUT_ITEM = 'PUT_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
const SELECT_DEPARTUREPOINT = 'SELECT_DEPARTUREPOINT';
const SAVE_SENDER = 'SAVE_SENDER';
const ADD_PICKUP = 'ADD_PICKUP';
const REMOVE_PICKUP = 'REMOVE_PICKUP';

export const resetBasket = () => ({
  type: RESET_BASKET,
});

export const loadBasket = basketId => async (dispatch, getState) => {
  try {
    const basket = await api.loadBasket(basketId);
    if (!basket) {
      removeBaskedId();
      return dispatch(resetBasket());
    }

    dispatch({
      ...basket,
      type: SET_BASKET,
    });
  } catch (error) {
    console.warn('Failed to load shopping basket:', error);
  }
};

export const putItem = (item, retry = false) => async (dispatch, getState) => {
  let basketId = getState().basket.basketId;
  const { noPickup = true, pickup = null } = getState().basket;
  if (!basketId) {
    basketId = uuid();
    dispatch({
      type: CREATE_BASKET,
      basketId,
      noPickup,
      pickup,
    });
  }
  setBaskedId(basketId);

  if (!item.rowId) {
    item = cloneDeep(item);
    item.rowId = uuid();
  }

  if (itemHasHomeDelivery(item) || !askPickupPointForCountry(item.country)) {
    item.pickupPoint = {};
  }

  if (item.parcelType === ParcelType.Express) {
    item.services = (item.services || []).filter(sv => sv.serviceCode !== 'EXP');
    item.product = {
      ...item.product,
      displayName: item.product.baseName || item.product.displayName,
    };
    const expService = item.product.services?.find(sv => sv.serviceCode === 'EXP');
    if (expService) {
      item.services.unshift(expService);
    }
  }

  const basketBeforeUpdate = getState().basket;
  dispatch({
    type: PUT_ITEM,
    item,
  });

  const isExpress = item.parcelType === ParcelType.Express;
  basketBeforeUpdate.items.forEach(it => {
    if (isExpress ? it.parcelType !== ParcelType.Express : it.parcelType === ParcelType.Express) {
      dispatch({
        type: REMOVE_ITEM,
        item: it,
      });
    }
  });

  const basketAfterUpdate = getState().basket;

  try {
    const basket = await api.putBasket(basketAfterUpdate);
    dispatch({
      type: SET_BASKET,
      ...basket,
    });
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // basket state is paid or closed, update is forbidden
      // create new basket
      if (!retry) {
        removeBaskedId();
        await dispatch(resetBasket());
        await dispatch(putItem(item, true));
        return;
      }
    }
    console.warn('Failed to update shopping basket:', error);
    dispatch(showNotification('updateFailed'));
    dispatch({
      type: SET_BASKET,
      ...basketBeforeUpdate,
    });
    throw error;
  }
};

export const removeItem = item => async (dispatch, getState) => {
  const basketBeforeUpdate = getState().basket;
  dispatch({
    type: REMOVE_ITEM,
    item,
  });
  let basketAfterUpdate = getState().basket;
  if (basketAfterUpdate.items.length === 0) {
    dispatch({
      type: REMOVE_PICKUP,
    });
  }

  basketAfterUpdate = getState().basket;

  try {
    const basket = await api.putBasket(basketAfterUpdate);
    dispatch({
      type: SET_BASKET,
      ...basket,
    });
  } catch (error) {
    console.warn('Failed to remove shopping basket item:', error);
    dispatch(showNotification('updateFailed'));
    dispatch({
      type: SET_BASKET,
      ...basketBeforeUpdate,
    });
    throw error;
  }
};

export const applyDiscount = code => async (dispatch, getState) => {
  const { basketId } = getState().basket;
  const basket = await api.applyDiscount(basketId, code);
  if (typeof basket === 'string') {
    return basket; // error code
  } else {
    dispatch({
      type: SET_BASKET,
      ...basket,
    });
  }
};

export const removeDiscount = () => async (dispatch, getState) => {
  const basketBeforeUpdate = getState().basket;
  dispatch({
    type: REMOVE_DISCOUNT,
  });
  const basketAfterUpdate = getState().basket;

  try {
    await api.putBasket(basketAfterUpdate);
  } catch (error) {
    console.warn('Failed to remove shopping basket discount:', error);
    dispatch(showNotification('updateFailed'));
    dispatch({
      type: SET_BASKET,
      ...basketBeforeUpdate,
    });
    throw error;
  }
};

export const setPickup = pickup => async (dispatch, getState) => {
  const basketBeforeUpdate = getState().basket;
  if (!pickup) {
    dispatch({
      type: REMOVE_PICKUP,
    });
  } else {
    dispatch({
      type: ADD_PICKUP,
      pickup,
    });
  }
  const basketAfterUpdate = getState().basket;

  try {
    const basket = await api.putBasket(basketAfterUpdate);
    dispatch({
      type: SET_BASKET,
      ...basket,
    });
  } catch (error) {
    console.warn('Failed to update shopping basket:', error);
    dispatch(showNotification('updateFailed'));
    dispatch({
      type: SET_BASKET,
      ...basketBeforeUpdate,
    });
    throw error;
  }
};

export const selectDeparturePoint = departurePoint => ({
  departurePoint,
  type: SELECT_DEPARTUREPOINT,
});

export const saveSender = sender => async (dispatch, getState) => {
  dispatch({
    type: SAVE_SENDER,
    sender,
  });

  const basketAfterUpdate = getState().basket;
  await api.putBasket(basketAfterUpdate);
};

export const createOrder = paymentOptions => async (dispatch, getState) => {
  const basket = getState().basket || {};
  if ((basket.items || []).length === 0) {
    return;
  }
  const discountPercent = (getState().session.user || {}).discountPercent;
  try {
    const language = getLanguage(getState());
    const response = await api.createOrder(basket, paymentOptions, language, discountPercent);
    // const { redirectUrl, orderId } = response;
    return response;
  } catch (error) {
    console.warn('Failed to create order:', error);
    dispatch(showNotification('updateFailed'));
  }
};

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case RESET_BASKET:
      return initialState;
    case SET_BASKET:
      return params;
    case CREATE_BASKET:
      return { ...initialState, ...params, status: BasketState.OPEN };
    case PUT_ITEM:
      let found = false;
      const items = (state.items || []).map(i => {
        if (i.rowId === params.item.rowId) {
          found = true;
          return params.item;
        } else {
          return i;
        }
      });
      if (!found) {
        items.push(params.item);
      }
      return { ...state, items };
    case REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(i => i.rowId !== params.item.rowId),
      };
    case REMOVE_DISCOUNT:
      return { ...state, discount: undefined };
    case SELECT_DEPARTUREPOINT:
      return { ...state, ...params };
    case SAVE_SENDER:
      return { ...state, ...params };
    case ADD_PICKUP:
      return { ...state, ...params, noPickup: false };
    case REMOVE_PICKUP:
      return { ...state, pickup: null, noPickup: true };
    default:
      return state;
  }
};
