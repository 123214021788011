import React, { useEffect, useState } from 'react';
export const isBrowser = typeof window !== 'undefined';

export const isAppleLoginEnabled = true; // (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development') !== 'production';

export const MAX_EMAIL_FILE_SIZE = 10 * 1024 * 1024;

export const yupMaxEmailFileSizeText = value => {
  if (value && value.length > 0) {
    const size = value.reduce((acc, val) => (val instanceof File ? val.size + acc : acc), 0);
    return MAX_EMAIL_FILE_SIZE >= size;
  }
  return true;
};

export const BrowserWrapper = ({ children }) => {
  // first render in browser tries to match elements rendered server side
  // it doesn't always work very well
  const [firstRender, setFirst] = useState(true);
  useEffect(() => setFirst(false), []);
  if (!isBrowser || firstRender) return null;
  return <React.Fragment>{children}</React.Fragment>;
};
