// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-gatsby-theme-style-guide-template-js": () => import("./../../../src/gatsby-theme-style-guide/template.js" /* webpackChunkName: "component---src-gatsby-theme-style-guide-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buy-multi-parcel-js": () => import("./../../../src/pages/buy-multi-parcel.js" /* webpackChunkName: "component---src-pages-buy-multi-parcel-js" */),
  "component---src-pages-buy-parcel-js": () => import("./../../../src/pages/buy-parcel.js" /* webpackChunkName: "component---src-pages-buy-parcel-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-claim-damages-js": () => import("./../../../src/pages/claim-damages.js" /* webpackChunkName: "component---src-pages-claim-damages-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-cookie-settings-js": () => import("./../../../src/pages/cookie-settings.js" /* webpackChunkName: "component---src-pages-cookie-settings-js" */),
  "component---src-pages-customs-js": () => import("./../../../src/pages/customs.js" /* webpackChunkName: "component---src-pages-customs-js" */),
  "component---src-pages-delete-profile-js": () => import("./../../../src/pages/delete-profile.js" /* webpackChunkName: "component---src-pages-delete-profile-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-delivery-to-service-point-js": () => import("./../../../src/pages/delivery-to-service-point.js" /* webpackChunkName: "component---src-pages-delivery-to-service-point-js" */),
  "component---src-pages-departure-place-js": () => import("./../../../src/pages/departure-place.js" /* webpackChunkName: "component---src-pages-departure-place-js" */),
  "component---src-pages-e-invoicing-js": () => import("./../../../src/pages/e-invoicing.js" /* webpackChunkName: "component---src-pages-e-invoicing-js" */),
  "component---src-pages-ebus-ticket-cancellation-form-js": () => import("./../../../src/pages/ebus-ticket-cancellation-form.js" /* webpackChunkName: "component---src-pages-ebus-ticket-cancellation-form-js" */),
  "component---src-pages-email-validate-js": () => import("./../../../src/pages/email-validate.js" /* webpackChunkName: "component---src-pages-email-validate-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-form-disturbance-online-store-js": () => import("./../../../src/pages/form-disturbance-online-store.js" /* webpackChunkName: "component---src-pages-form-disturbance-online-store-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-application-js": () => import("./../../../src/pages/job-application.js" /* webpackChunkName: "component---src-pages-job-application-js" */),
  "component---src-pages-live-tracking-js": () => import("./../../../src/pages/live-tracking.js" /* webpackChunkName: "component---src-pages-live-tracking-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-multi-parcel-js": () => import("./../../../src/pages/multi-parcel.js" /* webpackChunkName: "component---src-pages-multi-parcel-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-pages-favorite-trips-js": () => import("./../../../src/pages/my-pages/favorite-trips.js" /* webpackChunkName: "component---src-pages-my-pages-favorite-trips-js" */),
  "component---src-pages-my-pages-incoming-parcels-js": () => import("./../../../src/pages/my-pages/incoming-parcels.js" /* webpackChunkName: "component---src-pages-my-pages-incoming-parcels-js" */),
  "component---src-pages-my-pages-multi-parcels-js": () => import("./../../../src/pages/my-pages/multi-parcels.js" /* webpackChunkName: "component---src-pages-my-pages-multi-parcels-js" */),
  "component---src-pages-my-pages-my-tickets-js": () => import("./../../../src/pages/my-pages/my-tickets.js" /* webpackChunkName: "component---src-pages-my-pages-my-tickets-js" */),
  "component---src-pages-my-pages-packing-instructions-js": () => import("./../../../src/pages/my-pages/packing-instructions.js" /* webpackChunkName: "component---src-pages-my-pages-packing-instructions-js" */),
  "component---src-pages-my-pages-parcel-archive-js": () => import("./../../../src/pages/my-pages/parcel-archive.js" /* webpackChunkName: "component---src-pages-my-pages-parcel-archive-js" */),
  "component---src-pages-my-pages-sent-parcels-js": () => import("./../../../src/pages/my-pages/sent-parcels.js" /* webpackChunkName: "component---src-pages-my-pages-sent-parcels-js" */),
  "component---src-pages-my-pages-travel-cards-js": () => import("./../../../src/pages/my-pages/travel-cards.js" /* webpackChunkName: "component---src-pages-my-pages-travel-cards-js" */),
  "component---src-pages-new-delivery-js": () => import("./../../../src/pages/new-delivery.js" /* webpackChunkName: "component---src-pages-new-delivery-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-parcel-cancellation-form-js": () => import("./../../../src/pages/parcel-cancellation-form.js" /* webpackChunkName: "component---src-pages-parcel-cancellation-form-js" */),
  "component---src-pages-passengers-ticket-order-form-js": () => import("./../../../src/pages/passengers/ticket-order-form.js" /* webpackChunkName: "component---src-pages-passengers-ticket-order-form-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payment-method-js": () => import("./../../../src/pages/payment-method.js" /* webpackChunkName: "component---src-pages-payment-method-js" */),
  "component---src-pages-pickup-order-js": () => import("./../../../src/pages/pickup-order.js" /* webpackChunkName: "component---src-pages-pickup-order-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-qr-login-js": () => import("./../../../src/pages/qr-login.js" /* webpackChunkName: "component---src-pages-qr-login-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-success-js": () => import("./../../../src/pages/register-success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-request-details-js": () => import("./../../../src/pages/request-details.js" /* webpackChunkName: "component---src-pages-request-details-js" */),
  "component---src-pages-request-password-reset-js": () => import("./../../../src/pages/request-password-reset.js" /* webpackChunkName: "component---src-pages-request-password-reset-js" */),
  "component---src-pages-return-shipment-js": () => import("./../../../src/pages/return-shipment.js" /* webpackChunkName: "component---src-pages-return-shipment-js" */),
  "component---src-pages-return-to-customer-js": () => import("./../../../src/pages/return-to-customer.js" /* webpackChunkName: "component---src-pages-return-to-customer-js" */),
  "component---src-pages-rh-callback-en-js": () => import("./../../../src/pages/rh-callback-en.js" /* webpackChunkName: "component---src-pages-rh-callback-en-js" */),
  "component---src-pages-sales-contact-form-js": () => import("./../../../src/pages/sales-contact-form.js" /* webpackChunkName: "component---src-pages-sales-contact-form-js" */),
  "component---src-pages-scam-messages-js": () => import("./../../../src/pages/scam-messages.js" /* webpackChunkName: "component---src-pages-scam-messages-js" */),
  "component---src-pages-sender-js": () => import("./../../../src/pages/sender.js" /* webpackChunkName: "component---src-pages-sender-js" */),
  "component---src-pages-service-points-js": () => import("./../../../src/pages/service-points.js" /* webpackChunkName: "component---src-pages-service-points-js" */),
  "component---src-pages-shopping-cart-js": () => import("./../../../src/pages/shopping-cart.js" /* webpackChunkName: "component---src-pages-shopping-cart-js" */),
  "component---src-pages-sovijakelu-en-js": () => import("./../../../src/pages/sovijakelu-en.js" /* webpackChunkName: "component---src-pages-sovijakelu-en-js" */),
  "component---src-pages-storage-time-js": () => import("./../../../src/pages/storage-time.js" /* webpackChunkName: "component---src-pages-storage-time-js" */),
  "component---src-pages-strike-refund-form-js": () => import("./../../../src/pages/strike-refund-form.js" /* webpackChunkName: "component---src-pages-strike-refund-form-js" */),
  "component---src-pages-strike-refund-form-secondary-js": () => import("./../../../src/pages/strike-refund-form-secondary.js" /* webpackChunkName: "component---src-pages-strike-refund-form-secondary-js" */),
  "component---src-pages-thank-you-form-js": () => import("./../../../src/pages/thank-you-form.js" /* webpackChunkName: "component---src-pages-thank-you-form-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-token-js": () => import("./../../../src/pages/token.js" /* webpackChunkName: "component---src-pages-token-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-pages-transfer-js": () => import("./../../../src/pages/transfer.js" /* webpackChunkName: "component---src-pages-transfer-js" */),
  "component---src-pages-update-shipment-js": () => import("./../../../src/pages/update-shipment.js" /* webpackChunkName: "component---src-pages-update-shipment-js" */),
  "component---src-pages-upgrade-size-js": () => import("./../../../src/pages/upgrade-size.js" /* webpackChunkName: "component---src-pages-upgrade-size-js" */),
  "component---src-pages-wolt-delivery-js": () => import("./../../../src/pages/wolt-delivery.js" /* webpackChunkName: "component---src-pages-wolt-delivery-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-mobile-js": () => import("./../../../src/templates/mobile.js" /* webpackChunkName: "component---src-templates-mobile-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

