import axios from 'axios';

const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

const logglyAxios = axios.create({
    baseURL: 'https://logs-01.loggly.com/inputs/4e92d8a9-baa6-4559-82e2-05428d10fa7b'
});

const log = (msg) => {
    if (isProduction) {    
        (async () => {
            try {        
                await logglyAxios.post('/tag/test/', msg, {headers: { 'content-type': 'text/plain'}});
            } catch (err) {
                console.log(err);
            }
        })();
    }
};

export default { log };

