/** @jsx jsx  */
import { jsx, Flex, Box } from 'theme-ui';
import { Facebook, Instagram, Twitter, LinkedIn } from './Icon';

const SocialLink = props => (
  <a
    {...props}
    sx={{
      display: 'flex',
      alignItems: 'center',
      py: 1,
      lineHeight: 'heading',
      fontSize: 2,
      color: 'white',
      ':hover': { opacity: 0.8 },
    }}
  />
);

const IconStyles = { width: [24, 16], height: [24, 16], mr: 3 };

export default ({ iconsOnly = false, ...props }) => (
  <Flex
    sx={{
      flexDirection: iconsOnly ? 'row' : ['row', 'column'],
      justifyContent: 'center',
    }}
  >
    <SocialLink href="https://www.facebook.com/matkahuolto">
      <Facebook sx={IconStyles} />
      <Box sx={{ display: ['none', 'block'] }}>Facebook</Box>
    </SocialLink>
    <SocialLink href="https://www.instagram.com/matkahuolto/">
      <Instagram sx={IconStyles} />
      <Box sx={{ display: ['none', 'block'] }}>Instagram</Box>
    </SocialLink>
    <SocialLink href="http://www.linkedin.com/company/matkahuolto">
      <LinkedIn
        sx={
          ({
            ml: [0, '2px'],
          },
          IconStyles)
        }
      />
      <Box sx={{ display: ['none', 'block'] }}>LinkedIn</Box>
    </SocialLink>
  </Flex>
);
