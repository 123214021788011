/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui';
import { useSelector } from 'react-redux';
import { ParcelWidget } from '.';
import { getTranslate } from 'react-localize-redux';

export default ({ sxx, showHeader = true, center = true }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  // TODO: refactor this and make it possible to create product widgets with predefined products directly form Contentful
  const products = [
    {
      id: '86XXS30',
      name: '10 x XXS',
      countryCode: 'FI',
      price: 49,
      productId: '86XXS30',
      maxSize: '3 x 25 x 40 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86XS30',
      name: '10 x XS',
      countryCode: 'FI',
      price: 57,
      productId: '86XS30',
      maxSize: '6 x 25 x 40 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86S30',
      name: '10 x S',
      countryCode: 'FI',
      price: 60,
      productId: '86S30',
      maxSize: '10 x 40 x 55 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86M30',
      name: '10 x M',
      countryCode: 'FI',
      price: 80,
      productId: '86M30',
      maxSize: '20 x 40 x 55 cm',
      productType: 'Sarjapaketti',
    },
    /*
    {
      id: '86L30',
      name: '10 x L',
      countryCode: 'FI',
      price: 119,
      productId: '86L30',
      maxSize: '35 x 40 x 55 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86SMaxi30',
      name: '10 x S Maxi',
      countryCode: 'FI',
      price: 99,
      productId: '86SMaxi30',
      maxSize: '10 x 60 x 100 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86MMaxi30',
      name: '10 x M Maxi',
      countryCode: 'FI',
      price: 119,
      productId: '86MMaxi30',
      maxSize: '20 x 60 x 100 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86LMaxi30',
      name: '10 x L Maxi',
      countryCode: 'FI',
      price: 15.9,
      productId: '86LMaxi30',
      maxSize: '35 x 60 x 100 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86XL30',
      name: '10 x XL',
      countryCode: 'FI',
      price: 17.9,
      productId: '86XL30',
      maxSize: '70 x 40 x 55 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86SMaxiPitkä30',
      name: '10 x S Maxi Pitkä',
      countryCode: 'FI',
      price: 19.9,
      productId: '86SMaxiPitkä30',
      maxSize: '10 x 60 x 200 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86XLLeveä30',
      name: '10 x XL Leveä',
      countryCode: 'FI',
      price: 19.9,
      productId: '86XLLeveä30',
      maxSize: '30 x 80 x 80 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86XLMaxi30',
      name: '10 x XL Maxi',
      countryCode: 'FI',
      price: 19.9,
      productId: '86XLMaxi30',
      maxSize: '60 x 60 x 100 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86MMaxiPitkä30',
      name: '10 x M Maxi Pitkä',
      countryCode: 'FI',
      price: 21.9,
      productId: '86MMaxiPitkä30',
      maxSize: '20 x 60 x 200 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86LMaxiPitkä30',
      name: '10 x L Maxi Pitkä',
      countryCode: 'FI',
      price: 35.9,
      productId: '86LMaxiPitkä30',
      maxSize: '35 x 60 x 200 cm',
      productType: 'Sarjapaketti',
    },
    {
      id: '86Taulu10',
      name: '10 x Taulu',
      countryCode: 'FI',
      price: 35.9,
      productId: '86Taulu10',
      maxSize: '15 x 120 x 150 cm',
      productType: 'Sarjapaketti',
    },*/
  ];

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['flex-start', center ? 'center' : null],
        ...sxx,
      }}
    >
      {showHeader && <Styled.h2 sx={{ variant: 'text.title', mt: 0 }}>{translate('multiSendInSmallMoney')}</Styled.h2>}
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          justifyContent: center && 'center',
          overflowX: 'auto',
          maxWidth: '100%',
          width: ['100%', null],
          scrollSnapType: 'x mandatory',
          mb: 2,
          mt: 3,
          gap: 2,
          MsOverflowStyle: '-ms-autohiding-scrollbar',
        }}
      >
        {products.map(p => (
          <ParcelWidget key={p.id} {...p} />
        ))}
      </Flex>
    </Flex>
  );
};
