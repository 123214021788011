import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../utils/api';
import { RETURN_METHODS, STEP } from './utils';
import { showNotification } from '../state/notifications';
import { useLanguage } from '../utils/getLanguage';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';
import { useMemo } from 'react';

export const ReturnContext = React.createContext({
  prefilledShipmentNumber: null,
  setPrefilledShipmentNumber: () => {},
  shipment: {},
  order: {},
  step: null,
  setStep: () => {},
  isLoading: false,
  setState: () => {},
  setOrderPayed: () => {},
  returnMethod: '',
  setReturnMethod: () => {},
  sender: {},
  setSender: () => {},
  returnCode: '',
  checkCode: async () => {},
  createOrder: async () => {},
  setShipment: () => {},
  resetFlow: () => {},
  pickupPrice: null,
  setDiscount: null,
  discount: null,
});

export const ReturnShipmentProvider = ({ children }) => {
  const [shipment, setShipment] = useState({});
  const [order, setOrder] = useState(null);
  const [sender, setSender] = useState(null);
  const [step, setStep] = useState();
  const [returnMethod, setReturnMethod] = useState();
  const [prefilledShipmentNumber, setPrefilledShipmentNumber] = useState();
  const [returnCode, setReturnCode] = useState('');
  const [pickupPrice, setPickupPrice] = useState(null);
  const [discount, setDiscount] = useState();
  const { user } = useSelector(state => state.session);

  const dispatch = useDispatch();
  const language = useLanguage();
  const { isLoading: isUserLoading } = useSelector(state => state.session);

  const loadShipment = useCallback(
    async (shipmentNumber, recaptcha) => {
      try {
        if (!shipmentNumber || !!shipment?.shipmentNumber) {
          return;
        }
        if (!recaptcha) {
          return;
        }
        const { shipment, canReturn, needsReturnPermissionCode, pickupPrice } = await api.getShipmentForReturn(
          shipmentNumber,
          recaptcha
        );
        if (!canReturn) {
          setShipment({ returnNotPossible: true });
          return false;
        }
        setPickupPrice(pickupPrice);
        setShipment({ ...shipment, needsReturnPermissionCode });
        setSender({ hasPickup: false });
        return { canReturn, needsReturnPermissionCode };
      } catch (e) {
        setShipment({ returnNotPossible: true });
        return { canReturn: false };
      } finally {
      }
    },
    [dispatch, shipment]
  );

  const isLoading = useMemo(() => isUserLoading || (!shipment?.shipmentNumber && !shipment?.returnNotPossible), [
    shipment,
    isUserLoading,
  ]);

  const setOrderPayed = useCallback(order => {
    setOrder(order);
    setStep(STEP.DONE);
  }, []);

  const resetFlow = useCallback(() => {
    setStep(null);
    setShipment({});
    setPickupPrice(null);
    setSender(null);
    setOrder(null);
    setPrefilledShipmentNumber(null);
    setReturnMethod(null);
  }, []);

  const goBack = () => {
    setStep(prevStep => {
      const currentIndex = allSteps.indexOf(prevStep);
      if (currentIndex > 0) {
        return allSteps[currentIndex - 1];
      }
      return prevStep; // Stay at the current step if it's the first step
    });
  };

  const goNext = step => {
    if (step) {
      setStep(step);
    } else {
      setStep(prevStep => {
        const currentIndex = allSteps.indexOf(prevStep);
        if (currentIndex < allSteps.length - 1) {
          const nextStep = allSteps[currentIndex + 1];
          return nextStep;
        }
        return prevStep; // Stay at the current step if it's the last step
      });
    }
  };

  const shipmentNumber = isBrowser && new URLSearchParams(location.search).get('shipment');

  const allSteps = useMemo(() => {
    const senderInfoStep = sender?.hasPickup ? STEP.SENDER_INFO : null;
    const baseSteps = [STEP.CHOOSE_PICKUP, senderInfoStep, STEP.PICKUP_PAYMENT];

    const getLoggedInSteps = () => {
      if (shipment?.needsReturnPermissionCode) {
        // user came from shipment list, and shipment needs the return code
        const shipmentNumberStep = !shipmentNumber ? STEP.SHIPMENT_NUMBER : null;
        return [shipmentNumberStep, STEP.RETURN_PERMISSION_CODE, ...baseSteps];
      }
      if (shipmentNumber && shipment?.shipmentNumber) {
        // user came from the shipment list, no return code needed
        return baseSteps;
      }
      if (returnMethod === RETURN_METHODS.SHIPMENT_NUMBER) {
        // user came directly to /palauta-paketti and chose to return by shipment number
        return [STEP.CHOOSE_METHOD, STEP.SHIPMENT_NUMBER, ...baseSteps];
      }
      if (returnMethod === RETURN_METHODS.RETURN_PERMISSION_CODE) {
        // user came directly to /palauta-paketti and chose to return by permission code
        return [STEP.CHOOSE_METHOD, STEP.SHIPMENT_NUMBER, STEP.RETURN_PERMISSION_CODE, ...baseSteps];
      }

      // user landed on /palauta-paketti by URL
      return [STEP.CHOOSE_METHOD];
    };

    const getAnonymousSteps = () => {
      if (returnMethod === RETURN_METHODS.SHIPMENT_NUMBER) {
        // user has selected return by shipment number
        const permissionCodeStep = shipment?.needsReturnPermissionCode ? STEP.RETURN_PERMISSION_CODE : null;
        return [STEP.CHOOSE_METHOD, STEP.SHIPMENT_NUMBER, permissionCodeStep, ...baseSteps];
      }
      if (returnMethod === RETURN_METHODS.RETURN_PERMISSION_CODE) {
        // user has selected return by code
        return [STEP.CHOOSE_METHOD, STEP.SHIPMENT_NUMBER, STEP.RETURN_PERMISSION_CODE, ...baseSteps];
      }
      if (!shipmentNumber) {
        // user landed on /palauta-paketti by URL
        return [STEP.CHOOSE_METHOD];
      }
      return [];
    };

    if (order && !shipmentNumber) {
      return [STEP.READY];
    }
    if (shipment?.returnNotPossible) {
      return [STEP.INVALID_SHIPMENT];
    }
    const steps = (user ? getLoggedInSteps() : getAnonymousSteps()).filter(Boolean);
    return steps;
  }, [user, shipment, returnMethod, sender, order, shipmentNumber]);

  const canGoBack = useMemo(() => {
    const currentIndex = (allSteps || []).indexOf(step);
    return currentIndex > 0;
  }, [step, allSteps]);

  const canGoNext = useMemo(() => {
    const currentIndex = (allSteps || []).indexOf(step);
    return currentIndex < (allSteps || []).length - 1;
  }, [step, allSteps]);

  useEffect(() => {
    // if no step is set or current step isn't part of the flow, go to first step of the current flow
    if (!step || (step && !allSteps.includes(step))) {
      setStep(allSteps?.[0]);
    }
  }, [step, allSteps]);

  const checkCode = useCallback(
    async code => {
      try {
        const response = await api.checkReturnCode(shipment.shipmentNumber, code);
        if (response.valid) {
          setReturnCode(code);
          goNext();
          return;
        } else {
          return 'INVALID_CODE';
        }
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [shipment, setReturnCode, dispatch, goNext]
  );

  const createOrder = useCallback(
    async (senderDetails, paymentOptions, recaptcha) => {
      try {
        const response = await api.createReturnShipmentOrder(
          shipment.shipmentNumber,
          paymentOptions,
          language,
          returnCode,
          { ...sender, ...senderDetails, discountCode: undefined },
          discount?.code,
          recaptcha
        );
        const { redirectUrl, orderId, transactionId } = response;
        if (redirectUrl) {
          setOrderId(orderId, transactionId);
          isBrowser && window.location.assign(redirectUrl);
          return;
        }
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [returnCode, shipment, sender, dispatch, discount]
  );

  return (
    <ReturnContext.Provider
      value={{
        shipment,
        order,
        setStep,
        step,
        loadShipment,
        setOrderPayed,
        goNext,
        goBack,
        sender,
        setSender,
        returnCode,
        checkCode,
        createOrder,
        pickupPrice,
        isLoading,
        returnMethod,
        setReturnMethod,
        prefilledShipmentNumber,
        setPrefilledShipmentNumber,
        setDiscount,
        discount,
        allSteps,
        canGoBack,
        canGoNext,
        setShipment,
        resetFlow,
      }}
    >
      {children}
    </ReturnContext.Provider>
  );
};
