/** @jsx jsx */
import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { DropZone } from 'react-formik-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Box, Button, jsx, Label, useThemeUI } from 'theme-ui';

const DropzoneField = ({ name, newLabel, extraLabel, placeholder, clearButtonText, zoneActiveText }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const { setFieldValue, values } = useFormikContext();
  //const files = (values[name] || []).filter(v => v instanceof File);
  //const totalSize = (files.reduce((total, file) => total + file.size, 0) / 1024).toFixed(0); // kB
  const maxSize = 5000; // about 5.9 MB
  const label = newLabel || translate('feedback.attachmentSize', { maxSize: maxSize / 1000 });

  const clearFiles = () => {
    setFieldValue(name, []);
  };

  const { theme } = useThemeUI();
  const StyledDropZone = styled(DropZone)`
    img {
      max-height: 200px;
      display: inline-block;
    }
  `;
  return (
    <Box
      sx={{
        '.error': { color: 'danger' },
        '.dropzone-wrapper .dropzone': {
          display: 'flex',
          justifyContent: 'center',
          borderWidth: 2,
          borderColor: 'border',
          borderStyle: 'dashed',
          borderRadius: 2,
          backgroundColor: 'grayLighter',
          padding: 2,
        },
        '.dropzone': {
          alignItems: 'center',
        },
        img: {
          maxHeight: '200px',
          maxWidth: '200px',
          mx: 'auto',
          display: 'block',
        },
      }}
    >
      <Label sx={{ mb: extraLabel ? 0 : 1 }}>{label}</Label>
      {extraLabel && <Box sx={{ mb: 1 }}>{extraLabel}</Box>}
      <DropZone name={name} placeholder={placeholder} zoneActiveText={zoneActiveText} />
      <Button variant="plain" onClick={clearFiles}>
        {clearButtonText}
      </Button>
    </Box>
  );
};

export default DropzoneField;
