/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';

export default ({ title, bg = 'primary', titleColor = 'primary80', color = 'white', action, ...props }) => {
  return (
    <Box
      sx={{
        flex: ['auto', null, '1 1 0'],
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: [3, 24],
          px: [3, 24],
          height: ['auto', null, '100%'],
          bg: bg ? bg : 'secondary',
          color,
          minHeight: ['auto', null, null, 200],
          borderRadius: [0, 4],
        }}
      >
        {title && action && (
          <Flex>
            <p
              sx={{
                mt: 0,
                mb: [2, null, 3],

                color: titleColor,
                fontWeight: 'semibold',
                flex: 1,
                fontSize: '22px',
              }}
            >
              {title}
            </p>
            {action}
          </Flex>
        )}
        {title && !action && (
          <p
            sx={{
              mt: 0,
              mb: [2, null, 3],
              color: titleColor,
              fontWeight: 600,
              fontSize: '22px',
            }}
          >
            {title}
          </p>
        )}
        <Flex
          sx={{
            flex: 'auto',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {props.children}
        </Flex>
      </Flex>
    </Box>
  );
};
