import * as api from '../utils/api';
import { showNotification } from './notifications';
import { locNavigate } from './session';
import getLanguage from '../utils/getLanguage';

export const STATUS = {
  UNUSED: 'UNUSED',
  FILLED: 'FILLED',
  SENT: 'SENT',
};

const initialState = {
  email: null,
  productId: null,
  product: {},
  services: [],
  shipments: [],
  sender: {},
};

export const fetchSeriesInfo = (email, productId, isRefresh, onSuccess) => async (
  dispatch,
  getState
) => {
  if (getState().series.email !== email) {
    dispatch(setEmail(email));
  }

  let response;
  try {
    const language = getLanguage(getState());
    response = await api.getSeriesProduct(email, productId, language);
  } catch (error) {
    if (error.authorizationRequired) {
      dispatch(showNotification('series.form.loginRequired'));
      dispatch(locNavigate('/login', `?email=${email}&afterLogin=/multi-parcel%3FproductId=${productId}`));
      return;
    }
    dispatch(showNotification('genericApiError'));
  }

  if (!response) {
    onSuccess(null);
    return;
  }

  response.productId = productId;
  response.updateTimestamp = +new Date();
  if (!isRefresh) {
    response.firstFetched = +new Date();
  }
  dispatch(setSeriesInfo(response));
  onSuccess(productId);
};

export const updateShipment = (productId, shipment, isUnused) => async (
  dispatch,
  getState
) => {
  const state = getState().series;
  try {
    const language = getLanguage(getState());
    const { activationCode } = await api.updateSeriesShipment({
      ...shipment,
      email: state.email,
      productId,
      isUnused,
    }, language);
    dispatch({
      shipmentNumber: shipment.shipmentNumber,
      recipient: shipment.recipient,
      pickupPoint: shipment.pickupPoint,
      activationCode,
      status: STATUS.FILLED,
      type: UPDATE_SERIES_SHIPMENT,
    });
  } catch (error) {
    dispatch(showNotification('updateFailed'));
    console.warn('updateSeriesShipment failed:', error);
    throw error;
  }
};

const SET_EMAIL = 'SET_EMAIL';
const SET_SERIES_INFO = 'SET_SERIES_INFO';
const RESET_SERIES_INFO = 'RESET_SERIES_INFO';
const UPDATE_SERIES_SHIPMENT = 'UPDATE_SERIES_SHIPMENT';

export const setEmail = email => ({
  type: SET_EMAIL,
  email,
});

export const setSeriesInfo = data => ({
  ...data,
  type: SET_SERIES_INFO,
});

export const resetSeriesInfo = () => ({
  type: RESET_SERIES_INFO,
});

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case SET_EMAIL:
      return { ...state, ...params };
    case SET_SERIES_INFO:
      return { ...state, ...params };
    case RESET_SERIES_INFO:
      return { ...initialState, email: state.email };
    case UPDATE_SERIES_SHIPMENT:
      const shipments = state.shipments.map(s =>
        s.shipmentNumber !== params.shipmentNumber ? s : { ...s, ...params }
      );
      return { ...state, shipments };
    default:
      return state;
  }
};
