import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { LocalizeProvider, initialize, addTranslationForLanguage } from 'react-localize-redux';
import { store } from '../state/ReduxWrapper';
import localizationFi from './fi.json';
import localizationSv from './sv.json';
import localizationEn from './en.json';

const languages = [{ name: 'Finnish', code: 'fi' }, {name: 'Swedish', code: 'sv'}, {name: 'English', code: 'en'}];
const defaultLanguage = 'fi';

const translations = {
  fi: localizationFi,
  sv: localizationSv,
  en: localizationEn
};

const options = {
  renderToStaticMarkup,
  onMissingTranslation: (x) => x.defaultTranslation, // Fallback to default language
  // onMissingTranslation: x => x.translationId, // If translation is missing return the key.
  // onMissingTranslation: x => `käännös "${x.translationId}" puuttuu`, // If translation is missing return the key.
};

store.dispatch(initialize({ languages, translation: translations[defaultLanguage], options }));
for (var lang in translations) {
  store.dispatch(addTranslationForLanguage(translations[lang], lang));
}

export default ({ children }) => <LocalizeProvider store={store}>{children}</LocalizeProvider>;
