import { useThemeUI } from '@theme-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isBrowser } from '../utils';
import mapScriptLoader from '../utils/mapScriptLoader';
const PADDING_BREAKPOINTS = [false, false, true]; // Breakpoints for responsive layout
const DEF_LATITUDE = 62.48;
const DEF_LONGITUDE = 25.69;
const DEF_ZOOM = 6;
const MAX_ZOOM_OUT = 6.1;
const MAX_TOP = 70.1;
const MAX_LEFT = 19.1;
const MAX_BOTTOM = 59.5;
const MAX_RIGHT = 31.6;
const FOCUS_ZOOM = 15;
const FOCUS_ZOOM_MULTIPLIER = 0.3;
const VIEW_AREA_RESIZE = 0.06;

export const useMap = (mapRef, { paddingLeft, onViewChanged, onAddressFound, onMapCreated } = {}) => {
  const platform = useRef(null);
  const map = useRef(null);
  const ui = useRef(null);
  const { theme } = useThemeUI();
  const [singleTouchDrag, setSingleTouchDrag] = useState(false);
  const [mapCreated, setMapCreated] = useState(false);
  const calculatedPadding = useMemo(() => {
    if (!theme.breakpoints || !isBrowser || mapCreated) return false;

    let themeIndex = theme.breakpoints.findIndex(point => {
      if (point.substring(point.length - 2) === 'em') {
        const fontSize = getComputedStyle(document.querySelector('body'))['font-size'];
        return window.innerWidth < parseFloat(fontSize) * parseFloat(point);
      } else {
        return window.innerWidth < parseFloat(point);
      }
    });
    const listLen = PADDING_BREAKPOINTS.length;
    return themeIndex >= 0 && themeIndex < listLen ? PADDING_BREAKPOINTS[themeIndex] : PADDING_BREAKPOINTS[listLen - 1];
  }, [theme]);

  const handleCreateMap = useCallback(() => {
    if (!mapRef) return;
    const HERE = isBrowser && window.H;
    platform.current = new HERE.service.Platform({
      apikey: process.env.HERE_API_KEY,
    });

    // Heading and tilt behaviors are disabled
    const enabledBehaviors =
      HERE.mapevents.Behavior.Feature.PANNING |
      HERE.mapevents.Behavior.Feature.PINCH_ZOOM |
      HERE.mapevents.Behavior.Feature.WHEEL_ZOOM |
      HERE.mapevents.Behavior.Feature.DBL_TAP_ZOOM |
      HERE.mapevents.Behavior.Feature.FRACTIONAL_ZOOM;

    // Obtain the default map types from the platform object
    const defaultLayers = platform.current.createDefaultLayers();

    // Instantiate (and display) a map object
    map.current = new HERE.Map(mapRef, defaultLayers.vector.normal.map, {
      zoom: DEF_ZOOM,
      center: { lat: DEF_LATITUDE, lng: DEF_LONGITUDE },
      pixelRatio: (window && window.devicePixelRatio) || 1,
    });
    if (onMapCreated) onMapCreated(map.current, platform.current, HERE);

    // Add a resize listener to make sure that the map occupies
    // the whole container and padding is correct

    const viewport = map.current.getViewPort();
    const setPadding = () => viewport.setPadding(0, 0, 0, calculatedPadding && paddingLeft ? paddingLeft : 0);
    setPadding();

    isBrowser &&
      window.addEventListener('resize', () => {
        viewport.resize();
        setPadding();
      });

    // Add zoom pan etc. controls

    const behavior = new HERE.mapevents.Behavior(new HERE.mapevents.MapEvents(map.current), {
      kinetics: { power: 0, duration: 0 },
      enabled: enabledBehaviors,
    });
    ui.current = new HERE.ui.UI(map.current, { locale: 'fi-FI' });
    const zoomControl = new HERE.ui.ZoomControl({
      alignment: HERE.ui.LayoutAlignment.BOTTOM_RIGHT,
    });
    ui.current.addControl('zoom', zoomControl);
    ui.current.addControl('scalebar', new HERE.ui.ScaleBar({ alignment: HERE.ui.LayoutAlignment.BOTTOM_RIGHT }));

    // Trigger view change function on user input

    if (onViewChanged) map.current.addEventListener('wheel', onViewChanged);
    if (onViewChanged) map.current.addEventListener('dragend', onViewChanged);
    if (onViewChanged) map.current.addEventListener('mapviewchange', onViewChanged);

    // Add Google maps like controls

    let startY,
      endY = 0;
    map.current.addEventListener('dragstart', e => {
      if (e.currentPointer.type === 'touch') {
        if (e.pointers.length < 2) {
          startY = e.currentPointer.viewportY;
          behavior.disable();
          setSingleTouchDrag(true);
        }
      }
    });

    map.current.addEventListener('drag', e => {
      if (e.currentPointer.type === 'touch' && e.pointers.length < 2) {
        endY = e.currentPointer.viewportY;
        isBrowser && window.scrollBy(0, startY - endY);
      }
    });

    map.current.addEventListener('dragend', () => {
      behavior.enable(enabledBehaviors);
      if (singleTouchDrag) setSingleTouchDrag(false);
    });

    // Add bounds where user can look at (Finland) and how far can zoom out

    const bounds = new HERE.geo.Rect(MAX_TOP, MAX_LEFT, MAX_BOTTOM, MAX_RIGHT);

    map.current.getViewModel().addEventListener('sync', () => {
      let center = map.current.getCenter();

      if (map.current.getZoom() < MAX_ZOOM_OUT) {
        map.current.setZoom(MAX_ZOOM_OUT);
      }

      if (!bounds.containsPoint(center)) {
        if (center.lat > bounds.getTop()) {
          center.lat = bounds.getTop();
        } else if (center.lat < bounds.getBottom()) {
          center.lat = bounds.getBottom();
        }
        if (center.lng < bounds.getLeft()) {
          center.lng = bounds.getLeft();
        } else if (center.lng > bounds.getRight()) {
          center.lng = bounds.getRight();
        }
        map.current.setCenter(center);
      }
    });

    // Provide user location
    const searchUserLocation = onCoordinatesFound => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            map.current.setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            map.current.setZoom(FOCUS_ZOOM, true);
            onCoordinatesFound(position.coords.latitude, position.coords.longitude);
          },
          e => console.warn('searchUserLocation failed:', e),
          { maximumAge: 10000, timeout: 6000 }
        );
      }
    };

    const searchAddressByCoordinates = (lat, long, onSuccess) => {
      const geocoder = platform.current.getGeocodingService();
      geocoder.reverseGeocode(
        {
          prox: `${lat},${long}`,
          mode: 'retrieveAddress',
          maxresults: 1,
        },
        onSuccess,
        e => console.warn('searchAddressByCoordinates failed:', e)
      );
    };

    if (onAddressFound) {
      searchUserLocation((lat, lng) =>
        searchAddressByCoordinates(lat, lng, result => {
          onAddressFound && onAddressFound(result.Response.View[0].Result[0].Location.Address);
        })
      );
    }
    setMapCreated(true);
  }, [
    map,
    platform,
    mapRef,
    calculatedPadding,
    paddingLeft,
    ui,
    singleTouchDrag,
    setSingleTouchDrag,
    onViewChanged,
    onAddressFound,
    setMapCreated,
    mapCreated,
  ]);
  useEffect(() => {
    if (!mapRef) return;
    mapScriptLoader(() => {
      try {
        handleCreateMap();
      } catch (error) {
        console.error('Problem in creating map:', error);
      }
    });
  }, [mapRef]);
  return { map, platform, mapCreated, calculatedPadding, singleTouchDrag };
};
