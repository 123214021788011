/** @jsx jsx*/
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import Link from '../components/Link';
import Button from '../components/Button';
import { getTranslate } from 'react-localize-redux';
import CustomsClearanceStatus from './CustomsClearanceStatus';

export const TrackingEvents = ({ events }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  if (!events) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        mt: 4,
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 11,
          width: 2,
          bg: 'gray',
        },
      }}
    >
      {events.map((event, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            mb: 4,
            pl: 40,
            '::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              width: 24,
              height: 24,
              borderRadius: 3,
              bg: 'white',
              border: '2px solid',
              borderColor: 'white',
              boxShadow: 'step',
            },
          }}
        >
          <Box sx={{ fontWeight: 'medium' }}>
            {event.date} {event.time} <span sx={{ fontWeight: 'normal' }}>{event.place}</span>{' '}
            {event.officeCode && (
              <>
                <span>&nbsp;&nbsp;</span>
                <Link to={`/palvelupisteet?id=${event.officeCode}`} sx={{ textDecoration: 'underline' }}>
                  {translate('account.myParcels.showOnMap')}
                </Link>
              </>
            )}
          </Box>
          <Box sx={{ color: 'black' }}>{event.description}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default function TrackingParcel({ parcel }) {
  const translate = getTranslate(useSelector(state => state.localize));
  if (!parcel) return null;

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Flex>
          <Box sx={{ width: 160, color: 'gray' }}>{translate('tracking.details.parcelNumber')}</Box>
          <Box sx={{ fontWeight: 'medium' }}>{parcel.parcelNumber || '-'}</Box>
        </Flex>
        {!parcel.productCategory || parcel.productCategory.includes('käteinen') ? null : (
          <Flex>
            <Box sx={{ width: 160, color: 'gray' }}>{translate('tracking.details.productCategory')}</Box>
            <Box>{parcel.productCategory}</Box>
          </Flex>
        )}
        <Flex>
          <Box sx={{ width: 160, color: 'gray' }}>{translate('tracking.details.additionalServices')}</Box>
          <Box>{parcel.additionalServices ? reduceServices(parcel.additionalServices) : '-'}</Box>
        </Flex>
      </Box>
      {parcel.customsStatus && <CustomsClearanceStatus parcel={parcel} />}
      <TrackingEvents events={parcel.trackingEvents} />
    </>
  );
}

function reduceServices(services) {
  const servicesArr = services.map(service => service.description);
  return servicesArr.join(', ');
}
