/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';

const PageGallery = ({ videos }) => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr'],
        gridGap: [3, null, null, 4],
        maxWidth: '100%',
      }}
    >
      {videos &&
        videos.map(image => (
          <video
            key={image.contentful_id}
            src={image?.file?.url || image?.fluid?.src}
            controls
            sx={{ maxWidth: '100%', backgroundColor: 'blueLighter' }}
          >
            Your browser does not support the video tag.
          </video>
        ))}
    </Grid>
  );
};

export default PageGallery;
