export default {
  Pink: {
    bg: 'primary',
    titleColor: 'pinkDark',
    color: 'white',
  },
  Bluelight: {
    bg: 'primary',
    color: 'white',
    titleColor: 'primary80',
  },
  Transparent: {
    titleColor: '#FFFFFF',
    color: 'text',
    bg: '#FFFFFF',
  },
};
