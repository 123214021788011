import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import exampleReducer from './example';
import basket from './basket';
import catalog from './catalog';
import delivery from './delivery';
import notifications from './notifications';
import parcelFlow from './parcelFlow';
import series from './series';
import sessionReducer from './session';
import shipments from './shipments';
import trackingReducer from './tracking';
import transfer from './transfer';
import servicePoints from './servicePoints';
import pickupOrder from './pickupOrder';
import favoriteTrips from './favoriteTrips';
import customsFlow from './customsFlow';

export default combineReducers({
  localize: localizeReducer,
  example: exampleReducer,
  basket,
  catalog,
  delivery,
  notifications,
  parcelFlow,
  customsFlow,
  series,
  session: sessionReducer,
  shipments,
  tracking: trackingReducer,
  transfer,
  servicePoints: servicePoints,
  pickupOrder: pickupOrder,
  favoriteTrips,
});
