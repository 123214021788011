/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { CallToActionWidget } from '../widgets';

export default ({ widgets, sxx }) => (
  <Grid
    sx={{
      mt: 4,
      mx: [-3, 0],
      gridGap: 3,
      gridTemplateColumns: ['1fr', null, null, '1.5fr 1.5fr 1.5fr'],
      ...sxx,
    }}
  >
    {widgets &&
      widgets.map((widget, i) => <CallToActionWidget content={widget} key={widget.contentful_id} sidebar={false} />)}
  </Grid>
);
