
export const split = address => {
  if (!address) {
    return ['', ''];
  }
  const streetNum = /\d+/.exec(address);
  const index = streetNum ? streetNum.index + streetNum[0].length : address.length;
  return [address.substr(0, index), address.substr(index).trim()];
};

export const combine = ({street, apartment}) => {
  if (!apartment) {
    return street || '';
  }
  return street + ' ' + apartment;
};
