import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Poppins from '../../static/fonts/Poppins-Regular.ttf';
import PoppinsMedium from '../../static/fonts/Poppins-SemiBold.ttf';
import PoppinsBold from '../../static/fonts/Poppins-Bold.ttf';

const SEO = ({ title, description, image, pathname, article, keywords, locale, updatedAt, paths }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
          twitterUserId,
        },
      },
    }) => {
      const seo = {
        name: title,
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${image || siteUrl + defaultImage}`,
        url: `${siteUrl}${paths[(locale || '').substring(0, 2)] || '/'}`,
        keywords,
        locale: locale || 'fi_FI',
        updatedAt,
      };

      let schemaOrgJSONLD = [];
      if (Object.values(paths).includes('/' || '/en' || '/sv')) {
        schemaOrgJSONLD.push({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: defaultTitle,
          url: siteUrl,
          sameAs: [
            'https://www.facebook.com/matkahuolto',
            'https://www.instagram.com/matkahuolto',
            'https://twitter.com/matkahuolto',
            'http://www.linkedin.com/company/matkahuolto',
            'https://www.youtube.com/channel/UCz1VCI7vgDsSQxQ0Gb_iGDg',
          ],
          logo: seo.image,
        });
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate} htmlAttributes={{ lang: seo.locale.substr(0, 2) }}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {/* Preload fonts */}
            <link rel="preload" href={Poppins} as="font" type="font/ttf" crossOrigin="anonymous" />
            <link rel="preload" href={PoppinsBold} as="font" type="font/ttf" crossOrigin="anonymous" />
            <link rel="preload" href={PoppinsMedium} as="font" type="font/ttf" crossOrigin="anonymous" />

            <meta name="og:site_name" content={seo.name} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {/* Schema.org tags */}
            {schemaOrgJSONLD.length > 0 && (
              <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
            )}
            {article ? <meta property="og:type" content="article" /> : <meta name="og:type" content="website" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary" />
            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
            {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
            {twitterUserId && <meta name="twitter:site:id" content={twitterUserId} />}
            {twitterUserId && <meta name="twitter:creator:id" content={twitterUserId} />}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {seo.keywords && <meta name="keywords" content={seo.keywords} />}
            {seo.locale && <meta name="og:locale" content={seo.locale} />}
            {seo.updatedAt && <meta name="og:updated_time" content={seo.updatedAt} />}
            {Object.entries(paths || {}).map(([loc, path]) => (
              <link key={loc} rel="alternative" href={path} hrefLang={loc} />
            ))}
          </Helmet>
        </>
      );
    }}
  />
);

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        twitterUserId
      }
    }
  }
`;

export default SEO;
