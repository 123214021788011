import React from 'react';
import { Box, Flex } from 'theme-ui';
import { InfoIcon } from './Icon';
import RichText from './RichText';

const AlertNotification = ({ sxx, body, locale }) => {
  return (
    <Box sx={{ width: '100%', backgroundColor: 'blueLighter', py: '24px', px: 4, mb: 4, ...sxx }}>
      <Flex sx={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
        <div>
          <InfoIcon />
        </div>
        <RichText content={body} locale={locale} paragraphStyles={{ m: 0 }} />
      </Flex>
    </Box>
  );
};

export default AlertNotification;
