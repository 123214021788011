import { useThemeUI } from 'theme-ui';
const { theme } = useThemeUI;

const textInputBase = {
  bg: 'white',
  color: 'black',
  borderColor: 'border',
  borderRadius: 1,
  borderWidth: 2,
  '::-webkit-input-placeholder': {
    color: 'gray',
  },
  '::-moz-placeholder': {
    color: 'gray',
  },
  ':-ms-input-placeholder': {
    color: 'gray',
  },
  '::placeholder': {
    color: 'gray',
  },
  ':focus, :focus:hover': {
    //boxShadow: 'inputFocus',
    outline: 'none',
    borderColor: 'newBlue',
  },
  ':hover': {
    borderColor: 'newBlue',
    //boxShadow: 'inputHover',
  },
};

export default {
  input: {
    ...textInputBase,
  },
  select: {
    ...textInputBase,
  },
  label: {
    fontWeight: 'medium',
    fontSize: 2,
    color: 'black',
    div: {
      flex: 'none',
    },
  },
  textarea: { ...textInputBase },
  radio: {},
  checkbox: { mt: '-2px' },
  error: {
    borderColor: 'error',
  },
};
