/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLanguage } from '../../utils/getLanguage';
import HereMap from '../HereMap';
import { useMapMarker } from '../../home-delivery/Map';

const FOCUS_POINT_MARKER = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<circle cx="742" cy="460" r="11" transform="translate(-730 -448)" fill="#001E60" stroke="#FFF" stroke-width="2" fill-rule="evenodd" fill-opacity=".8"/>
</svg>`;

export default ({ stop, height = 400, connection }) => {
  const size = useMemo(() => (stop ? height : 0), [stop, height]);
  const { theme } = useThemeUI();
  const [zoom, setZoom] = useState(12);
  const [openedState, setOpenedState] = useState('CLOSED');
  const language = useLanguage();
  const [mapRef, setMapRef] = useState(null);
  const HERE = useRef();
  const handleMapCreated = useCallback(
    (newMap, _platform, newHERE) => {
      HERE.current = newHERE;
      setMapRef(newMap);
    },
    [setMapRef]
  );

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => {
      if (size === 0) {
        setOpenedState('CLOSED');
      } else if (size === height) {
        setOpenedState('OPENED');
      } else {
        setOpenedState('OPENING');
      }
    }, 500);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [size, height, setOpenedState, timeoutRef]);

  useMapMarker(
    HERE.current,
    mapRef,
    stop ? { latitude: stop.nCoordinate, longitude: stop.eCoordinate } : null,
    FOCUS_POINT_MARKER,
    true,
    {
      anchor: { x: 10, y: 10 },
      size: { w: 20, h: 20 },
    }
  );

  useEffect(() => {
    if (!mapRef || !stop) {
      return;
    }
    mapRef.getViewModel().setLookAtData(
      {
        position: { lat: stop.nCoordinate, lng: stop.eCoordinate },
        zoom: 12,
      },
      false
    );
  }, [mapRef, stop]);

  return (
    <Box sx={{ width: '100%', height: size, transition: 'height 500ms ease-in-out' }}>
      {openedState !== 'CLOSED' && (
        <HereMap onMapCreated={handleMapCreated} height={`${height}px`} paddingLeft={0} absoluteHeight />
      )}
    </Box>
  );
};
