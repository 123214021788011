/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import Link from './Link';

const NavigationActionLink = ({ current, ...props }) => (
  <Flex
    as={Link}
    partiallyActive={true}
    sx={{
      position: 'relative',
      alignItems: 'center',
      color: ['white', null, null, 'hiContrast'],
      mx: 2,
      transition: 'color .25s ease',
      ':hover': {
        color: 'hiContrast',
        textDecoration: ['underline', null, null, null],
      },
      ':hover, &[aria-current="page"]': {
        color: ['white', null, null, 'hiContrast'],
      },
      '&::after': {
        position: 'absolute',
        bottom: -1,
        left: 2,
        right: 2,
        height: 2,
        bg: 'hiContrast',
        content: '""',
        width: current ? [0, 0, 0, 'auto'] : 0,
      },
      '&[aria-current="page"]::after': {
        width: [0, 0, 0, 'auto'],
      },
    }}
    {...props}
  >
    {props.children}
  </Flex>
);

export default NavigationActionLink;
