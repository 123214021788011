/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';

export default ({ children }) => {
  return (
    <Flex sx={{ flexDirection: 'row' }}>
      <span sx={{ fontSize: 140, mr: 3, alignSelf: 'flex-start', mt: '-0.35em', mb: '-1em' }}>&rdquo;</span>
      <div>{children}</div>
    </Flex>
  );
};
