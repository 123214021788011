import { useStaticQuery, graphql } from 'gatsby';

export const usePages = () => {
  const { allContentfulPage } = useStaticQuery(
    graphql`
      query AllContentfulPageQuery {
        allContentfulPage {
          edges {
            node {
              id
              contentful_id
              slug
              parentPage {
                ... on ContentfulLayout {
                  slug
                }
                ... on ContentfulPage {
                  slug
                  parentPage {
                    ... on ContentfulLayout {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allContentfulPage.edges;
};
