import moment from 'moment-timezone';

export function dateToTimezoneHour(date) {
  return moment(date)
    .tz('Europe/Helsinki')
    .format('HH.mm');
}

export function dateToTimezoneDate(date) {
  return moment(date)
    .tz('Europe/Helsinki')
    .format('D.M.YYYY');
}
