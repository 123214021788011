import { useStaticQuery, graphql } from 'gatsby';

export const filterByChannel = (edge) => {
  const channels = edge.node.channel;
  return channels === null || channels.includes('www.matkahuolto.fi');
};

export const useBlogPosts = () => {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      query AllContentfulBlogPostQuery {
        allContentfulBlogPost {
          edges {
            node {
              id
              contentful_id
              slug
              postType
              channel
            }
          }
        }
      }
    `
  );
  return allContentfulBlogPost.edges.filter(filterByChannel);
};
