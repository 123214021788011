export default (HERE, iconUrl) => {
  const domIcon = document.createElement('img');
  domIcon.style.height = '40px';
  domIcon.style.width = '27px';
  // Set marker "on top" of point
  domIcon.style.top = '-40px';
  domIcon.style.left = '-13.5px';
  domIcon.setAttribute('src', iconUrl);
  return new HERE.map.DomIcon(domIcon);
};

export const createUrlDomIcon = (HERE, iconUrl) => {
  const domIcon = document.createElement('img');
  domIcon.style.height = '24px';
  domIcon.style.width = '24px';
  domIcon.setAttribute('src', iconUrl);
  return new HERE.map.DomIcon(domIcon);
};
