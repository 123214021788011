/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import en from 'date-fns/locale/en-GB';
import sv from 'date-fns/locale/sv';
import { CalendarIcon } from './Icon';

import 'react-datepicker/dist/react-datepicker.css';
import { pointer } from 'popmotion';

registerLocale('fi', fi);
registerLocale('en', en);
registerLocale('sv', sv);
setDefaultLocale('fi');

const datePickerStyle = (newStyle = false) => ({
  input: {
    minWidth: 0,
    width: 'calc(100%)',
    flex: 1,
    bg: 'transparent',
    color: newStyle ? 'secondary' : 'white',
    fontSize: 2,
    fontWeight: newStyle ? 'body' : 'bold',
    border: newStyle ? 'solid 1px #CED2D9' : 0,
    borderRadius: newStyle ? 1 : 0,
    margin: newStyle ? '1px' : 0,
    padding: newStyle ? 2 : 0,
    cursor: 'pointer',
    '::placeholder': { color: newStyle ? 'grayLight' : 'white', opacity: 1 },
  },
  'input:hover': newStyle
    ? {
        border: 'solid 2px #9EA6B4',
        margin: 0,
      }
    : {},
  '.react-datepicker': {
    fontFamily: 'body',
    fontSize: 1,
    boxShadow: 'dropdown',
    border: 0,
  },
  '.react-datepicker__current-month, ': {
    color: 'text',
  },
  '.react-datepicker__header': {
    bg: 'blueLighter',
    border: 0,
  },
  '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle': {
    borderBottomColor: 'blueLighter',
  },
  '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before': {
    borderBottom: 0,
  },
  '.react-datepicker__day, .react-datepicker__day-name': {
    width: 40,
    lineHeight: '40px',
    margin: '1px',
    color: 'text',
  },
  '.react-datepicker__day--selected': {
    bg: 'primary',
    color: 'white',
  },
  '.react-datepicker__day--disabled': {
    bg: 'white',
    color: '#ccc',
  },
});

export const DateField = ({ field, form, ...props }) => (
  <Box sx={datePickerStyle(true)}>
    <DatePicker
      selected={field.value}
      onChange={value => form.setFieldValue(field.name, value)}
      locale={getActiveLanguage(useSelector(state => state.localize)).code}
      {...props}
    />
  </Box>
);

export const DateInput = ({ newStyle, ...props }) => (
  <Box
    sx={{
      display: newStyle ? 'flex' : 'initial',
      alignItems: 'center',
      ...datePickerStyle(newStyle),
    }}
  >
    {!!newStyle && <CalendarIcon sx={{ mr: 2, flex: 'none' }} />}
    <DatePicker
      onChange={v => console.log('change', v)}
      locale={getActiveLanguage(useSelector(state => state.localize)).code}
      {...props}
    />
  </Box>
);
