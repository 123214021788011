import { useStaticQuery, graphql } from 'gatsby';

export const useFooterLinks = () => {
  const { customer, corporate } = useStaticQuery(
    graphql`
      query FooterLinks {
        customer: allContentfulLayout(filter: { slug: { in: ["footer", "footeren", "footersv"] } }) {
          nodes {
            node_locale
            contentModules {
              ... on ContentfulLayoutLinkList {
                title
                headline
                links {
                  title
                  url
                  page {
                    ...pageLinkFields
                  }
                }
              }
            }
          }
        }
        corporate: allContentfulLayout(
          filter: { slug: { in: ["footer-corporate", "footeren-corporate", "footersv-corporate"] } }
        ) {
          nodes {
            node_locale
            contentModules {
              ... on ContentfulLayoutLinkList {
                title
                headline
                links {
                  title
                  url
                  page {
                    ...pageLinkFields
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return { customerLinks: customer.nodes, corporateLinks: corporate.nodes };
};
