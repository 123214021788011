import { tint, rgba } from 'polished';

export default {
  background: '#FFFFFF',
  text: '#001E60',
  black: '#1B1F22',
  highlight: tint(0.8, '#9FCFEF'),
  logoNewDarkBlue: '#001e60',
  primary: '#001E60',
  primary80: '#5999E8',
  secondary: '#001E60',
  blueLight: '#9FCFEF',
  blueLighter: '#E5E8EF',
  pink: '#FFB6D9',
  //pinkDark: '#EA5798',
  pinkDark: '#F04E7D',
  salmon: '#E26675',
  green: '#71CC98',
  greenDark: '#005C50',
  greenLighter: tint(0.8, '#71CC98'),
  gray: '#58728C',
  grayLight: '#9EA6B4',
  grayLighter: tint(0.9, '#9EA6B4'),
  errorYellow: '#FFD401',
  paleYellow: '#FEDCA9',
  lightYellow: '#FFF6CC',
  paleRed: '#AE5262',
  newBlue: '#307FE2',
  border: tint(0.5, '#9EA6B4'),
  white: '#FFFFFF',
  danger: '#D0021B',
  error: '#D0021B',
  newGrayLighter: '#ADB3B7',
  lowContrast: '#ffffff',
  hiContrast: '#001E60',

  modes: {
    dark: {
      lowContrast: '#001E60',
      hiContrast: '#ffffff',
    },
  },
};
