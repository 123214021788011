import { capitalize } from './string';

export const formatSize = size => {
  return `${size.height} x ${size.width} x ${size.depth} cm`;
};

export const formatPrice = price => {
  if (typeof price !== 'number') return '';
  return `${price.toFixed(2)}\u00A0€`.replace('.', ',');
};

export const formatTimeWithoutSecond = date => {
  return date.format('HH:mm');
};

export const formatDuration = duration => {
  const dur = duration.split(':');
  if (dur.length < 2) {
    return duration;
  }
  dur[dur.length - 1] = +dur[dur.length - 1] + 'min';
  dur[dur.length - 2] = +dur[dur.length - 2] + 'h';
  return dur.join(' ');
};

export const formatDate = date => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const formatOffice = office => {
  if (!office) return '';
  const { officeName, officeStreetAddress, officePostalCode, officeCity } = office;
  return `${capitalize(officeName)}, ${capitalize(officeStreetAddress)}, ${officePostalCode} ${capitalize(officeCity)}`;
};
