import { useEffect } from 'react';

const inputSelector = '.searchInput';
const hitsSelector = '.hit';

export const useKeyboardNavigation = (containerRef, deps = []) => {
  useEffect(() => {
    const container = containerRef.current;
    const input = container.querySelector(inputSelector);
    if (!containerRef || !input) {
      return;
    }

    let activeIndex = -1;
    const focusListener = () => {
      activeIndex = -1;
    };

    const keydownListener = event => {
      // console.log('keydownListener', event.key);
      const hits = Array.from(container.querySelectorAll(hitsSelector));
      const focusHit = index => {
        if (index === -1) {
          input.focus();
          input.select();
        } else {
          const hit = hits[index];
          hit && hit.focus();
        }
      };

      switch (event.key) {
        case 'Escape':
          activeIndex = -1;
          focusHit(-1);
          return;

        case 'ArrowUp':
          if (activeIndex <= 0) {
            activeIndex = hits.length - 1;
          } else {
            activeIndex--;
          }
          focusHit(activeIndex);
          event.preventDefault();
          event.stopPropagation();
          return;

        case 'ArrowDown':
          if (activeIndex < hits.length - 1) {
            activeIndex++;
          } else {
            activeIndex = 0;
          }
          focusHit(activeIndex);
          event.preventDefault();
          event.stopPropagation();
          return;

        case 'Tab':
          if (event.shiftKey) {
            if (activeIndex > 0) {
              activeIndex--;
              focusHit(activeIndex);
              event.preventDefault();
              event.stopPropagation();
            }
          } else {
            if (activeIndex < hits.length - 1) {
              activeIndex++;
              focusHit(activeIndex);
              event.preventDefault();
              event.stopPropagation();
            }
          }
          return;

        case 'Enter':
          const hit = hits[activeIndex];
          if (hit) {
            hit.click();
          } else {
            activeIndex = 0;
            focusHit(0);
          }
          event.preventDefault();
          event.stopPropagation();
      }
    };

    input.addEventListener('focus', focusListener);
    container.addEventListener('keydown', keydownListener);

    return () => {
      input.removeEventListener('focus', focusListener);
      container.removeEventListener('keydown', keydownListener);
    };
  }, deps);
};
