import * as api from '../utils/api';
import { showNotification } from './notifications';

const initialState = {
  sent: {
    shipments: [],
    hasMore: true,
    isLoading: false,
  },
  received: {
    shipments: [],
    hasMore: true,
    isLoading: false,
  },
  series: {
    shipments: [],
    hasMore: true,
    isLoading: false,
  },
  archived: {},
};

const SET_SHIPMENTS_LOADING = 'SET_SHIPMENTS_LOADING';
const ADD_SHIPMENTS = 'ADD_SHIPMENTS';
const RESET = 'RESET';
const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
const SET_ARCHIVED_SHIPMENTS = 'SET_ARCHIVED_SHIPMENTS';
const SET_SESSION = 'SET_SESSION';

export const loadSeriesShipments = (language = 'fi') => async dispatch => {
  const direction = 'series';
  dispatch({ type: SET_SHIPMENTS_LOADING, direction, isLoading: true });
  let response;
  try {
    response = await api.getMultiParcelHistory(language);
    response.shipments.forEach(s => {
      s.shipmentDate = s.purchaseDate;
      s.direction = 'series';
    });
    response.shipments.sort((a, b) => {
      if (a.shipmentDate < b.shipmentDate) {
        return +1;
      }
      if (a.shipmentDate > b.shipmentDate) {
        return -1;
      } else {
        return 0;
      }
    });
    dispatch({
      ...response,
      hasMore: false,
      direction,
      type: ADD_SHIPMENTS,
    });
  } catch (error) {
    dispatch(showNotification('genericApiError'));
  }
  dispatch({ type: SET_SHIPMENTS_LOADING, direction, isLoading: false });
};

export const loadMoreShipments = direction => async (dispatch, getState) => {
  const state = getState().shipments;
  const { sent, received } = state;
  if (direction === 'any') {
    if (!sent.hasMore) {
      direction = 'received';
    } else if (!received.hasMore) {
      direction = 'sent';
    } else {
      const lastSent = sent.shipments[sent.shipments.length - 1];
      const lastReceived = received.shipments[received.shipments.length - 1];
      if (lastSent && lastReceived && lastSent.shipmentDate < lastReceived.shipmentDate) {
        direction = 'received';
      } else {
        direction = 'sent';
      }
    }
  }

  const { hasMore, isLoading, nextOffset = 0 } = state[direction];
  if (isLoading || !hasMore) {
    return;
  }

  dispatch({ type: SET_SHIPMENTS_LOADING, direction, isLoading: true });
  let response;
  try {
    if (direction === 'sent') {
      response = await api.getSentParcels(nextOffset);
    } else {
      response = await api.getReceivedParcels(nextOffset);
    }
    dispatch({
      ...response,
      direction,
      type: ADD_SHIPMENTS,
    });
  } catch (error) {
    dispatch(showNotification('genericApiError'));
  }
  dispatch({ type: SET_SHIPMENTS_LOADING, direction, isLoading: false });
};

export const reloadShipments = () => dispatch => {
  dispatch({
    type: RESET,
  });
};

export const updateSingleShipment = (direction, shipment) => ({
  type: UPDATE_SHIPMENT,
  direction,
  shipment,
});

export const archiveShipment = shipmentNumber => async dispatch => {
  const response = await api.archiveShipment(shipmentNumber);
  dispatch({
    type: SET_ARCHIVED_SHIPMENTS,
    archived: response.archived,
  });
};

export const archiveShipments = shipmentNumbers => async dispatch => {
  const response = await api.archiveShipments(shipmentNumbers);
  dispatch({
    type: SET_ARCHIVED_SHIPMENTS,
    archived: response.archived,
  });
};

export const unarchiveShipment = shipmentNumber => async dispatch => {
  const response = await api.unarchiveShipment(shipmentNumber);
  dispatch({
    type: SET_ARCHIVED_SHIPMENTS,
    archived: response.archived,
  });
};

export const unarchiveShipments = shipmentNumbers => async dispatch => {
  const response = await api.unarchiveShipments(shipmentNumbers);
  dispatch({
    type: SET_ARCHIVED_SHIPMENTS,
    archived: response.archived,
  });
};

export default (state = initialState, action) => {
  const { type, direction, ...params } = action;
  switch (type) {
    case SET_SHIPMENTS_LOADING:
      return { ...state, [direction]: { ...state[direction], isLoading: params.isLoading } };
    case ADD_SHIPMENTS:
      const { shipments: newShipments, ...other } = params;
      const shipments = [...state[direction].shipments, ...newShipments];
      return { ...state, [direction]: { ...state[direction], shipments, ...other } };
    case RESET:
      return { ...initialState };
    case UPDATE_SHIPMENT:
      const { shipment } = params;
      const allShipments = [...state[direction].shipments];
      const index = allShipments.findIndex(s => s.shipmentNumber === shipment.shipmentNumber);
      if (index !== -1) {
        allShipments.splice(index, 1, shipment);
        return { ...state, [direction]: { ...state[direction], shipments: allShipments } };
      } else {
        return state;
      }
    case SET_ARCHIVED_SHIPMENTS:
      return { ...state, archived: action.archived };
    case SET_SESSION:
      return { ...state, archived: action.user?.archived || [] };
    default:
      return state;
  }
};
