import React, { forwardRef } from 'react';
import { Box } from 'reflexbox';

const Container = forwardRef((props, ref) => (
  <Box
    ref={ref}
    tx="layout"
    variant="container"
    {...props}
    __css={{
      width: '100%',
      maxWidth: 1440,
      px: [3, 4, null, null],
      mx: 'auto',
      ...props.sx,
    }}
  />
));

export default Container;
