import { useStaticQuery, graphql } from 'gatsby';

export const useNavigation = (locale = 'fi') => {
  const node_locale = { fi: 'fi-FI', sv: 'sv-SE', en: 'en-US' }[locale];

  const resp = useStaticQuery(
    graphql`
      query Navigation {
        navigation: allContentfulLayoutLinkList(filter: { slug: { eq: "navigation" } }) {
          edges {
            node {
              node_locale
              links {
                id
                title
                page {
                  ...pageLinkFields
                }
                url
              }
            }
          }
        }
        business: allContentfulLayoutLinkList(filter: { slug: { eq: "business" } }) {
          edges {
            node {
              node_locale
              links {
                id
                title
                page {
                  ...pageLinkFields
                }
                url
              }
            }
          }
        }
        sustainability: allContentfulLayoutLinkList(filter: { slug: { eq: "sustainability" } }) {
          edges {
            node {
              node_locale
              links {
                id
                title
                page {
                  ...pageLinkFields
                }
                url
              }
            }
          }
        }
        matkahuolto: allContentfulLayoutLinkList(filter: { slug: { eq: "matkahuolto" } }) {
          edges {
            node {
              node_locale
              links {
                id
                title
                page {
                  ...pageLinkFields
                }
                url
              }
            }
          }
        }
      }
    `
  );
  const navigation = resp.navigation.edges.find(e => e.node.node_locale === node_locale);
  const business = resp.business.edges.find(e => e.node.node_locale === node_locale);
  const sustainability = resp.sustainability.edges.find(e => e.node.node_locale === node_locale);
  const matkahuolto = resp.matkahuolto.edges.find(e => e.node.node_locale === node_locale);
  const removeBlogIfNoFi = navigation.node.links.filter(l => !(l.title === 'Blog' && node_locale !== 'fi-FI'));
  return {
    navigation: removeBlogIfNoFi,
    business: business.node.links,
    sustainability: sustainability.node.links,
    matkahuolto: (matkahuolto && matkahuolto.node.links) || [],
  };
};
