/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import React, { useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { CaretIcon } from '../Icon';
import { useRef } from 'react';
import { useEffect } from 'react';
import { fetchTrips } from '../../state/favoriteTrips';
import { StarIcon } from '../Icon';

const FavoriteTripDropdownToggle = props => (
  <Flex
    {...props}
    sx={{
      cursor: 'pointer',
      alignItems: 'center',
      color: 'hiContrast',
      mx: 2,
      transition: 'color .25s ease',
      ':hover': {
        color: 'headerHover',
      },

      'svg:last-of-type': {
        transition: 'transform .25s ease',
        transform: props.active ? 'rotate(180deg)' : 'none',
      },
    }}
  >
    {props.children}
    <CaretIcon sx={{ ml: 2 }} />
  </Flex>
);

const FavoriteDropdownMenu = props => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '100%',
      right: 0,
      bottom: 'auto',
      bg: 'white',
      mt: 2,
      borderRadius: 1,
      boxShadow: 'dropdown',
      zIndex: 999,
      overflow: 'hidden',
    }}
  >
    {props.children}
  </Box>
);

const FavoriteDropdownMenuItem = ({ children, ...props }) => (
  <Box
    {...props}
    sx={{
      cursor: 'pointer',
      color: 'secondary',
      ':hover': { bg: 'blueLighter' },
      minWidth: 300,
    }}
  >
    {children}
  </Box>
);
const Trip = ({ trip: { arrival, departure }, ...props }) => {
  return (
    <Flex {...props}>
      <Box sx={{ flex: 1, fontSize: 'small' }}>
        <Box sx={{ fontWeight: 'medium', fontSize: 2 }}>{departure.region}</Box>
        {departure.name}
      </Box>
      <span
        sx={{
          px: 2,
          fontSize: '25px',
          fontWeight: 'medium',
          alignSelf: 'center',
        }}
      >
        &rarr;
      </span>
      <Box sx={{ flex: 1, fontSize: 'small' }}>
        <Box sx={{ fontWeight: 'medium', fontSize: 2 }}>{arrival.region}</Box>
        {arrival.name}
      </Box>
    </Flex>
  );
};

export default props => {
  const translate = getTranslate(useSelector(state => state.localize));
  const favoriteTrips = useSelector(state => state.favoriteTrips);
  const [open, setOpen] = useState(false);
  const node = useRef();
  const dispatch = useDispatch();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (!favoriteTrips || (!favoriteTrips.isLoading && !favoriteTrips.trips)) {
      dispatch(fetchTrips());
    }
  }, [dispatch]);

  if (!favoriteTrips || favoriteTrips.isLoading || !favoriteTrips.trips || !favoriteTrips.trips.length) return <div />;

  return (
    <div ref={node} sx={{ position: 'relative', alignSelf: 'start' }}>
      <FavoriteTripDropdownToggle
        active={open}
        onClick={e => setOpen(!open)}
        theme={props.theme}
        sx={{
          color: 'white',
          borderRadius: 0,
          ':hover': { color: 'white' },
          p: 0,
        }}
      >
        <StarIcon sx={{ width: 20, height: 20, mr: 1 }} />
        {translate('account.favoriteTrips.title')}
      </FavoriteTripDropdownToggle>
      {open && favoriteTrips && favoriteTrips.trips && (
        <FavoriteDropdownMenu open={open}>
          {favoriteTrips.trips.map((trip, i) => (
            <FavoriteDropdownMenuItem
              key={trip.id}
              onClick={e => {
                if (props.onTripSelected) props.onTripSelected(trip);
                setOpen(false);
              }}
            >
              <Trip
                trip={trip}
                sx={{
                  flex: 1,
                  borderBottom: i !== favoriteTrips.trips.length - 1 ? '1px solid' : '',
                  borderBottomColor: i !== favoriteTrips.trips.length - 1 ? 'border' : '',
                  px: 3,
                  py: 2,
                }}
              />
            </FavoriteDropdownMenuItem>
          ))}
        </FavoriteDropdownMenu>
      )}
    </div>
  );
};
