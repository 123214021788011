/** @jsx jsx */
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { jsx, Flex, Box, Styled } from 'theme-ui';
import { Button, FullHeightColumn, Link } from '../components';
import { locNavigate } from '../state/session';
import { reloadShipments } from '../state/shipments';
import { useTranslate } from '../utils/getLanguage';

export default ({ order }) => {
  const dispatch = useDispatch();
  const { shipmentNumber, product = {}, services } = order?.shipments?.[0] || {};
  const translate = useTranslate();
  useEffect(() => {
    dispatch(reloadShipments());
  }, [dispatch]);

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('upgrade.ready.title')}</Styled.h1>
      <Box>
        {translate('upgrade.ready.description1')}{' '}
        <Link to={`/my-pages/sent-parcels?sn=${shipmentNumber}`}>{shipmentNumber}</Link>{' '}
        {translate('upgrade.ready.description2', {
          newSize: product.sizeCode + (product.weightClass > 30 ? ` ${product.weightClass} kg` : ''),
        })}
      </Box>
    </FullHeightColumn>
  );
};
