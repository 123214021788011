/** @jsx jsx */
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { jsx, Flex, Input } from 'theme-ui';

import { locNavigate } from '../state/session';

import { Button, Spinner } from '.';
import { hideAllNotifications } from '../state/notifications';
import { ArrowIcon } from './Icon';
import { findTrackingInfo } from '../state/tracking';

export default function TrackingSearch(props) {
  const [input, setInput] = useState(props.input || '');
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const trackingState = useSelector(state => state.tracking);

  const handleSearch = useCallback(
    e => {
      e.preventDefault();
      dispatch(hideAllNotifications());
      dispatch(findTrackingInfo(input, props?.inlineTracking));
      if (!props?.inlineTracking) {
        dispatch(locNavigate('/seuranta', '?parcelNumber=' + input));
      }
    },
    [props, input]
  );

  function onInputChange(e) {
    const { value } = e.target;
    setInput(value.replace(/[^A-z0-9]/g, ''));
  }

  return (
    <form onSubmit={handleSearch} sx={{ flex: 1 }}>
      <Flex
        sx={{
          alignItems: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        <Input
          placeholder={translate('tracking.search.label')}
          value={input}
          onChange={onInputChange}
          sx={{
            py: 12,
            px: 3,
          }}
        />
        <Button
          onClick={handleSearch}
          sx={{ color: 'gray', p: 0, position: 'absolute', right: 3 }}
          title={translate('tracking.search.button')}
          variant="plain"
        >
          {trackingState.fetching ? (
            <div sx={{ mr: 4 }}>
              <Spinner size="medium" />
            </div>
          ) : (
            <ArrowIcon />
          )}
        </Button>
      </Flex>
    </form>
  );
}
