import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from '.';
import immutable from 'redux-immutable-state-invariant';

const isDevelopment = process.env['NODE_ENV'] !== 'production';
const initialState = {};
const middleware = [thunk];
if (isDevelopment) {
  middleware.unshift(immutable({ ignore: ['router.location.action'] }));
}
const compose_ = isDevelopment ? composeWithDevTools : compose;
const composedEnhancers = compose_(applyMiddleware(...middleware));
export const store = createStore(rootReducer, initialState, composedEnhancers);

export default ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
