import * as api from '../utils/api';

const initialState = {
  isLoading: false,
  trips: null,
};

const SET_TRIPS_LOADING = 'SET_TRIPS_LOADING';
const SET_TRIPS = 'SET_TRIPS';

export const fetchTrips = () => async dispatch => {
  dispatch({ type: SET_TRIPS_LOADING, isLoading: true });
  try {
    const trips = await api.getFavoriteTrips();
    dispatch({
      type: SET_TRIPS,
      trips,
    });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({ type: SET_TRIPS_LOADING, isLoading: false });
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRIPS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_TRIPS:
      return { ...state, trips: action.trips };
    default:
      return state;
  }
};
