import React from 'react';
import { BookingWidget, TrackingSearchWidget, CallToActionWidget, MultiparcelWidget, Widget } from '.';
import ImageWidget from './ImageWidget';

export default ({ widgets, sidebar = false }) => {
  if (!widgets?.length) return null;
  const url = typeof window !== 'undefined' ? window.location.url : '';
  let contentfulWidgets = [];
  widgets.map(widget => {
    switch (widget.type) {
      case 'Timetable Search':
        contentfulWidgets.push(<BookingWidget key={widget.contentful_id} sidebar={sidebar} />);
        break;
      case 'Parcel Tracking':
        contentfulWidgets.push(<TrackingSearchWidget key={widget.contentful_id} sidebar={sidebar} />);
        break;
      case 'Multiparcel':
        contentfulWidgets.push(<MultiparcelWidget data={widget} key={widget.contentful_id} sidebar={sidebar} />);
        break;
      case 'Image':
        contentfulWidgets.push(<ImageWidget content={widget} key={widget.contentful_id} sidebar={sidebar} />);
        break;
      default:
        if (widget.ctaLink !== url) {
          contentfulWidgets.push(<CallToActionWidget content={widget} key={widget.contentful_id} sidebar={sidebar} />);
        }
    }
  });
  return contentfulWidgets;
};
