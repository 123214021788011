/** @jsx jsx */
import { jsx } from 'theme-ui';
import SearchWidget from '../components/searchwidget';
import { useStaticQuery, graphql } from 'gatsby';

const searchIndices = [
  {
    name: `${process.env.GATSBY_ALGOLIA_SEARCH_INDEX}`,
    title: `Pages`,
    hitComp: `PageHitOld`,
  },
];

const Search = props => {
  const data = useStaticQuery(
    graphql`
      query AllContentfulPagesQueryForSearch {
        allContentfulPage {
          edges {
            node {
              contentful_id
              slug
              node_locale
              parentPage {
                ... on ContentfulLayout {
                  slug
                }
                ... on ContentfulPage {
                  slug
                  parentPage {
                    ... on ContentfulLayout {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <SearchWidget
      indices={searchIndices}
      edges={data.allContentfulPage.edges}
      theme={props.theme}
    />
  );
};

export default Search;
