import React, { useState } from 'react';

export const SearchContext = React.createContext({
  searchTerm: '',
  setSearchTerm: () => {},
});

export const SearchStore = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');  
  return (
    <SearchContext.Provider 
        value={{ 
            searchTerm,
            setSearchTerm }}
    >
        {children}
    </SearchContext.Provider>
  );
};
