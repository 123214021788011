export const combineProps = (product, services = []) => {
  if (!services) return product;
  const ret = { ...product };
  for (const s of services) {
    Object.assign(ret, s.override);
  }
  return ret;
};

export default combineProps;
