export default {
  root: {
    m: 0,
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
    color: 'secondary',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  h1: {
    variant: 'text.heading',
    fontSize: 6,
  },
  h2: {
    variant: 'text.heading',
    fontWeight: 'semibold',
    fontSize: 5,
  },
  h3: {
    variant: 'text.heading',
    fontSize: 4,
  },
  h4: {
    variant: 'text.heading',
    fontSize: 3,
  },
  h5: {
    variant: 'text.heading',
    fontSize: 2,
  },
  h6: {
    variant: 'text.heading',
    fontSize: 1,
  },
  p: {
    fontSize: [2, null, 17],
    m: 0,
    mb: '1.5rem',
    color: 'black',
  },
  a: {
    color: 'primary',
    textDecoration: 'underline',
    '&:hover': {
      color: 'secondary',
    },
    '&:visited': {
      color: 'primary',
    },
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  hr: {
    mt: 4,
    mb: 4,
    border: 0,
    borderBottom: '1px solid',
    borderColor: 'border',
  },
  strong: {
    fontWeight: 'medium',
  },
  ul: {
    p: {
      mb: 0,
    },
  },
  ol: {
    counterReset: 'my-awesome-counter',
    listStyle: 'none',
    pl: 40,
    li: {
      counterIncrement: 'my-awesome-counter',
      position: 'relative',
      '::before': {
        content: 'counter(my-awesome-counter)',
        position: 'absolute',
        color: 'white',
        bg: 'secondary',
        lineHeight: '28px',
        fontWeight: 'medium',
        borderRadius: 3,
        minWidth: 28,
        height: 28,
        textAlign: 'center',
        left: -40,
      },
    },
  },
  table: {
    width: '100%',
    my: 4,
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderColor: 'border',
  },
  th: {
    textAlign: 'left',
    verticalAlign: 'bottom',
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 2,
    paddingLeft: 0,
    borderColor: 'inherit',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
  },
  td: {
    textAlign: 'left',
    verticalAlign: 'top',
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 2,
    paddingLeft: 0,
    borderColor: 'inherit',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
  input: {
    fontSize: 2,
  },
  Container: {
    maxWidth: 1600,
    px: [4, 5, 6, 7],
  },
  Footer: {
    h4: {
      color: 'white',
      fontWeight: 'medium',
    },
    a: {
      color: 'white',
      ':hover': {
        opacity: 0.5,
      },
    },
  },
};
