import { showNotificationWithoutTranslation } from '../state/notifications';

export const handleFormValidationError = (error, dispatch, translate) => {
  if (error.response && error.response.status === 400) {
    const errRegexp = /^\[\d+\] (.*$)/; // "[1] Email is not valid"
    const messages = error.response.data
      .split('\n')
      .map(s => (errRegexp.exec(s) || [])[1])
      .filter(s => !!s);
    if (messages.length > 0) {
      dispatch(showNotificationWithoutTranslation(messages.join(' ')));
      return true;
    }
  }
  if (error.name === 'FileSizeError') {
    dispatch(showNotificationWithoutTranslation(translate('feedback.message.sizeLimit', { size: '5.5MB' })));
    return true;
  }
  return false;
};
