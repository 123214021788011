/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import TooltipTrigger from 'react-popper-tooltip';

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
  <TooltipTrigger
    trigger={['click', 'hover']}
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <Flex
        {...getTooltipProps({
          ref: tooltipRef,
        })}
        sx={{
          bg: 'secondary',
          color: 'white',
          borderRadius: 1,
          border: 'none',
          boxShadow: 'dropdown',
          py: 12,
          px: 3,
          m: 2,
          transition: 'opacity .25s',
          zIndex: 389383,
          fontFamily: 'body',
          fontSize: 1,
          maxWidth: ['80vw', '360px'],
        }}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              'data-placement': placement,
            })}
            sx={{
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 0,
              height: 0,
              margin: 'auto',
              borderStyle: 'solid',
              borderWidth: 6,
              borderColor: 'transparent',
              '&[data-placement*="right"]': {
                left: -12,
                borderRightColor: 'secondary',
              },
              '&[data-placement*="left"]': {
                right: -12,
                borderLeftColor: 'secondary',
              },
              '&[data-placement*="top"]': {
                bottom: -12,
                borderTopColor: 'secondary',
              },
              '&[data-placement*="bottom"]': {
                top: -12,
                borderBottomColor: 'secondary',
              },
            }}
          />
        )}
        {tooltip}
      </Flex>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

export default Tooltip;
