/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import React, { useState, useCallback, Component, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import AsyncSelect from 'react-select/async';
import Button from '../Button';
import { DateInput } from '../DateField';
import Widget from './Widget';
import { autocompleteBusLocation } from '../../utils/api';
import { formatDate } from '../../utils/formatters';
import getLanguage from '../../utils/getLanguage';
import Spinner from '../Spinner';
import { CalendarIcon, CaretIcon, ArrowIcon } from '../Icon';
import moment from 'moment';
import FavoriteTripDropdownButton from './FavoriteTripDropdown';

// this is react component instead of function because annoying warning message in browser console caused by <DateInput customInput=... />
class DateButton extends Component {
  render() {
    const { value, onClick, translate } = this.props;
    return (
      <Button
        variant="plain"
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          color: 'white',
          borderRadius: 0,
          ':hover': { color: 'white' },
        }}
        onClick={onClick}
      >
        <CalendarIcon sx={{ mr: 2, flex: 'none' }} />
        <Box>{value ? value : moment().format('DD.MM.YYYY')}</Box>
        <CaretIcon sx={{ flex: 'none', ml: 1 }} />
      </Button>
    );
  }
}

export default ({ showFavoriteTrips = true, frontPage = false, ...props }) => {
  const language = useSelector(state => getLanguage(state));
  const hasUser = useSelector(state => !!(state.session && state.session.user));
  const { theme } = useThemeUI();

  const [departurePlaceId, setDepartureId] = useState();
  const selectDeparturePlace = useCallback(
    v => {
      if (!v) {
        return;
      }
      setDepartureId(v.id);
      destinationRef.current.focus();
      return `${v.name} (${v.region})`;
    },
    [setDepartureId]
  );

  const [arrivalPlaceId, setArrivalId] = useState();
  const selectArrivalPlace = useCallback(
    v => {
      if (!v) {
        return;
      }
      setArrivalId(v.id);
      return `${v.name} (${v.region})`;
    },
    [setArrivalId]
  );

  const [departureDate, setDepartureDate] = useState();
  const selectDate = useCallback(
    v => {
      setDepartureDate(v);
    },
    [setDepartureDate]
  );

  const redirectToTicketService = useCallback(() => {
    let url = process.env.GATSBY_TICKET_API_URL + '/timetable?';
    url += `lang=${language}`;
    url += `&departurePlaceId=${departurePlaceId || ''}`;
    url += `&arrivalPlaceId=${arrivalPlaceId || ''}`;
    url += `&departureDate=${formatDate(departureDate)}`;
    // url += `&returnDate=${returnDate || ''}`;
    window.location.assign(url);
  });

  const translate = getTranslate(useSelector(state => state.localize));
  const selectStyles = {
    container: base => ({
      ...base,
      position: 'static',
      width: '100%',
      left: 0,
    }),
    control: base => ({
      ...base,
      position: 'static',
      border: 'none',
      boxShadow: 'none',
    }),
    indicatorsContainer: base => ({ display: 'none' }),
    singleValue: (base, { selectProps: { alignRight } }) => ({
      ...base,
      right: alignRight ? '0' : 'none',
    }),
    placeholder: (base, { selectProps: { alignRight } }) => ({
      ...base,
      right: alignRight ? theme.space[2] : 'none',
      color: theme.colors.gray,
    }),
    menu: base => ({
      ...base,
      left: 0,
      paddingTop: 8,
      paddingBottom: 8,
    }),
    option: (base, state) => ({
      ...base,
      color: '#001E60',
      backgroundColor: state.isFocused ? '#E5E8EF' : 'none',
      ':hover': { backgroundColor: '#E5E8EF' },
    }),
  };

  const handleTripSelected = useCallback(
    trip => {
      if (!trip.departure || !trip.arrival) return;
      let url = process.env.GATSBY_TICKET_API_URL + '/timetable?';
      url += `lang=${language}`;
      url += `&departurePlaceId=${trip.departure.id || ''}`;
      url += `&arrivalPlaceId=${trip.arrival.id || ''}`;
      url += `&departureDate=${formatDate(departureDate)}`;
      // url += `&returnDate=${returnDate || ''}`;
      window.location.assign(url);
    },
    [language, departureDate]
  );

  const destinationRef = useRef(null);

  return (
    <Widget
      title={translate('timeTableWidget.title')}
      action={hasUser && showFavoriteTrips && <FavoriteTripDropdownButton onTripSelected={handleTripSelected} />}
      {...props}
    >
      <Box></Box>
      <Box>
        <Flex
          sx={{
            position: 'relative',
            bg: 'white',
            borderRadius: 1,
            height: 48,
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.colors.gray,
          }}
        >
          <AsyncSelect
            aria-label={translate('timeTableWidget.from')}
            placeholder={translate('timeTableWidget.from')}
            styles={selectStyles}
            isClearable={true}
            loadOptions={term => autocompleteBusLocation(term, language)}
            getOptionLabel={opt => {
              return `${opt.name} (${opt.region})`;
            }}
            isOptionSelected={option => departurePlaceId === option.id}
            onChange={selectDeparturePlace}
            noOptionsMessage={() => null}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
          />
          <ArrowIcon
            sx={{
              display: 'block',
              flex: 'none',
              px: 1,
              boxSizing: 'content-box',
            }}
          />
          <AsyncSelect
            aria-label={translate('timeTableWidget.to')}
            placeholder={translate('timeTableWidget.to')}
            ref={destinationRef}
            styles={selectStyles}
            loadOptions={term => autocompleteBusLocation(term, language)}
            getOptionLabel={opt => {
              return `${opt.name} (${opt.region})`;
            }}
            isOptionSelected={option => departurePlaceId === option.id}
            onChange={selectArrivalPlace}
            noOptionsMessage={() => null}
            alignRight={true}
            loadingMessage={() => (
              <Box sx={{ py: 2 }}>
                <Spinner />
              </Box>
            )}
          />
        </Flex>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3,
            flexWrap: 'wrap',
          }}
        >
          <DateInput
            selected={departureDate}
            onChange={selectDate}
            disabledKeyboardNavigation
            dateFormat="dd.MM.yyyy"
            customInput={<DateButton translate={translate} />}
            sx={{ flex: 'auto' }}
          />
          <Button
            variant="secondary"
            onClick={redirectToTicketService}
            sx={{
              bg: frontPage ? 'newBlue' : undefined,
              color: frontPage ? 'white' : undefined,
              border: frontPage ? 0 : undefined,
            }}
          >
            {translate('timeTableWidget.search')}
          </Button>
        </Flex>
      </Box>
    </Widget>
  );
};
