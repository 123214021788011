import React, { useEffect, useContext } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchContext } from './SearchContext';
import { Input } from 'theme-ui';

const AlgoliaSearchBox = connectSearchBox(
  ({ currentRefinement, refine, value, ...rest }) => {
    const { setSearchTerm } = useContext(SearchContext);
    useEffect(() => {
      if (value.length >= 3 && value !== currentRefinement) {
        setSearchTerm(value);
        refine(value);
      }
    }, [refine, value, currentRefinement, setSearchTerm]);
    /*const handleChange = (e) => {    
    const val = e.target.value;
    // do not search when there is not enough characters
    if (val.length < 3) return;
    refine(e.target.value);
  };*/
    return <Input type="hidden" placeholder="Hae" aria-label="Search" />;
  }
);

export default AlgoliaSearchBox;
