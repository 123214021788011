/** @jsx jsx */
import { jsx, Box, Label } from 'theme-ui';

const CheckboxFieldGroup = ({ children, width, ...props }) => {
  return (
    <Box sx={{ mb: 3, width }}>
      {props.label && <Label htmlFor={props.name}>{props.label}</Label>}
      {children}
      {props.error && <div style={{ color: 'red' }}>{props.error}</div>}
    </Box>
  );
};

export default CheckboxFieldGroup;
