/** @jsx jsx */
import { jsx, Flex, Radio, Label } from 'theme-ui';

const RadioField = ({ field, value, id, label, ...props }) => {
  return (
    <Flex sx={{ my: 2 }}>
      <Label sx={{ fontWeight: 'normal', m: 0 }} htmlFor={id}>
        <Radio
          name={field.name}
          id={id}
          value={value || id}
          checked={(value || id) === field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
        {label}
      </Label>
    </Flex>
  );
};

export default RadioField;
