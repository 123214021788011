import { getTranslate } from 'react-localize-redux';
import { putItem as putItemToBasket } from './basket';
import { updateShipment } from './series';
import { locNavigate } from '../state/session';
import * as catalog from './catalog';
import * as api from '../utils/api';
import { CountryCode } from '../utils/countryCode';
import { useSelector } from 'react-redux';
import { isLargeBalticParcel } from '../utils/shipment';
const { ServiceCode } = catalog;

export const abroadEnabled = true; // !['production'].includes(process.env.GATSBY_ACTIVE_ENV);

export const PAGE = {
  PARCEL_TYPE: 13,
  SELECT_COUNTRY: 1,
  SELECT_PRODUCT: 2,
  SELECT_EXTRAS: 3,
  SELECT_DELIVERY: 11,
  RECIPIENT: 4,
  PICKUP_POINT: 5,
  ADD_TO_BASKET: 6,
  EMPTY_WAIT_PAGE: 7,
  SELECT_REPACK_OFFICE: 8,
  ASK_CONTENTS: 9,
  ASK_CONTENTS_ALAND: 10,
  UPDATE_SERIES_SHIPMENT: 12,
  SELECT_BUS_LINE: 14,
  EXPRESS_PACKAGE_BACKUP_ADDRESS: 15,
};

export const ParcelType = {
  Domestic: 'domestic',
  Abroad: 'abroad',
  Express: 'express',
  MultiParcel: 'multiParcel',
};

// todo: list countries with pickup point search
export const askPickupPointForCountry = country => true;
/*  [
    CountryCode.Finland,
    CountryCode.Aland,
    CountryCode.Sweden,
    CountryCode.Denmark,
    CountryCode.Germany,
    CountryCode.Estonia,
    CountryCode.Latvia,
    CountryCode.Lithuania,
  ].includes(country); */

export const askContents = country => [CountryCode.Russia].includes(country);
export const askContentsAland = country => [CountryCode.Aland].includes(country);

// todo serviceCode for toimitus sovittuna ajankohtana
export const itemHasHomeDelivery = item => {
  return (
    ((item.product || {}).deliveryIncluded && !['FI', 'AX'].includes(item.country)) ||
    !!(item.services || []).find(s =>
      [ServiceCode.DeliveryToMailBox, ServiceCode.Delivery8_16, ServiceCode.DeliveryAgreedTime].includes(s.serviceCode)
    ) ||
    [ServiceCode.Delivery8_16, ServiceCode.DeliveryAgreedTime].includes(item.delivery) ||
    isLargeBalticParcel(item.product)
  );
};

export const askDeliveryRemarks = item =>
  !!(item.services || []).find(s => s.serviceCode === catalog.ServiceCode.Delivery8_16) ||
  (item.product.deliveryIncluded && !['FI', 'AX'].includes(item.country)) ||
  item.delivery === catalog.ServiceCode.Delivery8_16;

export const askPackageCount = (product, buySeries = false) => {
  return !buySeries && (product || {}).sizeCode === catalog.SizeCode.TIRESET;
};

const initialState = {
  page: PAGE.PARCEL_TYPE,
  country: 'FI',
  isLarge: null,
  product: null,
  services: [],
  delivery: null,
  recipient: {},
  sender: {},
  departurePoint: {},
  pickupPoint: {},
  rowId: null,
  seriesId: null, // when using single shipment in series
  buySeries: false, // when buying 10xshipment
  parcelType: null,
  jys: {},
};

const rowIdSelector = state => state.parcelFlow.rowId;
export const useRowId = () => useSelector(rowIdSelector);

const freePickupSelector = state => {
  const { product, services } = state.parcelFlow;
  return product?.pickupIncluded || !!services.find(sv => sv.serviceCode === ServiceCode.HeavyWithDelivery);
};
export const useCurrentItemHasFreePickup = () => useSelector(freePickupSelector);

export const parcelCantBeSentToAutomat = state => {
  const { product } = state;
  if (!product) {
    return state.isLarge;
  }
  return product.isHeavy || product.isLarge;
};

const RESET_FLOW = 'RESET_FLOW';
const SET_PARLCEL_TYPE = 'SET_PARLCEL_TYPE';
const SET_COUNTRY = 'SET_COUNTRY';
const SELECT_PAGE = 'SELECT_PAGE';
const SELECT_LARGE = 'SELECT_LARGE';
const SELECT_PRODUCT = 'SELECT_PRODUCT';
const SELECT_PACKAGE_COUNT = 'SELECT_PACKAGE_COUNT';
const SET_REPACK_CODE = 'SET_REPACK_CODE';
const SELECT_SERVICE = 'SELECT_SERVICE';
const UNSELECT_SERVICE = 'UNSELECT_SERVICE';
const SELECT_DELIVERY_METHOD = 'SELECT_DELIVERY_METHOD';
const SAVE_RECIPIENT = 'SAVE_RECIPIENT';
const SELECT_BUS_LINE = 'SELECT_BUS_LINE';
const SELECT_PICKUPPOINT = 'SELECT_PICKUPPOINT';
const SELECT_REPACKPOINT = 'SELECT_REPACKPOINT';
const SAVE_CONTENT = 'SAVE_CONTENT'; // Customs
const SAVE_CONTENTS = 'SAVE_CONTENTS'; // Åland simple description
const UPDATE_ITEM_IN_BASKET = 'UPDATE_ITEM_IN_BASKET';
const USE_SERIES_SHIPMENT = 'USE_SERIES_SHIPMENT';
const END_SERIES_SHIPMENT_UPDATE = 'END_SERIES_SHIPMENT_UPDATE';
const BUY_SERIES_SHIPMENT = 'BUY_SERIES_SHIPMENT';
const SET_JYS_DELIVERY = 'SET_JYS_DELIVERY';
const SET_UNATTENDED_DELIVERY = 'SET_UNATTENDED_DELIVERY';

export const resetParcelFlow = () => ({
  type: RESET_FLOW,
});

export const reverseOrderForRecipient = (country, sizeCode) => {
  return false;
  // return country === CountryCode.Finland && (sizeCode || '').includes('XXS') && catalog.xxsDeliveryEnabled;
};

export const getPageOrder = (parcelType, country = 'FI', item = {}, isSeriesUsage = false) => {
  if (item.buySeries || parcelType === ParcelType.MultiParcel) {
    return [
      PAGE.PARCEL_TYPE,
      PAGE.SELECT_PRODUCT,
      // PAGE.SELECT_EXTRAS, // +5kg with delivery included has issues
      PAGE.ADD_TO_BASKET,
    ];
  }

  if (parcelType === ParcelType.Express) {
    return [
      PAGE.PARCEL_TYPE,
      PAGE.SELECT_PRODUCT,
      PAGE.SELECT_BUS_LINE,
      PAGE.RECIPIENT,
      PAGE.EXPRESS_PACKAGE_BACKUP_ADDRESS,
      PAGE.ADD_TO_BASKET,
    ];
  }

  const { sizeCode, services, deliveryIncluded } = item.product || {};
  const selectionProps = { ...(item.product || {}) };
  (item.services || []).forEach(s => Object.assign(selectionProps, s.override));
  const hiddenServices = [ServiceCode.PickUp];
  if (deliveryIncluded) {
    if (!isLargeBalticParcel(item.product)) hiddenServices.push(ServiceCode.DeliveryAgreedTime);
    else hiddenServices.push(ServiceCode.Delivery8_16);
  }
  const sservices = (services || []).filter(s => !hiddenServices.includes(s.serviceCode));

  const disablePickupPoint = ['RU'];
  const pages = isSeriesUsage
    ? [
        PAGE.PARCEL_TYPE,
        selectionProps.deliveryIncluded && ['FI', 'AX'].includes(country) ? PAGE.SELECT_DELIVERY : null,
        PAGE.RECIPIENT,
        !itemHasHomeDelivery(item) && !disablePickupPoint.includes(country) ? PAGE.PICKUP_POINT : null,
        PAGE.UPDATE_SERIES_SHIPMENT,
      ]
    : [
        PAGE.PARCEL_TYPE,
        parcelType === ParcelType.Abroad ? PAGE.SELECT_COUNTRY : null,
        PAGE.SELECT_PRODUCT,
        sizeCode === catalog.SizeCode.REPACK ? PAGE.SELECT_REPACK_OFFICE : null,
        !services || sservices.length > 0 ? PAGE.SELECT_EXTRAS : null,
        selectionProps.deliveryIncluded && ['FI', 'AX'].includes(country) ? PAGE.SELECT_DELIVERY : null,
        askContents(country) ? PAGE.ASK_CONTENTS : null,
        PAGE.RECIPIENT,
        askPickupPointForCountry(country) && !itemHasHomeDelivery(item) && !disablePickupPoint.includes(country)
          ? PAGE.PICKUP_POINT
          : null,
        askContentsAland(country) ? PAGE.ASK_CONTENTS_ALAND : null,
        PAGE.ADD_TO_BASKET,
      ];

  if (reverseOrderForRecipient(country, sizeCode)) {
    // XXS jakelu: ask recipient address before extra services
    const index1 = pages.indexOf(PAGE.SELECT_EXTRAS);
    const index2 = pages.indexOf(PAGE.RECIPIENT);
    if (index1 !== -1 && index1 < index2) {
      pages.splice(index2, 1); // remove
      pages.splice(index1, 0, PAGE.RECIPIENT); // insert
    }
  }
  return pages.filter(p => !!p);
};

export const goBack = () => async (dispatch, getState) => {
  const state = getState().parcelFlow;
  const { page, seriesId, ...item } = state;
  const pageOrder = getPageOrder(state.parcelType, state.country, item, !!seriesId);
  const curPageIndex = pageOrder.indexOf(state.page);
  const prevPage = curPageIndex > 0 ? pageOrder[curPageIndex - 1] : state.page - 1;

  if (prevPage === PAGE.SELECT_COUNTRY && [ParcelType.Domestic, ParcelType.Express].includes(item.parcelType)) {
    dispatch({
      type: SELECT_PAGE,
      page: PAGE.PARCEL_TYPE,
    });
  } else if (seriesId && curPageIndex === 0) {
    dispatch({
      type: END_SERIES_SHIPMENT_UPDATE,
    });
    dispatch(locNavigate('/sarjapaketti', `?productId=${state.seriesId}`));
  } else {
    dispatch({
      type: SELECT_PAGE,
      page: prevPage,
    });
  }
};

export const goNext = () => async (dispatch, getState) => {
  const state = getState().parcelFlow;
  const { page, seriesId, isUnused, ...item } = state;

  const pageOrder = getPageOrder(state.parcelType, state.country, item, !!seriesId);
  const curPageIndex = pageOrder.indexOf(page);
  const nextPage = curPageIndex >= 0 ? pageOrder[curPageIndex + 1] : state.page + 1;

  if (nextPage === PAGE.SELECT_COUNTRY && [ParcelType.Domestic, ParcelType.Express].includes(item.parcelType)) {
    dispatch({
      type: SELECT_PAGE,
      page: PAGE.SELECT_PRODUCT,
    });
  } else if (nextPage === PAGE.ADD_TO_BASKET) {
    await putItemToBasket(item)(dispatch, getState);
    dispatch(locNavigate('/ostoskori'));
  } else if (nextPage === PAGE.UPDATE_SERIES_SHIPMENT) {
    await updateShipment(seriesId, item, isUnused)(dispatch, getState);
    dispatch({
      type: END_SERIES_SHIPMENT_UPDATE,
    });
    dispatch(
      locNavigate('/sarjapaketti', `?productId=${state.seriesId}`, {
        state: { expandedShipment: state.shipmentNumber },
      })
    );
    return;
  } else {
    dispatch({
      type: SELECT_PAGE,
      page: nextPage,
    });
  }
};

export const selectParcelType = parcelType => {
  switch (parcelType) {
    case ParcelType.Abroad:
      return { type: SET_PARLCEL_TYPE, buySeries: false, parcelType, country: '', connection: null };
    case ParcelType.Express:
      return { type: SET_PARLCEL_TYPE, buySeries: false, parcelType, country: 'FI' };
    case ParcelType.MultiParcel:
      return { type: SET_PARLCEL_TYPE, buySeries: true, parcelType, country: 'FI', connection: null };
    case ParcelType.Domestic:
    default:
      return { type: SET_PARLCEL_TYPE, buySeries: false, parcelType, country: 'FI', connection: null };
  }
};

export const combineExpressService = (product, translate) => {
  const { services, displayName, price, priceVat0 } = product;
  const expService = services?.find(sv => sv.serviceCode === 'EXP');
  if (!expService) return;
  const express = translate('buyParcel.express.name');
  const obj = {
    ...product,
    baseName: displayName,
    displayName: express + ' ' + displayName,
    price: price + expService.price,
    priceVat0: priceVat0 + expService.priceVat0,
  };
  if (obj.localizedName) {
    obj.localizedName = { ...obj.localizedName };
    Object.keys(obj.localizedName).forEach(k => {
      obj.localizedName[k] = express + ' ' + obj.localizedName[k];
    });
  }
  return obj;
};

export const selectLarge = ({ isLarge }) => ({
  type: SELECT_LARGE,
  isLarge,
});

export const selectProduct = (product, buySeries = false) => ({
  type: SELECT_PRODUCT,
  product: buySeries ? catalog.multiParcelProduct(product) : product,
});

export const selectPackageCount = packages => ({
  type: SELECT_PACKAGE_COUNT,
  packages: +packages,
});

export const checkRePackCode = repackCode => async (dispatch, getState) => {
  repackCode = repackCode.trim();
  if (repackCode.length < 5) {
    return 'UKNOWN_CODE';
  }

  const {
    basket: { items },
    parcelFlow: { rowId },
  } = getState();
  const alreadyInBasket = items.find(i => i.repackCode === repackCode);
  if (alreadyInBasket && alreadyInBasket.rowId !== rowId) {
    return 'ALREADY_IN_BASKET';
  }

  const response = await api.checkRepackCode(repackCode);
  if (typeof response === 'string') {
    return response; // error code
  } else {
    dispatch({
      type: SET_REPACK_CODE,
      repackCode,
    });
  }
};

export const toggleService = service => (dispatch, getState) => {
  const selectedServices = getState().parcelFlow.services;
  const isSelected = !!selectedServices.find(s => s.serviceCode === service.serviceCode);
  if (isSelected) {
    dispatch({
      type: UNSELECT_SERVICE,
      serviceCode: service.serviceCode,
    });
  } else {
    dispatch({
      type: SELECT_SERVICE,
      service,
    });
  }
};

export const selectDeliveryMethod = serviceCode => ({
  type: SELECT_DELIVERY_METHOD,
  delivery: serviceCode,
});

export const checkIfJysDeliveryPossible = recipient => async (dispatch, getState) => {
  const response = await api.checkJysDelivery(recipient);
  await dispatch({
    type: SET_JYS_DELIVERY,
    jys: response,
  });
};

export const saveRecipient = recipient => ({
  recipient,
  type: SAVE_RECIPIENT,
});

export const selectConnection = connection => ({
  connection,
  type: SELECT_BUS_LINE,
});

export const selectPickupPoint = pickupPoint => ({
  pickupPoint,
  type: SELECT_PICKUPPOINT,
});

export const selectRePackPoint = repackPoint => ({
  repackPoint,
  type: SELECT_REPACKPOINT,
});

export const saveContent = content => ({
  content,
  type: SAVE_CONTENT,
});

export const saveContents = contents => ({
  contents,
  type: SAVE_CONTENTS,
});

export const updateItemInBasket = item => (dispatch, getState) => {
  if (item.parcelType === ParcelType.Express) {
    const translate = getTranslate(getState().localize);
    dispatch({
      ...item,
      product: combineExpressService(item.product, translate),
      type: UPDATE_ITEM_IN_BASKET,
    });
  } else {
    dispatch({
      ...item,
      type: UPDATE_ITEM_IN_BASKET,
    });
  }

  if (item.buySeries || item.parcelType === ParcelType.MultiParcel) {
    dispatch(locNavigate('/osta-sarjapaketti'));
  } else {
    dispatch(locNavigate('/osta-paketti'));
  }
};

export const buySeriesShipment = (buySeries = true, productCode = null, country = 'FI') => async (
  dispatch,
  getState
) => {
  if (getState().parcelFlow.buySeries !== buySeries) {
    let product;
    if (productCode !== null) {
      if (country === 'FI') {
        let {
          catalog: { products },
        } = getState();
        if (!products || products.length === 0) {
          products = await dispatch(catalog.getProducts());
        }
        product = products.find(p => p.id === productCode);
      } else {
        const {
          catalog: { country: catCountries },
        } = getState();
        let products = catCountries[country];
        if (!products || products.length === 0) {
          products = await dispatch(catalog.loadProductsForCountry(country));
        }
        product = products.find(p => p.id === productCode);
      }
      const seriesProduct = catalog.multiParcelProduct(product);

      if (seriesProduct) {
        dispatch(locNavigate('/osta-sarjapaketti'));
        dispatch({
          type: BUY_SERIES_SHIPMENT,
          parcelType: buySeries ? ParcelType.MultiParcel : ParcelType.Domestic,
          buySeries,
          country,
          page: PAGE.SELECT_PRODUCT,
          product: seriesProduct,
          services: [],
        });
      }
    } else {
      dispatch({
        type: BUY_SERIES_SHIPMENT,
        parcelType: buySeries ? ParcelType.MultiParcel : ParcelType.Domestic,
        buySeries,
        page: buySeries ? PAGE.SELECT_PRODUCT : PAGE.SELECT_COUNTRY,
      });
    }
  }
};

export const useSeriesShipment = (series, shipment, isUnused) => (dispatch, getState) => {
  dispatch({
    ...shipment,
    product: series.product,
    services: series.services,
    seriesId: series.productId,
    isUnused,
    type: USE_SERIES_SHIPMENT,
  });
};

export const buyParcel = (productCode, country = 'FI') => async (dispatch, getState) => {
  let page = PAGE.SELECT_EXTRAS;
  if (productCode === catalog.BIKE_PRODUCT_ID) page = PAGE.SELECT_PRODUCT;
  if (productCode === catalog.TIRESET_PRODUCT_ID) page = PAGE.SELECT_PRODUCT;
  if (productCode === catalog.REFILL_PRODUCT_ID) page = PAGE.SELECT_PRODUCT;
  if (productCode === catalog.REPACK_PRODUCT_ID) page = PAGE.SELECT_REPACK_OFFICE;
  if (reverseOrderForRecipient(country, productCode) && page === PAGE.SELECT_EXTRAS) {
    page = PAGE.RECIPIENT;
  }
  await dispatch(startParcelFlowForProduct(productCode, country, page));
};

export const showParcel = (productCode, country = 'FI') => async (dispatch, getState) => {
  await dispatch(startParcelFlowForProduct(productCode, country, PAGE.SELECT_PRODUCT));
};

export const startParcelFlowForProduct = (productCode, country, page) => async (dispatch, getState) => {
  const {
    parcelFlow: { parcelType },
  } = getState();
  dispatch({
    type: SELECT_PAGE,
    page: PAGE.EMPTY_WAIT_PAGE,
    parcelType: parcelType || (country == 'FI' ? ParcelType.Domestic : ParcelType.Abroad),
    country,
  });
  let product;
  if (country === 'FI') {
    let {
      catalog: { products },
    } = getState();
    if (!products || products.length === 0) {
      products = await dispatch(catalog.getProducts());
    }
    product = products.find(p => p.id === productCode);
    if (!product && productCode) {
      product = products?.find(p => p.sizeCode?.toLowerCase() === productCode.toLowerCase());
    }
  } else {
    const {
      catalog: { country: catCountries },
    } = getState();
    let products = catCountries[country];
    if (!products || products.length === 0) {
      products = await dispatch(catalog.loadProductsForCountry(country));
    }
    product = products.find(p => p.id === productCode);
    if (!product && productCode) {
      product = products?.find(p => p.sizeCode?.toLowerCase() === productCode.toLowerCase());
    }
  }

  if (product) {
    dispatch(locNavigate('/osta-paketti'));
    dispatch({
      type: SELECT_PAGE,
      country,
      page,
      isLarge: product.isLarge,
      product,
      services: [],
    });
  } else {
    // unknown product code
    dispatch({
      type: SELECT_PAGE,
      page: PAGE.SELECT_PRODUCT,
    });
  }
};

export const startExpressParcelFlow = () => async (dispatch, getState) => {
  dispatch(locNavigate('/osta-paketti'));
  dispatch({
    type: SELECT_PAGE,
    page: PAGE.SELECT_PRODUCT,
    parcelType: ParcelType.Express,
  });
};

export const setUnattended = value => ({
  value,
  type: SET_UNATTENDED_DELIVERY,
});

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case RESET_FLOW:
      return { ...initialState };
    case SET_PARLCEL_TYPE:
      return { ...state, ...params };
    case SET_COUNTRY:
      const { countryCode } = params;
      return {
        ...state,
        country: countryCode,
        isLarge: null,
        product: null,
        services: [],
        recipient: {},
        pickupPoint: {},
      };
    case SELECT_PAGE:
      return { ...state, ...params };
    case SELECT_LARGE:
      return { ...state, ...params };
    case SELECT_PRODUCT: {
      // filter out services not available for selected product
      const oldProductId = state.product && state.product.id;
      const possibleServices = params.product.services || [];
      const services = (state.services || []).filter(
        s => !!possibleServices.find(p => p.serviceCode === s.serviceCode)
      );
      const newState = { ...state, ...params, services };
      if (oldProductId !== newState.product.id) {
        // when editing item in shopping cart, if product is changed, require reselection of pickup point
        delete newState.pickupPoint;
      }
      if (newState.packages > 1) {
        if (!askPackageCount(params.product)) {
          // if not rengaspaketti
          delete newState.packages;
        }
      }
      if (newState.product.id !== catalog.SizeCode.REFILL) {
        delete newState.repackCode;
      }
      return newState;
    }
    case SELECT_PACKAGE_COUNT:
      return { ...state, ...params };
    case SET_REPACK_CODE:
      return { ...state, ...params };
    case SELECT_SERVICE:
      if (state.services.find(s => s.serviceCode === params.service.serviceCode)) {
        return state;
      }
      const disabledServices = params.service.excludeServices || [];
      return {
        ...state,
        services: [...state.services.filter(s => !disabledServices.includes(s.serviceCode)), params.service],
        delivery: null,
      };
    case UNSELECT_SERVICE:
      return {
        ...state,
        services: state.services.filter(s => s.serviceCode !== params.serviceCode),
        delivery: null,
      };
    case SELECT_DELIVERY_METHOD:
      return { ...state, ...params };
    case SAVE_RECIPIENT:
      return { ...state, ...params, jys: {} };
    case SELECT_BUS_LINE:
      return { ...state, ...params };
    case SELECT_PICKUPPOINT:
      return { ...state, ...params };
    case SELECT_REPACKPOINT:
      return { ...state, ...params };
    case SAVE_CONTENT:
      return { ...state, ...params };
    case SAVE_CONTENTS:
      return { ...state, ...params };
    case UPDATE_ITEM_IN_BASKET:
      return { ...state, ...params, page: PAGE.SELECT_PRODUCT };
    case USE_SERIES_SHIPMENT:
      const page = params.product?.deliveryIncluded ? PAGE.SELECT_DELIVERY : PAGE.RECIPIENT;
      return { ...state, ...params, page };
    case END_SERIES_SHIPMENT_UPDATE:
      return { ...initialState };
    case BUY_SERIES_SHIPMENT:
      return { ...initialState, ...params };
    case SET_JYS_DELIVERY:
      return { ...state, ...params };
    case SET_UNATTENDED_DELIVERY:
      return { ...state, recipient: { ...state.recipient, unattendedHandover: params.value } };
    default:
      return state;
  }
};
