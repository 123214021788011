export const STEP = {
  CHOOSE_METHOD: 1,
  SHIPMENT_NUMBER: 2,
  RETURN_PERMISSION_CODE: 3,
  CHOOSE_PICKUP: 4,
  SENDER_INFO: 5,
  PICKUP_PAYMENT: 6,
  READY: 7,
  NOT_POSSIBLE: 8,
  INVALID_SHIPMENT: 9,
};

export const RETURN_METHODS = {
  SHIPMENT_NUMBER: 1,
  RETURN_PERMISSION_CODE: 2,
  RETURN_YOURSELF: 3,
};

export const PICKUP_SERVICE_CODE = 'NO';
