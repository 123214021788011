import * as api from './api';
import { isBrowser } from '../utils';

const removeTokens = () => {
  sessionStorage.removeItem('itoken');
  sessionStorage.removeItem('atoken');
  localStorage.removeItem('itoken');
  localStorage.removeItem('atoken');
  localStorage.removeItem('rtoken');
  document.cookie = `atoken=; max-age=0; domain=matkahuolto.fi; path=/; secure`;
  document.cookie = `signed-in=; max-age=0; domain=matkahuolto.fi; path=/; secure`;
};

const saveTokens = (rememberMe, IdToken, AccessToken, RefreshToken, ExpiresIn) => {
  if (rememberMe === true || rememberMe === 'true' ) {
    sessionStorage.removeItem('atoken');
    sessionStorage.removeItem('itoken');
    localStorage.setItem('itoken', IdToken);    
    localStorage.setItem('atoken', AccessToken);
    localStorage.setItem('rtoken', RefreshToken);
  } else {
    localStorage.removeItem('atoken');
    localStorage.removeItem('itoken');
    localStorage.removeItem('rtoken');
    sessionStorage.setItem('itoken', IdToken);  
    sessionStorage.setItem('atoken', AccessToken);
  }
  api.setAccessTokenCookie(AccessToken, RefreshToken, ExpiresIn);
};

export const getTokens = () => {
  let AccessToken = localStorage.getItem('atoken');
  let IdToken = localStorage.getItem('itoken');
  let RefreshToken = localStorage.getItem('rtoken');
  if (!AccessToken) {
    AccessToken = sessionStorage.getItem('atoken');
  }
  if (!IdToken) {
    IdToken = sessionStorage.getItem('itoken');
  }
  return { AccessToken, IdToken, RefreshToken };
};

export const login = async (username, password, rememberMe) => {
  if (!isBrowser) {
    return {};
  }

  try {
    const { AccessToken, IdToken, RefreshToken, ExpiresIn } = await api.login(username, password);
    saveTokens(rememberMe, IdToken, AccessToken, RefreshToken, ExpiresIn);
    const user = await api.getUser(IdToken);
    return { user, error: null };
  } catch (error) {
    console.error('login failed:', error);
    return { user: null, error };
  }
};

export const loginWithToken = async (token, rememberMe = false) => {  
  if (!isBrowser) {
    return {};
  }
  try {
    const { IdToken, RefreshToken, ...user } = await api.getUser(token, false);
    if (IdToken) {
      // token was access token from idp redirect link
      // sessionStorage.setItem('itoken', IdToken);
      // sessionStorage.setItem('atoken', token);
      saveTokens(rememberMe, IdToken, token, RefreshToken);  
    }
    return { user, error: null };
  } catch (error) {
    console.error('login failed:', error);
    if (error.response && error.response.status == 400) {
      return { user: null, error: new api.UserAuthError('Missing email') };
    }
    return { user: null, error };
  }
};

export const silentAuth = async () => {
  // let token = sessionStorage.getItem('itoken') || localStorage.getItem('itoken');
  let token = sessionStorage.getItem('atoken') || localStorage.getItem('atoken');
  let tokenFromCookie = false;
  if (!token) {
    token = api.getToken();
    tokenFromCookie = true;
  }
  if (!token) {
    return {};
  }
  try {
    const user = await api.getUser(token, !tokenFromCookie);
    if (tokenFromCookie) {
      const { IdToken, RefreshToken } = user;
      if (IdToken) {
        const rememberMe = false;
        saveTokens(rememberMe, IdToken, api.getToken(), RefreshToken);  
      }
    }
    return { user };
  } catch (error) {
    console.error('login error:', error);    
    removeTokens();
    return {};
  }
};

export const logout = () => {
  if (!isBrowser) {
    return;
  }
  removeTokens();
};
