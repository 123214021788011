import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { silentAuth } from '../state/session';
import { loadBasket } from '../state/basket';
import { getBasketId } from '../utils/basket';
import * as api from '../utils/api';
import Spinner from '../components/Spinner';

export default ({ children }) => {
  const hasUser = useSelector(state => !!(state.session && state.session.user));
  const shouldAttemptSilentAuth = api.getToken() !== null && !hasUser;
  //const [loading, setIsLoading] = useState(shouldAttemptSilentAuth);
  const basketId = getBasketId();
  const basketLoaded = useSelector(state => (state.basket && state.basket.basketId));
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldAttemptSilentAuth) {
      dispatch(
        silentAuth(/*() => {
          setIsLoading(false);
        }*/)
      );
    }

    if (basketId && basketLoaded !== basketId) {
      dispatch(loadBasket(basketId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<React.Fragment>{children}</React.Fragment>);
  //return loading === false ? <React.Fragment>{children}</React.Fragment> : <React.Fragment><Spinner size="medium" /></React.Fragment>;
};
