import React from 'react';
import { Link as GatsbyLink, StaticQuery, graphql } from 'gatsby';
import { useSelector } from 'react-redux';
//import staticPaths from '../pages/localization.json';
import translatePath from '../utils/translatePath';

const translateName = (children, to, locale, data) => {
  if (!to || !locale) return children;
  if (data.allSitePage && data.allSitePage.nodes) {
    const node = data.allSitePage.nodes.find(node => node.path === to);
    if (node && node.context && node.context.titles && node.context.titles[locale]) {
      return node.context.titles[locale];
    }
  }
  // fallback
  return children;
};

const Link = ({ data, children, to, noTitleFix, noPathTranslation, ...other }) => {
  const locale = useSelector(state => state.session.locale);
  let href = '/';
  let external = false;
  //FIXME: maybe better way to handle styled component's parameters
  if (typeof to === 'object' && to !== null) {
    href = to.link;
    external = to.external;
  }
  if (typeof to === 'string') {
    href = to;
  }
  if (href.startsWith('/') && !external && !other.target) {
    return (
      <GatsbyLink {...other} to={noPathTranslation ? to : translatePath(href, locale, data)}>
        {noTitleFix ? children : translateName(children, href, locale, data)}
      </GatsbyLink>
    );
  }
  return (
    <a href={href} {...other}>
      {children}
    </a>
  );
};

export const Link2 = props => {
  return <StaticQueryLink {...props} noTitleFix />;
};

const StaticQueryLink = props => (
  <StaticQuery
    query={graphql`
      query AllSitePageLinkQuery {
        allSitePage {
          nodes {
            context {
              paths {
                en
                fi
                sv
              }
              titles {
                en
                fi
                sv
              }
              slug
            }
            path
          }
        }
      }
    `}
    render={data => <Link data={data} {...props} />}
  />
);

export default StaticQueryLink;
