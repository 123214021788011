/** @jsx jsx */
import { jsx, Box, Checkbox, Label } from 'theme-ui';
import React from 'react';

const CheckboxField = ({ field, id, label, setFieldValue, link, ...props }) => {
  return (
    <Box sx={{ my: 1 }}>
      <Label sx={{ fontWeight: 'normal' }}>
        <Checkbox
          name={field.name}
          id={id}
          value={!props.multiple ? field.value : id}
          checked={
            !props.multiple
              ? field.value
              : field.value && field.value.includes(id)
          }
          onChange={
            !props.multiple
              ? field.onChange
              : evt => {
                  if (evt.target.checked) {
                    if (!field.value.includes(evt.target.value)) {
                      setFieldValue(field.name, [
                        ...field.value,
                        evt.target.value,
                      ]);
                    }
                  } else {
                    setFieldValue(
                      field.name,
                      field.value.filter(v => v !== evt.target.value)
                    );
                  }
                }
          }
          onBlur={field.onBlur}
          {...props}
        />
        <span>
          {label}
          {link && (
            <>
              {' '}
              <a target="_blank" rel="noreferrer" href={link.target}>
                {link.text}
              </a>
            </>
          )}
        </span>
      </Label>
      {props.error && <div style={{ color: 'red' }}>{props.error}</div>}
    </Box>
  );
};

export default CheckboxField;
