import { isBrowser } from '../utils';
const storage = isBrowser ? window.sessionStorage : { getItem: () => {} };

const KEY = {
  orderId: 'orderId',
  transactionId: 'transactionId',
};

export function getOrderId() {
  return storage.getItem(KEY.orderId);
}

export function getTransactionId() {
  return storage.getItem(KEY.transactionId);
}

export function setOrderId(orderId, transactionId) {
  storage.setItem(KEY.orderId, orderId);
  storage.setItem(KEY.transactionId, transactionId);
}

export function removeOrderId() {
  storage.removeItem(KEY.orderId);
  storage.removeItem(KEY.transactionId);
}
