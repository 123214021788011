import React from 'react';
import { useSelector } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import translatePath from '../utils/translatePath';
import Button from './Button';

const LocalizedButton = ({ to, ...props }) => {
  const locale = useSelector(state => state.session.locale);
  return (
    <StaticQuery
      query={graphql`
        query AllSitePageButtonLinkQuery {
          allSitePage {
            nodes {
              context {
                paths {
                  en
                  fi
                  sv
                }
                titles {
                  en
                  fi
                  sv
                }
                slug
              }
              path
            }
          }
        }
      `}
      render={data => {
        const locTo = translatePath(to, locale, data);
        return <Button to={locTo} {...props} />;
      }}
    />
  );
};

export default LocalizedButton;
