/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';

export default props => {
  const { name, jobTitle, phoneNumber, email, info, image } = props;
  return (
    <Flex
      sx={{
        mb: 24,
        width: info ? '100%' : ['100%', null, '50%'],
        display: 'inline-flex',
      }}
    >
      <Box
        sx={{
          flex: 'none',
          mr: 3,
          width: 96,
          height: 96,
          overflow: 'hidden',
          borderRadius: '50%',
        }}
      >
        <img src={image} alt={name} sx={{ display: 'block', maxWidth: '100%' }} />
      </Box>
      <Box>
        <Box sx={{ fontWeight: 'medium' }}>{name}</Box>
        {jobTitle && <Box>{jobTitle}</Box>}
        {phoneNumber && (
          <Box as="a" href={`tel:${phoneNumber}`} sx={{ display: 'block' }}>
            {phoneNumber}
          </Box>
        )}
        {email && (
          <Box as="a" href={`mailto:${email}`} sx={{ display: 'block' }}>
            {email}
          </Box>
        )}
        {info && <Box sx={{ mt: 3, p: { marginBottom: 0 } }} dangerouslySetInnerHTML={{ __html: info }} />}
      </Box>
    </Flex>
  );
};
