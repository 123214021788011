import React, { useContext } from 'react';
import { Box } from 'theme-ui';
import { CaretIcon } from './Icon';
import { FormikContext } from 'formik';

const getProps = test => props => {
  const next = {};
  for (const key in props) {
    if (test(key || '')) next[key] = props[key];
  }
  return next;
};

const MRE = /^m[trblxy]?$/;

const getMargin = getProps(k => MRE.test(k));
const omitMargin = getProps(k => !MRE.test(k));

export const Select = React.forwardRef((props, ref) => {
  const { setFieldTouched } = useContext(FormikContext) || {};
  return (
    <Box
      {...getMargin(props)}
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box
        ref={ref}
        as="select"
        variant="select"
        {...omitMargin(props)}
        __themeKey="forms"
        __css={{
          display: 'block',
          width: '100%',
          p: 2,
          appearance: 'none',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          border: '1px solid',
          borderRadius: 4,
          color: 'inherit',
          bg: 'transparent',
        }}
        onChange={props.onChange}
        onBlur={e => {
          setFieldTouched && setFieldTouched(props.name, true);
          props.onBlur && props.onBlur(e);
        }}
      />
      <CaretIcon
        sx={{
          ml: -28,
          alignSelf: 'center',
          pointerEvents: 'none',
          width: 16,
          height: 16,
        }}
      />
    </Box>
  );
});
