/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { Layout, Container } from '.';

export default props => (
  <Layout {...props}>
    <Container variant="wide">
      <Flex sx={{ flex: 1, flexDirection: ['column', null, 'row'] }}>
        {props.navigation && props.navigation}
        <Flex
          sx={{
            width: '100%',
            position: 'relative',
            justifyContent: 'space-between',
            flexWrap: ['wrap', null, null, null, 'nowrap'],
          }}
        >
          <Box sx={{ mr: [null, null, null, null, 4], flex: 'auto' }}>
            {props.children}
          </Box>
          {props.sidebar && (
            <Box
              sx={{
                flex: 'none',
                py: [3, 4],
                width: ['100%', null, 360, 400],
              }}
            >
              {props.sidebar}
            </Box>
          )}
        </Flex>
      </Flex>
    </Container>
  </Layout>
);
