/** @jsx jsx*/
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Link from '../components/Link';
import Button from '../components/Button';
import { WarningIcon } from './Icon';

const CustomsClearanceStatus = ({ parcel }) => {
  const isLoggedIn = useSelector(state => !!state.session.user);
  const {
    mrn,
    customsWarehouseCode,
    customsWarehouseArrivalDate,
    payments,
  } = parcel;
  const clearanceFeePaid = payments?.some(
    payment => payment.chargeCode === '801' && payment.paymentStatus === 'PAID'
  );
  const parcelNumber = parcel.parcelNumber || parcel.shipmentNumber;
  const parcelArrived =
    mrn && customsWarehouseCode && customsWarehouseArrivalDate;
  const parcelNotArrivedUnpaid = !parcelArrived && !clearanceFeePaid;
  const parcelArrivedUnpaid = parcelArrived && !clearanceFeePaid;
  const parcelArrivedDoClearance = parcelArrived && clearanceFeePaid;

  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Flex sx={{ flexDirection: 'column', mt: isLoggedIn ? 4 : null }}>
      <Flex
        sx={{
          bg: 'pinkDark',
          borderRadius: 2,
          p: 3,
          color: 'white',
          flexDirection: 'row',
        }}
      >
        <WarningIcon sx={{ mr: 3 }} />
        {parcelNotArrivedUnpaid
          ? translate('account.parcelDetails.customsPayPossible')
          : parcelArrivedUnpaid
          ? translate('account.parcelDetails.customsPayClearancePossible')
          : parcelArrivedDoClearance
          ? translate('account.parcelDetails.customsClearParcel')
          : translate('tracking.details.customsClearanceReadMore')}
      </Flex>
      <Box sx={{ mt: 4 }}>
        <Button
          as={Link}
          to={`/tullaus?parcelNumber=${parcelNumber}`}
          variant={isLoggedIn ? 'secondary' : 'primary'}
        >
          {parcelNotArrivedUnpaid
            ? translate('account.parcelDetails.payOrMandate')
            : translate('tracking.details.customsClearanceReadMore')}
        </Button>
      </Box>
    </Flex>
  );
};

export default CustomsClearanceStatus;
