const coreScript = {
  id: 'here-core',
  url: 'https://js.api.here.com/v3/3.1/mapsjs-core.js',
};

const additionalScripts = [
  {
    id: 'here-service',
    url: 'https://js.api.here.com/v3/3.1/mapsjs-service.js',
  },
  {
    id: 'here-ui',
    url: 'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
  },
  {
    id: 'here-mapevents',
    url: 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
  },
  {
    id: 'here-clustering',
    url: 'https://js.api.here.com/v3/3.1/mapsjs-clustering.js',
  },
];

const loadScript = (id, url) =>
  new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);

    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.src = url;
      script.id = id;
      script.addEventListener('load', () => resolve());
      script.addEventListener('error', e => reject(e));
      document.body.appendChild(script);
    }
  });

export default async callback => {
  try {
    // Core script needs to be loaded before additional scripts
    await loadScript(coreScript.id, coreScript.url);
    const promises = additionalScripts.map(script => loadScript(script.id, script.url));
    await Promise.all(promises);
    callback();
  } catch (error) {
    console.error('Loading map failed: ', error.message || error);
  }
};
