/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, Flex, Grid, jsx, Styled } from 'theme-ui';
import { useLatestPosts } from '../../hooks/useLatestPosts';
import BlogCard from '../BlogCard';
import LocalizedButton from '../LocalizedButton';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const Post = ({ node }) => (
  <Link
    key={node.slug}
    to={`/news/${node.slug}`}
    sx={{
      display: 'block',
      mb: 3,
    }}
  >
    <Box sx={{ color: 'text', color: 'black' }}>{node.publishTime}</Box>
    <Box
      sx={{
        fontWeight: 'medium',
      }}
    >
      {node.title}
    </Box>
  </Link>
);

export default () => {
  const { blogPosts, pressPosts } = useLatestPosts();
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Grid sx={{ gridTemplateColumns: ['1fr', null, '1fr 2fr'] }}>
      {pressPosts && (
        <Box>
          <Styled.h2 sx={{ variant: 'text.title', mt: 0 }}>{translate('news')}</Styled.h2>
          <Box sx={{ bg: 'blueLighter', borderRadius: 4, p: [3, 4] }}>
            {pressPosts.map(({ node }) => (
              <Post node={node} key={node.id} />
            ))}

            <LocalizedButton as={Link} to="/news" variant="plain" sx={{ pb: 0, textDecoration: 'underline' }}>
              {translate('allNews')} &rarr;
            </LocalizedButton>
          </Box>
        </Box>
      )}
      {blogPosts && (
        <Flex sx={{ flexDirection: 'column' }}>
          <Styled.h2 sx={{ variant: 'text.title', mt: 0 }}>{translate('blog')}</Styled.h2>
          <Grid columns={[1, null, 2]} gap={3} sx={{ flex: 1 }}>
            {blogPosts.map(({ node }) => (
              <BlogCard post={node} frontPage={true} key={node.id} />
            ))}
          </Grid>
        </Flex>
      )}
    </Grid>
  );
};
