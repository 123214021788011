/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';

export default ({ children }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'space-between',
      py: [3, null, 4],
      minHeight: ['calc(100vh - 80px)', null, '60vh'],
      minHeight: ['calc((var(--vh, 1vh) * 100) - 72px)', null, '60vh'],
    }}
  >
    {children}
  </Flex>
);
