import phonePrefix from './phonePrefix.json';
import { isFinlandOrAland } from './countryCode';

export const placeHolderFor = countryCode => {
  if (!countryCode || isFinlandOrAland(countryCode)) {
    return '';
    // return '+358 40 123 4567';
  }
  const prefix = phonePrefix[countryCode];
  if (prefix) {
    return prefix + ' 123 456 789';
  } else {
    return '+358 40 123 4567';
  }
};
