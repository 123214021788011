/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

export default props => (
  <Box
    sx={{
      p: 3,
      mb: 3,
      bg: 'paleYellow',
      borderRadius: 1,
      lineHeight: 'normal',
    }}
    {...props}
  >
    {props.children}
  </Box>
);
