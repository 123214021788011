/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useRef } from 'react';
import { Button } from '../components';
import { isBrowser } from '../utils';
import ReCAPTCHA from 'react-google-recaptcha';
import testLogger from '../components/test-logger';

const RecaptchaButton = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  field = 'recaptcha',
  buttonText = 'feedback.send',
  nocaptcha = false,
  enableLogging = false,
  errors,
  disabled = false,
  sxx,
}) => {
  const recaptchaInstance = useRef(null);
  const executeCaptcha = () => {
    if (nocaptcha) {
      setFieldValue(field, 'nocaptcha');
      return;
    }
    if (enableLogging) {
      testLogger.log('executeCaptcha');
    }
    recaptchaInstance && recaptchaInstance.current && recaptchaInstance.current.execute();
  };
  const resetRecaptcha = () => {
    recaptchaInstance && recaptchaInstance.current && recaptchaInstance.current.reset();
  };

  const submit = response => {
    if (enableLogging) {
      testLogger.log('setFieldValue ' + field + '->' + response);
      testLogger.log('validation errors: ' + JSON.stringify(errors));
    }
    setFieldValue(field, response);
    setTimeout(() => {
      reset();
    }, 100);
    handleSubmit && handleSubmit();
  };

  const reset = () => {
    resetRecaptcha();
    setFieldValue(field, '');
  };

  return (
    <Box sx={{ mt: 3 }}>
      {isBrowser && !nocaptcha && (
        <ReCAPTCHA
          ref={recaptchaInstance}
          size="invisible"
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={submit}
          onExpired={reset}
        />
      )}
      <Button type="submit" onClick={executeCaptcha} disabled={disabled || isSubmitting} sx={sxx}>
        {translate(buttonText)}
      </Button>
    </Box>
  );
};

export default RecaptchaButton;
