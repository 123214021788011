/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideNotification, SEVERITY } from '../state/notifications';
import { CloseIcon } from './Icon';
import { Button, Container } from './';
import { motion, AnimatePresence } from 'framer-motion';

export default ({ notification: { id, severity, msg } }) => {
  const [isVisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const hide = useCallback(e => {
    setVisible(false);
    dispatch(hideNotification(id));
  });

  useEffect(() => {
    const timer = setTimeout(() => hide(), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        key={id}
        initial={{ translateY: -100, translateX: '-50%' }}
        animate={{ translateY: 0 }}
        exit={{ translateY: -100 }}
        sx={{
          zIndex: 999,
          bg: severity === SEVERITY.INFO ? 'blueLighter' : 'paleYellow',
          lineHeight: 'heading',
          fontWeight: 'medium',
          position: 'fixed',
          top: 0,
          left: '50%',
          borderRadius: '0 0 8px 8px',
          boxShadow: 'dropdown',
          maxWidth: '100%',
          transform: 'translateX(-50%)',
          minWidth: 400,
        }}
      >
        <Container
          sx={{
            position: 'relative',
            py: 3,
            textAlign: 'center',
          }}
        >
          {msg}
          <Button
            variant="plain"
            onClick={hide}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 3,
              color: 'secondary',
              transform: 'translateY(-50%)',
            }}
          >
            <CloseIcon />
          </Button>
        </Container>
      </motion.div>
    </AnimatePresence>
  );
};
