/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import themes from './themes';
import React from 'react';

export default props => {
  const { visualStyle, image, title } = props.content;
  const imgUrl = image?.file?.url;
  const theme = visualStyle ? themes[visualStyle] : themes['Dark'];

  if (!imgUrl) return null;

  return (
    <>
      <ImageWidgetBase bg={theme?.bg || '#FFFFFF'} color={theme?.color || '#FFFFFF'} {...props}>
        <img src={imgUrl} alt={title} sx={{ height: 'auto', maxWidth: '100%' }} />
      </ImageWidgetBase>
    </>
  );
};

const ImageWidgetBase = ({ bg, color, ...props }) => {
  return (
    <Box
      sx={{
        flex: ['auto', null, '1 1 0'],
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: [3, 24],
          px: [3, 24],
          height: ['auto', null, '100%'],
          bg: bg,
          color: color ? color : 'white',
          minHeight: ['auto', null, null, 200],
          borderRadius: [0, 16],
        }}
      >
        <Flex
          sx={{
            flex: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div sx={{ maxWidth: '100%' }}>{props.children}</div>
        </Flex>
      </Flex>
    </Box>
  );
};
