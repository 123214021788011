/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import theme from '../gatsby-plugin-theme-ui';

export default (HERE, weight) => {
  const icon = document.createElement('div');
  icon.className = 'here-map-clustering-icon';
  // Put icon in center of point
  icon.style.left = '-1em';
  icon.style.top = '-1em';

  ReactDOM.render(
    <div
      sx={{
        color: 'white',
        width: '2em',
        height: '2em',
        borderRadius: 50,
        background: theme.colors.secondary,
        textAlign: 'center',
        fontWeight: 'medium',
        lineHeight: '2em',
      }}
    >
      {weight}
    </div>,
    icon
  );

  return new HERE.map.DomIcon(icon);
};
