/** @jsx jsx */
import { jsx } from 'theme-ui';
import { rgba } from 'polished';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useMap } from '../hooks/useMap';

export default ({ height, absoluteHeight = false, paddingLeft, mapRef: parentMapRef, onMapCreated }) => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const padding = paddingLeft ? `${paddingLeft}px` : '0';
  const [mapRef, setMapRef] = useState();
  const handleSetMapRef = useCallback(
    ref => {
      setMapRef(ref);
    },
    [setMapRef]
  );
  const { map, calculatedPadding, singleTouchDrag } = useMap(mapRef, {
    onMapCreated,
  });
  useEffect(() => {
    if (parentMapRef) parentMapRef.current = map;
  }, [map, parentMapRef]);

  return (
    <div sx={{ minHeight: absoluteHeight ? height : ['50vh', null, `${height}vh`], position: 'relative' }}>
      <link id="here-style-link" rel="stylesheet" type="text/css" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
      <div
        ref={handleSetMapRef}
        sx={{
          width: '100%',
          height: '100%',
          background: 'grey',
          position: 'absolute',
        }}
      />
      <div
        sx={{
          position: 'absolute',
          background: rgba(60, 60, 60, 0.5),
          pl: calculatedPadding ? ['0', null, padding] : '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          opacity: singleTouchDrag ? '1' : '0',
          transitionProperty: 'opacity',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-out',
          pointerEvents: 'none',
        }}
      >
        <h1 sx={{ color: 'white', margin: 'auto', textAlign: 'center' }}>{translate('map.moveWithTwoFingers')}</h1>
      </div>
    </div>
  );
};
