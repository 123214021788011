/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useContext, useState } from 'react';
import { Context } from './Context';
import { PaymentSelection } from '../components/Payment';

export default ({}) => {
  const [isProcessing, setProcessing] = useState(false);
  const { goBack, createOrder } = useContext(Context);

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      await createOrder(paymentOptions);
    } finally {
      setProcessing(false);
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />;
};
