import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';
import { showNotification } from './notifications';

export const PAGE = {
  SELECT_DESTINATION: 1,
  CONFIRM_ORDER: 2,
  PAYMENT: 3,
};

const initialState = {
  page: PAGE.SELECT_DESTINATION,
  shipmentNumber: null,
  sender: null,
  currentDestination: null,
  destination: null,
  discount: null,
  pricing: null,
  redirect: null,
};

const START_TRANSFER = 'START_TRANSFER';
const SET_TRANSFER_PAGE = 'SET_TRANSFER_PAGE';
const SET_TRANSFER_PRICE = 'SET_TRANSFER_PRICE';
const SET_TRANSFER_DESTINATION = 'SET_TRANSFER_DESTINATION';
const SET_TRANSFER_DISCOUNT = 'SET_TRANSFER_DISCOUNT';
const SET_REDIRECT = 'SET_REDIRECT';

export const startTransferFlow = ({ shipmentNumber, sender, currentDestination, freeTransfer }) => ({
  type: START_TRANSFER,
  shipmentNumber,
  sender,
  currentDestination,
  freeTransfer,
});

export const goBack = () => async (dispatch, getState) => {
  const state = getState().transfer;
  let prevPage = state.page - 1;

  dispatch({
    type: SET_TRANSFER_PAGE,
    page: prevPage,
  });
};

export const goNext = () => async (dispatch, getState) => {
  const state = getState().transfer;
  let prevPage = state.page + 1;

  dispatch({
    type: SET_TRANSFER_PAGE,
    page: prevPage,
  });
};

export const goPayment = () => {
  return {
    type: SET_TRANSFER_PAGE,
    page: PAGE.PAYMENT,
  };
};

export const selectDestination = destination => {
  return {
    type: SET_TRANSFER_DESTINATION,
    destination,
  };
};

export const setTransferPrice = pricing => {
  return {
    type: SET_TRANSFER_PRICE,
    pricing,
  };
};

export const setRedirect = redirect => {
  return {
    type: SET_REDIRECT,
    redirect,
  };
};

export const fetchTransferPrice = async (shipmentNumber, destinationId) => {
  // todo
};

export const saveDiscount = (code, discount) => {
  return {
    type: SET_TRANSFER_DISCOUNT,
    discount: {
      ...discount,
      code,
    },
  };
};

export const createOrder = paymentOptions => async (dispatch, getState) => {
  try {
    const { shipmentNumber, destination, discount } = getState().transfer;
    const language = getLanguage(getState());
    const response = await api.createTransferOrder(shipmentNumber, destination, discount, paymentOptions, language);
    if (paymentOptions?.paymentMethod === 'FREE') {
      dispatch(setRedirect(response?.redirectUrl));
    }
    return response;
  } catch (error) {
    console.warn('Failed to create transfer order:', error);
    dispatch(showNotification('genericApiError'));
  }
};

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case START_TRANSFER:
      return { ...initialState, ...params };
    case SET_TRANSFER_PAGE:
      return { ...state, ...params };
    case SET_TRANSFER_PRICE:
      return { ...state, ...params };
    case SET_TRANSFER_DESTINATION:
      return { ...state, ...params };
    case SET_TRANSFER_DISCOUNT:
      return { ...state, ...params };
    case SET_REDIRECT:
      return { ...state, ...params };
    default:
      return state;
  }
};
