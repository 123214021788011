/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import Image from 'gatsby-image';

const PageGallery = ({ title, images }) => (
  <Grid
    sx={{
      gridTemplateColumns: ['1fr', null, '1fr 1fr'],
      gridGap: [3, null, null, 4],
      mx: [-3, null, null, -4, -6],
      my: [4, null, null, 5],
    }}
  >
    {images &&
      images.map(image => (
        <Image
          key={image.contentful_id}
          fluid={{ ...image.fluid, aspectRatio: 3 / 2 }}
          sx={{
            width: '100%',
            borderRadius: [0, 0, 16],
            bg: 'secondary',
            /*
            img: {
              mixBlendMode: 'screen',
            }, // */
          }}
        />
      ))}
  </Grid>
);

export default PageGallery;
